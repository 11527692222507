
import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchConferences = createAsyncThunk(
  'user/fetchConferences',
  async (filters) => {
    try {
      const response = await axios.get(`/api/v2/conferences?filters=${JSON.stringify(filters)}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)


const conferencesSlice = createSlice({
  name: 'conferences',
  initialState: {
    loading: false,
    conferencesList: [],

  },
  reducers: {
    setConferencesList: () => {

      state.conferencesList = [...action.payload, ...state.conferencesList]
    },


  },
  extraReducers: (builder) => {
    builder.addCase(fetchConferences.pending, (state, action) => {
      if (!state.loading) {
        state.loading = true
        state.currentRequestId = action.meta.requestId
      }
    }),
      builder.addCase(fetchConferences.fulfilled, (state, action) => {
        const { requestId } = action.meta
        if (
          state.loading &&
          state.currentRequestId === requestId
        ) {
          state.loading = false
          state.conferencesList = action.payload
          state.currentRequestId = undefined
        }
      }),
      builder.addCase(fetchConferences.rejected, (state, action) => {
        const { requestId } = action.meta
        if (
          state.loading
        ) {
          state.loading = false
          state.error = action.error
          state.currentRequestId = undefined
        }
      })

  }
})

export const lessonsList = createSelector(
  (state) => ({
    conferencesList: state.conferencesList,
    lessonShow: state.lessonShow,
    lessonsCal: state.lessonsCal
  }),
  (state) => state.lessonList
)

export const { setConferencesList } = conferencesSlice.actions
export default conferencesSlice.reducer

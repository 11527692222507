import React from "react";
import TableTag from "./tableTag";
import TableCancelAction from "./tableCancelAction";
import TableDemandAction from "./tableDemandAction";
import CommentTooltip from "../components/commentTooltip";
import i18n from "../../../i18n";

const availabilityColumns = [
	{
		name: "id",
		selector:  row => row.id,
		omit: true
	},
	{
		name: i18n.t('activerecord.attributes.tickets.created_at'),
		selector:  row => row?.date ,
		width:'20%'
	},

	{
		name: i18n.t('activerecord.attributes.tickets.manager'),
		selector:  row => row.manager,
		omit: true,
		//width:'10%'
	},
	{
		name: i18n.t('activerecord.attributes.tickets.availability_date'),
		selector:  row => {
			return (
				<div className="table-sub-list">
					<div className="list-item">{row?.event_date}</div>
					<div className="list-item"><div className="ref">{row?.event_hours}</div></div>
				</div>
			)
		},
		width:'10%'
	},

	{
		name: i18n.t('activerecord.attributes.tickets.statuses'),
		selector:  row => {
			return (
				<div className="table-sub-list">
					<div className="list-item"><div className="label">Stt. initial</div>-</div>
					<div className="list-item"><div className="label">Stt. demandé</div><div className="ref"> <TableTag data={row?.expected} /> </div></div>
				</div>
			)
		},
		width:'15%'
	},

	{
		name: i18n.t('activerecord.attributes.tickets.teacher_note'),
		selector:  row =>  <CommentTooltip note={row.note} />,
		width:'15%'
	},
	{
		name: i18n.t('activerecord.attributes.tickets.manager_note'),
		selector: row => (
      <div style={{ marginLeft: '10px' }}>
        <CommentTooltip note={row.admin_note} />
      </div>

    ),
		width:'10%'
	},
	{
		name: i18n.t('activerecord.attributes.tickets.cancel_button'),
		selector: row => {
      return (
          row?.state !== 'refused' && <TableCancelAction data={row?.state} id={row.id}/>
      );
  },
		width:'15%'
	},
	{
		name: i18n.t('activerecord.attributes.tickets.status'),
		selector:  row => {
			return(
				<TableDemandAction data={row?.state} id={row.id} />
			)
		},
		width:'15%'
	},


];

export default availabilityColumns

import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchNotifications = createAsyncThunk(
	'notifications/fetchNotifications',
	async ({ read = 'unRead', category = 'reminder' }) => {
		try {
			const response = await axios.get(`/api/v1/notifications?read=${read}&category=${category}`, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			console.log(error.message);
			throw error;
		}
	}
);

export const fetchNotificationsCount = createAsyncThunk(
	'notifications/fetchNotificationsCount',
	async () => {
		try {

			const response = await axios.get(`/api/v1/notifications/count`, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			console.log(error.message);
			throw error;
		}
	}
);


export const fetchNotificationTabs = createAsyncThunk(
	'notifications/fetchNotificationTabs',
	async () => {
		try {
			const response = await axios.get('/api/v1/notification_tabs', {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			console.log(error.message);
			throw error;
		}
	}
);

export const readAllNotifications = createAsyncThunk(
	'notifications/readAllNotifications',
	async (notificationIds, { dispatch }) => { // Destructure dispatch
		try {
			const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
			const response = await axios.put(
				'/api/v1/notifications/read_all',
				{ notification_ids: notificationIds }, // Send notification IDs in the payload
				{
					headers: {
						"Content-Type": "application/json",
						"X-CSRF-Token": csrfToken
					},
					credentials: "same-origin",
				}
			);
			return response.data;
		} catch (error) {
			console.log(error.message);
			throw error;
		}
	}
);

export const readNotification = createAsyncThunk(
	'notifications/readNotification',
	async (notificationId) => {
		const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
		try {
			const response = await axios.put(`/api/v1/notifications/${notificationId}/read`, {}, {
				headers: {
					"Content-Type": "application/json",
					"X-CSRF-Token": csrfToken
				},
				credentials: "same-origin",
			});
			return response.data;
		} catch (error) {
			console.log(error.message);
			throw error;
		}
	}
);

// Notifications slice
const notificationsSlice = createSlice({
	name: 'notifications',
	initialState: {
		loading: false,
		notificationsList: [],
		notificationCount: 0,
		overviewCount: 0,
		absenceCount: 0,
		reminderCount: 0,
		currentRequestId: '',
		error: '',
	},
	reducers: {
		setNotifications: (state, action) => {
			state.notificationsList = [...action.payload, ...state.notificationsList];
		},
		setPendingRequestId(state, action) {
			state.pendingRequestId = action.payload;
		},
		resetNotificationError: (state) => {
			state.error = '';
		},
	},
	extraReducers: (builder) => {
		// Fetch notifications
		builder.addCase(fetchNotifications.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true;
				state.currentRequestId = action.meta.requestId;
			}
		});
		builder.addCase(fetchNotifications.fulfilled, (state, action) => {
			const { requestId } = action.meta
			if (
				state.loading &&
				state.currentRequestId === requestId
			) {
				state.loading = false
				state.notificationsList = action.payload
				state.currentRequestId = undefined
			}
		});
		builder.addCase(fetchNotifications.rejected, (state, action) => {
			const { requestId } = action.meta;
			if (state.loading && state.currentRequestId === requestId) {
				state.loading = false;
				state.error = action.error.message;
				state.currentRequestId = undefined;
			}
		});
		builder.addCase(fetchNotificationsCount.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true;
				state.currentRequestId = action.meta.requestId;
			}
		});

		builder.addCase(fetchNotificationsCount.fulfilled, (state, action) => {
			const { requestId } = action.meta
			if (
				state.loading &&
				state.currentRequestId === requestId
			) {
				state.loading = false
				state.notificationCount = action.payload.count
				state.reminderCount = action.payload.reminder_count
				state.absenceCount = action.payload.absence_count
				state.overviewCount = action.payload.overview_count

				state.currentRequestId = undefined
			}
		});

		builder.addCase(fetchNotificationsCount.rejected, (state, action) => {
			const { requestId } = action.meta;
			if (state.loading && state.currentRequestId === requestId) {
				state.loading = false;
				state.error = action.error.message;
				state.currentRequestId = undefined;
			}
		});

		// Fetch notification tabs
		builder.addCase(fetchNotificationTabs.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(fetchNotificationTabs.fulfilled, (state, action) => {
			state.loading = false;
			// Process notification tabs here, if needed
		});
		builder.addCase(fetchNotificationTabs.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		});

		// Read all notifications
		builder.addCase(readAllNotifications.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(readAllNotifications.fulfilled, (state, action) => {
			state.loading = false;
			state.notificationsList = []
		});
		builder.addCase(readAllNotifications.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		});

		// Read a specific notification
		builder.addCase(readNotification.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(readNotification.fulfilled, (state, action) => {
			state.loading = false;
			let oldList = state.notificationsList.filter(e => {
				return e.id !== action.payload.id
			})
			state.notificationsList = oldList;
		});
		builder.addCase(readNotification.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		});
	},
});

export const notificationsList = createSelector(
	(state) => ({
		notificationsList: state.notificationsList,
		notificationCount: state.notificationCount,
		reminderCount: state.reminderCount,
		absenceCount: state.absenceCount,
		overviewCount: state.overviewCount,

		error: state.notifications.error,
	}),
	(state) => state.notificationsList
);

export const {
	setNotifications,
	setPendingRequestId,
	resetNotificationError,
	clearNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

import React from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProfile } from "../../studentSlice"
import i18n from "../../../../i18n"


export default function PersonalInfo(){

  const dispatch= useDispatch()
  const student = useSelector((state)=>state.students.profile)

  useEffect(()=>{
    dispatch(getProfile)
  },[])

  return (
    <div className="container-profile">
      <div className="item-container-profile">
        <span className="item">{i18n.t('views.profile.first_name')}</span>
        <span className="item-pair"> {student?.first_name}</span>
      </div>

      <div className="item-container-profile">
        <span className="item">{i18n.t('views.profile.last_name')}</span>
        <span className="item-pair">{student?.last_name}</span>

      </div>
      <div className="item-container-profile">
        <span className="item">{i18n.t('views.profile.birth_date')}</span>
        <span className="item-pair">{student?.birth_date}</span>

      </div>
      <div className="item-container-profile">
        <span className="item"> {i18n.t('views.profile.geozone')}
        </span>
        <span className="item-pair">{student?.geozone}</span>
      </div>
      <div className="item-container-profile">
        <span className="item"> {i18n.t('views.profile.email')}</span>
        <span className="item-pair">{student?.email}</span>

      </div>
      <div className="item-container-profile">
        <span className="item"> {i18n.t('views.profile.phone')}
        </span>
        <span className="item-pair">{student?.phone_number}</span>

      </div>


    </div>
  )

}

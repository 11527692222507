require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

//import "bootstrap";
//import 'bootstrap/dist/css/bootstrap.min.css';

import "../v2"
import "../v2/stylesheets/application.scss";

$(".alert-info" ).fadeOut(5000);
$(".alert-warning" ).fadeOut(5000);

const userDomEl = document.getElementById("app");
const idStonly = userDomEl ? userDomEl.dataset.idstonly : null;
const valueStonly = userDomEl ? userDomEl.dataset.valuestonly : null;
const email = userDomEl ? JSON.parse(userDomEl.dataset?.user).email : null;
const isTrueSet = valueStonly == 'true';

if (!email.includes("lecercledeslangues")) {
    StonlyWidget('identify', idStonly, {
    'firstvisit': isTrueSet,
    });
}

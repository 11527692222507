import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {setFiltersLesson, setFiltersTraining, setResponsiveFilterCleanBtn} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'


const ByTestStatus = ({model, action, loading}) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.filters[model].test_statuses)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
  const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
  const ref = useRef()

	const [options, setOptions] = useState([
		{ value: 'done', label: `${i18n.t('evaluation_status.done')}` },
		{ value: 'planned', label: `${i18n.t('evaluation_status.planned')}` },
		{ value: 'not_done', label: `${i18n.t('evaluation_status.not_done')}` },
		{ value: 'none', label: `${i18n.t('evaluation_status.none')}` }
	])


	//to-do: options will take from backend

	const dispatcher = {
		"setFiltersTraining": (test_statuses) => dispatch(setFiltersTraining({test_statuses: test_statuses})),
	}

	const  onSelectChange = value => {
    // if(responsiveFilter){
    //   dispatch(setResponsiveFiltersArray({action,value,type:'testStatus'}))
    // }
    // else{
      dispatcher[action](value)
    //}
	}


  if(isCleaned){
    ref.current?.select.clearValue();

  }

	return (
		<div className='filter by_test_statuses'>
			<div className="line">
				<label htmlFor="filter_type" id="test_statuses" className="s_label">
				{i18n.t('filters.by_test_status')}

          </label>
				<Select
					ref={ref}
					isLoading={loading}
					isDisabled={loading}
					cacheOptions
					onChange={onSelectChange}
					isMulti
					options={options}
					defaultValue={current}
          placeholder={`${i18n.t('helpers.select.prompt')}...`}
				/>
			</div>
		</div>
	)

};

export default ByTestStatus;

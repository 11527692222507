
import React, { useEffect, useState } from "react"
import Header from "./components/header"
import { useDispatch, useSelector } from "react-redux"
import { getProfile } from "./studentSlice";
import openArrow from "../images/icons/openArrow.svg";
import closeArrow from "../images/icons/closeArrow.svg";
import PersonalInfo from "./components/parts/personalInfo";
import i18n from "../../i18n";

export default function Profile(){


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch((getProfile()));
  }, []);
  const openNavbar = useSelector((state) => state.navigations.openNavbar)

  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };



  return(
    <div className="main-content">
          <div className={`page-container ${openNavbar && 'responsive-hidden'}`} >


        <Header />

        <div className="list-infos-teacher">
          <ul className="teacher-list-ul">
            <li className="teacher-list-item" onClick={() => handleClick(0)}>
              <span>
                <img
                  className="icon"
                  src={activeIndex === 0 ? openArrow : closeArrow}
                />
              </span>
              {i18n.t('views.profile.personal_informations')}
              {activeIndex === 0 && <PersonalInfo/>}
            </li>


          </ul>
        </div>


    </div>

    </div>
  )
}

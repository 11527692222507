import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { useDispatch, useSelector, ReactReduxContext } from "react-redux";
import { fetchLessons, fetchFuturesLessons, fetchPassedLessons } from "./lessonsSlice";
import { setFiltersCoursCollectives, setFiltersLesson } from "../filters/filtersSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DataTable from '../toolbox/datatable';
import StudentColumns from './columns/student';
import StudentSidedColumns from './columns/student_sided';
import StudentCollective from './columns/student_collective';
import TeacherColumns from './columns/teacher';
import TeacherSidedColumns from './columns/teacher_sided';
import AdminColumns from './columns/admin';
import AdminSidedColumns from './columns/admin_sided';
import Panel from '../toolbox/panel';
import Lesson from './lesson';
import Header from './components/header';
import Modal from '../toolbox/modal';
import StateManagement from './components/state_management';
import conditionalRowStyles from "./components/conditional_row_styles";
import ResponsiveOne from './components/responsiveOne'
import { setResponsiveOne } from "../navigation/navigationSlice";
import CardList from "../toolbox/cardList";
import CardListItem from "../lessons/components/cardListItem"
import { fetchCourseCollectives } from "../cours-collectives/coursCollectivesSlice";
//column rendering depending of role / sided

const Lessons = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const search = location.search;
    const path = location.pathname
    const filters = useSelector(state => state.filters);
    const panelState = new URLSearchParams(search).get('pm');
    const lessonId = new URLSearchParams(search).get('l');
    const user = useSelector(state => state.user.userData);
    const { lessonsList, loading } = useSelector((state) => state.lessons)
    const [currentRow, setCurrentRow] = useState(null);
    const [columns, setColumns] = useState(TeacherColumns);
    const [futurePage, setFuturePage] = useState(null);
    const [passedPage, setPassedPage] = useState(null);
    const ref = useRef(null);
    const modalRef = useRef(null);
    const { store } = useContext(ReactReduxContext)
    const state = store.getState();
    const openNavbar = useSelector((state)=>state.navigations.openNavbar)
    const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
    const responsiveOne = useSelector((state)=>state.navigations.responsiveOne)
    const [page, setPage] = useState(1)
    const collectiveLessonsList = useSelector((state)=>state.coursCollectives.courseCollectivelist)
    const profile = useSelector((state)=>state.user.currentProfile)



  useEffect(() => {
    if ((user.role)) {
      if (user.role == 'Student') {
        if (path == "/lessons") {
          dispatch(setFiltersLesson({ types: [{ value: 'personal', label: 'Individual' }] }))
        } else {
          dispatch(setFiltersCoursCollectives({ scope: "my_lessons" }))
        }
      } else {
        if(user.role==='Teacher'){

          filters?.filtersLesson?.states?.length >0 ? dispatch(setFiltersLesson({states:[]})) : ''
          dispatch(setFiltersLesson({ types: [{ value: 'personal', label: 'Individual' }, { value: 'collective', label: 'Collectif' }] }))

        }
        dispatch(setFiltersLesson({ types: [{ value: 'personal', label: 'Individual' }, { value: 'collective', label: 'Collectif' }] }))
      }
    }
  }, [user, path,profile]);


  useEffect(() => {

    setTimeout(() => {
      const state = store.getState();
      const filters =  path.includes("collective_lessons") ? state.filters.filtersCoursCollectives :  state.filters.filtersLesson
      if (filters.types || filters.scope) {
        if(path.includes("collective_lessons")){
          dispatch(fetchCourseCollectives({ filters, page }))
        }
        else{
          dispatch(fetchLessons({filters})).then(() => {
            setFuturePage(2)
            setPassedPage(2)
          })
        }

      }
    }, 200)
  }, [filters, user,profile]);

  useEffect(() => {
    if (panelState == 's') {
      if (user.role == "Student") {
        setColumns(StudentSidedColumns)
      } else if (user.role == "Admin") {
        setColumns(AdminSidedColumns)
      } else {
        setColumns(TeacherSidedColumns)
      }
    } else {
      if (user.role == "Student") {
        if (location.pathname.includes('collective')) {
          setColumns(StudentCollective)
        } else {
          setColumns(StudentColumns)
        }
      } else if (user.role == "Admin") {
        setColumns(AdminColumns)
      } else {
        setColumns(TeacherColumns)
      }
    }
  }, [panelState, user, path,profile]);

  useEffect(() => {
    if (panelState == 'm') {
      modalRef.current?.onShow()
    } else {
      modalRef.current?.onHide()
    }
  }, [panelState]);


  useEffect(() => {
    const state = store.getState();
    const filters =  path.includes("/collective_lessons") ? state.filters.filtersCoursCollectives :  state.filters.filtersLesson

    if (passedPage && !loading) {
      if(path.includes("collective_lessons")){
        dispatch(fetchCourseCollectives({ page: passedPage, filters }))
      }
      else{
        dispatch(fetchPassedLessons({ passedPage, filters }))
      }
    }
  }, [passedPage]);

  useEffect(() => {
    const state = store.getState();

    const filters =  path.includes("/collective_lessons") ? state.filters.filtersCoursCollectives :  state.filters.filtersLesson

    if (futurePage && !loading) {

      if(path.includes("collective_lessons")){
        dispatch(fetchCourseCollectives({ filters, page:futurePage }))
      }
      else{
        dispatch(fetchFuturesLessons({ futurePage, filters }))
      }

      setTimeout(() => {
      }, 400);
    }
  }, [futurePage]);

  useEffect(() => {
    (currentRow && ['done', 'passed'].includes(currentRow.state)) &&
      navigate(`?l=${currentRow?.id}&pm=s`)
  }, [currentRow]);

  const expand =()=>{
    navigate(`/lessons/${lessonId}`);
  }


    return (
      <div className={`main-content lessons`}>
      <div className={`datatable page-container ${(openNavbar || responsiveFilter || (responsiveOne && panelState=='s') ) && 'responsive-hidden' } ${panelState === 's' ? 'sided' : ''}`}>

        <Header />
        {
          !responsiveOne ?
            <DataTable
              columns={columns}
              data= { path.includes("collective_lessons") ? collectiveLessonsList : lessonsList}
              clickable={false}
              setPassedPage={setPassedPage}
              passedPage={passedPage}
              loading={loading}
              futurePage={futurePage}
              setFuturePage={setFuturePage}
              ref={ref}
              conditionalRowStyles={conditionalRowStyles}
              filters={filters}
            /> : <CardList
              data={lessonsList || collectiveLessonsList}
              onRowClicked={(row) => setCurrentRow(row)}
              setPassedPage={setPassedPage}
              passedPage={passedPage}
              loading={loading}
              futurePage={futurePage}
              setFuturePage={setFuturePage}
              ref={ref}
              rootPage='lesson'
              card={<CardListItem />} // custom render function


            />
        }


      </div>
      <Panel  key={currentRow?.id} state={panelState} unselect={setCurrentRow} expand={expand} >
        <Lesson key={lessonId} />
      </Panel>
      <Modal ref={modalRef}>
        <StateManagement />
      </ Modal>
    </div>
  )

};

export default Lessons;

import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const ImportantDates = ({first, middle, last}) => {
    
    return (
        <div className="important-dates">
           <span className="date first">{first}</span>
           <FontAwesomeIcon className="icon-details left" icon={faArrowRight} color="#000"/>
           <span className="date middle">{middle}</span>
           <FontAwesomeIcon className="icon-details left" icon={faArrowRight} color="#C6C6C6"/>
           <span className="date last">{last}</span>  
        </div>
    )
    
};

export default ImportantDates;
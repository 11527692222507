import React from "react"
import { useSelector } from "react-redux"
import i18n from "../../../../i18n";

export default function Statistics() {

  const teacher = useSelector((state) => state.teachers.profile);


  return (
    <div className="container-profile">
      <div className="item-container-profile">
        <span className="item">{i18n.t('views.profile.fill_rate')}</span>

        <span className="item-pair"> { `${teacher?.fill_rate} %`} </span>

      </div>

      {/* <div className="item-container-profile">
        <span className="item"> Activité de mes élèves	      </span>

        <span className="item-pair">{teacher?.activitesmthng}</span>

      </div> */}

    </div>
  )
}

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Popover } from "react-bootstrap";

export default function EmailTooltip({ email }) {


  const overlay = (
    <Popover id="popover-basic">
     <Popover.Content style={{ padding: '10px' }}>
      {email}
    </Popover.Content>
    </Popover>
  )

  return (
    <div>
      <OverlayTrigger
        trigger="hover"
        placement="right"
        containerPadding={20}
        overlay={
          overlay
        }
      >
        <FontAwesomeIcon icon={faEnvelope} style={{ cursor: "pointer" }} />
      </OverlayTrigger>
    </div>
  );
}


import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleTicket } from "../ticketsSlice";
import i18n from "../../../i18n";

export default function TableCancelAction({ data, id }) {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user.userData);


const cancelTicket=()=>{

  dispatch(handleTicket({id, state:'canceled'}))

}


  return (
    <div>
      <button disabled={user.role!=='Teacher' || data==='validated'}  onClick={()=>cancelTicket()} className={`cancel-button ${(data==='validated' || data==='canceled') ? false : true}`} >
        {i18n.t('views.ticket.cancel_action')}
      </button>
    </div>
  )

}

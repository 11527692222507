import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import i18n from '../../../i18n'

const Header = () => {

    return (
        <div className="main-header">
            <div className="main-title">
                <h2>{i18n.t('navigation.student.profile')}</h2>
            </div>

            </div>
    )

};

export default Header;

import React, { useContext, useEffect, useState } from "react"
import Header from "./components/header"
import { ReactReduxContext, useDispatch, useSelector } from "react-redux"
import { elearnings, fetchExercises, fetchOneExercise } from "./elearningSlice"
import Steps from "./components/steps"
import Panel from "../toolbox/panel"
import { useLocation, useNavigate } from "react-router-dom"
import ELearning from "./elearning"
import { setResponsivePanel } from "../navigation/navigationSlice"

export default function ELearnings() {

  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const path = location.pathname
  const panelState = new URLSearchParams(search).get('pm');
  const dispatch = useDispatch()
  const filters = useSelector((state) => state.filters.filtersELearning)
  const exercices = useSelector((state) => state.elearnings.exercisesList)
  const [currentRow, setCurrentRow] = useState(null);

  const [openedNode, setOpenedNode] = useState([]);
  const user = useSelector(state => state.user.userData);
  const { store } = useContext(ReactReduxContext)

  const openNavbar = useSelector((state) => state.navigations.openNavbar)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
  const responsivePanel = useSelector((state)=>state.navigations.responsivePanel)
  const responsiveOne = useSelector((state)=>state.navigations.responsiveOne)
  const profile = useSelector((state)=>state.user.currentProfile)


  useEffect(() => {
    dispatch(fetchExercises({filters: filters}))
  }, [])

  useEffect(() => {
    const state = store.getState();
    const filters = state.filters.filtersELearning
    dispatch(fetchExercises({filters: filters}))
  }, [filters, user, profile]);

  useEffect(() => {
    (currentRow) &&
      navigate(`/elearnings?e=${currentRow}&pm=s`)
  }, [currentRow]);



  if (exercices) {
    return (

      <div className={`main-content`}>

        <div className={`datatable page-container ${(openNavbar||responsiveFilter || (responsiveOne && panelState=='s')) && 'responsive-hidden'} ${panelState == 's' && 'sided'}`} >

          <Header />
          <div className="elearning-page">
            {
              exercices?.map((item, index) => {
                return (
                  <Steps setCurrentRow={setCurrentRow} index={index} key={index} node={item} openedNode={openedNode}
                    setOpenedNode={setOpenedNode} currentRow={currentRow} />
                )
              })
            }
          </div>

        </div>

        <Panel state={panelState} unselect={setCurrentRow} >
          <ELearning key={currentRow}  />
        </Panel>




      </div>
    )
  }



  return (
    <div className="main-content">
        <div className={`page-container ${openNavbar && 'responsive-hidden'} `} >


        <Header />

        <div> There is no data yet </div>


      </div>

    </div>
  )

}

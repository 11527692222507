import React from "react";
import Filters from "../../filters/filters";
import ActiveFilters from "../../filters/activeFilters";

const ListHeader = () => {

	return (
		<>
			<div className="main-header">
				<div className="main-title">
					<h2>Teachers</h2>
				</div>
				<div className="main-actions">
					<Filters model={"Teachers"} />
				</div>
			</div>
			<ActiveFilters model={"filtersTeachers"} action={"setFiltersTeachers"}  />
		</>
	)

};

export default ListHeader;

import React, {useEffect, useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroupedAvailabilities } from "../availabilities/availabilitiesSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { setFiltersAvailabilities } from "../filters/filtersSlice";
import Header from './components/header';
import Calendar from "../toolbox/calendar";
import Avatar from "../teachers/avatar";
import Modal from "../toolbox/modal";
import Event from './components/event';
import AvailabilitiesDetails from './components/availabilities_details';

//column rendering depending of role / sided

const Agenda = () => {
    const dt = new Date();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [start_at, setStartAt] = useState(null)
    const [end_at, setEndAt] = useState(null)
    const user = useSelector(state => state.user.userData)
    const availabilities = useSelector(state => state.availabilities.groupedAvailabilities)
    const location = useLocation();
    const search = location.search;
    const path = location.pathname
    const ref = useRef(null);
    const modalState = new URLSearchParams(search).get('pm');
    let filters = useSelector(state => state.filters.filtersAvailabilities);

    const openNavbar = useSelector((state) => state.navigations.openNavbar)
    const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)



    useEffect(() => {
        dispatch(setFiltersAvailabilities({teachers: null, teacher_status:{value: 'active', label: 'Actif'}}))
    }, [path])

    useEffect(() => {

        dispatch(fetchGroupedAvailabilities({...filters, start_at: start_at, end_at: end_at }))
    }, [start_at, filters]);

    useEffect(() => {
        if (modalState == 'm') {
            ref.current?.onShow()
        } else {
            ref.current?.onHide()
        }
    }, [modalState]);

    const changeDate = (date) => {
        const temp_wkStart = new Date(new Date(date).setDate(new Date(date).getDate()));
        const temp_wkEnd = new Date(new Date(temp_wkStart).setDate(temp_wkStart.getDate() + 7));
        setEndAt(temp_wkEnd)
        setStartAt(temp_wkStart)
    }

    return (
      <div className={`main-content`}>
      <div className={`page-container ${(openNavbar||responsiveFilter) && 'responsive-hidden'}`} >


                <Header adminAgenda />
                {/* <Avatar calendar /> */}
                <Calendar data={availabilities} date={dt} changeDate={changeDate} selectable={filters.teachers} event={Event}/>
            </div>
            <Modal ref={ref} large>
                <AvailabilitiesDetails />
            </ Modal>
        </div>
    )

};

export default Agenda;

import React from "react"
import { useSelector } from "react-redux"

export default function BankInfos() {

  const bankInfos = useSelector(state => state.teachers.profile.bank_account)


  return (
    <div className="container-profile">

      <div className="item-container-profile">
        <span className="item"> IBAN  </span>
        <span className="item-pair">{bankInfos?.iban}</span>

      </div>
      <div className="item-container-profile">
        <span className="item">{`BIC (SWIFT)`}</span>
        <span className="item-pair">{bankInfos?.bic}</span>

      </div>

      <div className="item-container-profile">
        <span className="item"> Account number </span>
        <span className="item-pair">{bankInfos?.account_number} </span>


      </div>

      <div className="item-container-profile">
        <span className="item"> Transit code  </span>
        <span className="item-pair">{bankInfos?.transit_code}</span>

      </div>

      <div className="item-container-profile">
        <span className="item"> Sort code  </span>

        <span className="item-pair">{bankInfos?.sort_code}</span>
      </div>

      <div className="item-container-profile">
        <span className="item"> Routing number  </span>
        <span className="item-pair">{bankInfos?.routing_number}</span>
      </div>

      <div className="item-container-profile">
        <span className="item"> Company number  </span>

        <span className="item-pair">{bankInfos?.company_number}</span>
      </div>

      <div className="item-container-profile">
        <span className="item"> Institution number  </span>

        <span className="item-pair">{bankInfos?.insitution_number}</span>
      </div>









    </div>
  )
}

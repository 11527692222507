import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { getLessonByID } from "../lessonTransitionSlice";
import { getLesson } from "../lessonsSlice";
import LessonActions from './lessonActions';
import { listItemAvatarClasses } from "@mui/material";
import i18n from "../../../i18n";


export default function LessonTransition({ event, state }) {
  const user = useSelector(state => state.user.userData)
  const lessonDetail = useSelector((state) => state?.lessonTransition)
  const lesson = useSelector((state) => state?.lessons.lessonShow)
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getLessonByID(event))
    dispatch(getLesson(event))
  }, [])

  const renderEventBody = (item) => {
    return (
      <div style={{ display: 'flex', width:'100%' }} >

        <p className={`lesson-transition-status ${item?.to_state}`}>{i18n.t(`activerecord.attributes.lesson_transition.state.${item?.to_state ? item?.to_state : 'pending'}`)}</p>
        <p className="lesson-transition-user">
          {" par " + item?.user + " le " + item?.date + (item?.cancel_note ? `: ${item?.cancel_note}` : "")}
        </p>
      </div>
    );
  };

  return (
    <div className={`event-detail`}>
      {lessonDetail?.lessonStatus?.map((item, index) => {
        return (
          <div key={index}>
              {renderEventBody(item)}
          </div>
        );
      })}

      <div  className="event-footer">
        <LessonActions id={lessonDetail?.lessonStatus[0]?.lesson_id} state={state} event={lesson} is_passed={lessonDetail?.lessonStatus[0]?.is_passed} />
      </div>


    </div>
  );


}

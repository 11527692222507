import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../toolbox/modal'
import TrainingManagement from '../../trainings/components/trainingManagement';



export default function TableDemandAction({ data, id }) {

  const [position, setPosition] = useState('')
  const location = useLocation();
  const navigate = useNavigate()
  const search = location.search;
  const modalState = new URLSearchParams(search).get('pm');
  const [clickedId, setClickedId] = useState()
  const user = useSelector(state => state.user.userData);



  const ref = useRef()


  useEffect(() => {
    if (modalState == 'm' && clickedId === id) {
      ref.current?.onShow()
    } else {
      ref.current?.onHide()
      setClickedId(null)
    }
  }, [modalState, clickedId]);


  useEffect(() => {

    if (data === 'validated' || data === true) {
      setPosition('right')
    }
    else if (data === 'refused') {
      setPosition('left')
    }
    else {
      setPosition('middle')
    }


  }, [data])

  const toggleSwitch = (event) => {

    if (user?.role !== 'Admin' || data === 'validated' || data === 'refused') {
      return;
    }

    const switchContainer = event.currentTarget;
    const switchElement = document.getElementById(`switch-${id}`);

    if (event.target === switchElement || event.target.closest(`#switch-${id}`)) {
        const containerRect = switchContainer.getBoundingClientRect();
        const clickX = event.clientX - containerRect.left;
        if (clickX < switchElement.offsetWidth / 2) {
            navigate(`?ts=${id}&pm=m&state=ticket-students-refused`);
            setClickedId(id);
        } else {
            navigate(`?tv=${id}&pm=m&state=ticket-students-validated`);
            setClickedId(id);
        }
    }
  }



  return (
    <div id={`switch-container-${id}`} className={`switch-container ${position}`} onClick={(e) => {
      user?.role==='Admin' ? toggleSwitch(e) : null
    }}>

      <div id={`switch-${id}`} className={`switch ${position}`}>
        <div className="switch-handle"></div>
      </div>

      {clickedId === id && (
        <Modal ref={ref}>
          <TrainingManagement />
        </Modal>
      )}


    </div>
  );
};

import React, {useEffect, useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { getInvoice } from "./invoicesSlice";
import Header from './components/header';
import Status from './components/status';
import InvoiceTable from './components/invoiceTable';
import { Button } from 'react-bootstrap';
import Modal from "../toolbox/modal";
import ConfirmationModal from "./components/confirmationModal";
import i18n from '../../i18n'


const Invoice = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const search = useLocation().search;
    const invoiceId = new URLSearchParams(search).get('i') || location.pathname.split("/")[2]
    const panelState = new URLSearchParams(search).get('pm');
    const user = useSelector(state => state.user.userData)
    const invoice = useSelector(state => state.invoices.invoiceShow)
    const ref = useRef(null);
    const responsiveOne = useSelector((state) => state.navigations.responsiveOne)
    const openNavbar = useSelector((state) => state.navigations.openNavbar)

    useEffect(() => {
        dispatch(getInvoice(invoiceId))
    }, [invoiceId]);

    const showModal = () => {
        ref.current?.onShow()
    }


    const renderSendButton = () => {
        if (invoice?.current) {
          return <Button className="btn btn-primary" disabled
          >
            {i18n.t('views.invoices.not_done_month')}
            </Button>
        } else if (invoice?.status == "pending" && invoice?.passed > 0) {
          return <Button className="btn btn-primary" disabled >{i18n.t('views.invoices.send')}</Button>
        } else if (invoice?.status == "pending") {
          return (
            <Button className="btn btn-primary" disabled={invoice?.has_pending_recredit_ticket} onClick={() => {showModal()}}>{i18n.t('devise.invitations.new.submit_button')}</Button>
          )
        } else {
          return <Button className="btn btn-primary" disabled >{i18n.t(`views.invoices.${invoice?.status}`)}</Button>
        }
      }

      //const viewText = this.props.invoices.status == "paid" ? "Visualiser" : 'Prévisualiser'

    if (invoice.ref) {
        if (panelState == "s") {
            return (
              <div className={` page-container ${openNavbar && 'responsive-hidden'} ${(panelState=='s' && responsiveOne) && "responsive-panel"}`} >
                    <div className="invoice">
                        <div className="invoice-header">
                            <h3>{i18n.t('views.invoices.invoice')} - {invoice.month_year}</h3>
                            <p className="subtitle ref"># {invoice.ref}</p>
                            <Status status={invoice.status}/>
                        </div>
                        <div className="invoice-body">
                            <InvoiceTable data={invoice.data}/>
                        </div>
                        <div className="invoice-footer">
                            <h3>Total : {invoice.fixed_amount_incl}</h3>
                            <div className="invoice-actions">
                                {renderSendButton()}
                                <a href={`/invoices/${invoice.id}/pdf.pdf`} target="_blank">{invoice?.status == "paid" ?
                                `${i18n.t('views.invoices.visualize')}`
                                :
                                `${i18n.t('views.invoices.preview')}`

                                }</a>
                            </div>
                        </div>
                    </div>
                    <Modal ref={ref}>
                        <ConfirmationModal />
                    </ Modal>
                </div>

            )
        }
    }
    // to update with data from controller & translation
    return (
        <div className="invoice">
            {i18n.t('navigation.teacher.loading')} ...
        </div>
    )

};

export default Invoice;

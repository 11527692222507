import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {setFiltersLesson, setFiltersTraining, setResponsiveFilterCleanBtn} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'

const ByStudentStatus = ({model, action, loading}) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.filters[model].student_status)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
  const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
  const ref = useRef()

	const [options, setOptions] = useState([
		{ value: 'active', label: `${i18n.t('activerecord.attributes.student.status.active')}` },
		{ value: 'inactive', label: `${i18n.t('activerecord.attributes.student.status.inactive')}` },
		{ value: 'passed', label: `${i18n.t('activerecord.attributes.student.status.passed')}`},
		{ value: 'empty', label: `${i18n.t('activerecord.attributes.student.status.empty')}` }
	])


	//to-do: options will take from backend

	const dispatcher = {
		"setFiltersTraining": (status) => dispatch(setFiltersTraining({student_status: status})),
	}

	const  onSelectChange = value => {
    // if(responsiveFilter){
    //   dispatch(setResponsiveFiltersArray({action,value,type:'studentStatus'}))
    // }
    // else{
      dispatcher[action](value)
    //}
	}

  if(isCleaned){
    ref.current?.select.clearValue();

  }

	return (
		<div className='filter by_student_status'>
			<div className="line">
				<label htmlFor="filter_type" id="student_status" className="s_label">
        {i18n.t('filters.by_student_status')}
          </label>
				<Select
					ref={ref}
					isLoading={loading}
					isDisabled={loading}
					cacheOptions
					onChange={onSelectChange}
					isMulti
					options={options}
					defaultValue={current}
          placeholder={`${i18n.t('helpers.select.prompt')}...`}
				/>
			</div>
		</div>
	)

};

export default ByStudentStatus;

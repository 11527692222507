import React, {useEffect, useState, useRef} from "react";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import { useSelector } from "react-redux";
import EventDetail from './event_detail';
import { Link } from "react-router-dom";
import i18n from '../../../i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ReactHtmlParser from 'react-html-parser';

const ReviewPartTitle = (attr, editable) => {
    const user = useSelector(state => state.user.userData)

    if (user.role == "Teacher" && editable) {
        return(
            <div className="review-part-title">
                <h4>{i18n.t(`views.lessons.review.${attr}`)} :</h4>
                <OverlayTrigger
                    trigger="click"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>{ReactHtmlParser (i18n.t(`views.lessons.review.information.${attr}`))}</Tooltip>}
                    >
                <FontAwesomeIcon className="icon-details" icon={faInfoCircle} color="#1ABC9C" />
                </OverlayTrigger>
            </div>
        )
    } else {
        return(
            <div className="review-part-title">
                <h4>{i18n.t(`views.lessons.review.${attr}`)} :</h4>
            </div>
        )
    }

};


export default ReviewPartTitle;



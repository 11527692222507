

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setFiltersAvailabilities } from "../filters/filtersSlice";
import { fetchGroupedAvailabilities, fetchWeekType } from "../availabilities/availabilitiesSlice";
import Avatar from "../teachers/avatar";
import Calendar from "../toolbox/calendar";
import Event from './components/event';
import Header from "../lessons/components/header";
import Modal from "../toolbox/modal";
import StateManagement from "../lessons/components/state_management";



export default function AdminWeekType(){

  const dt = new Date();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [start_at, setStartAt] = useState(null)
  const [end_at, setEndAt] = useState(null)
  const weekType = useSelector(state => state.availabilities.weekType)
  const location = useLocation();
  const search = location.search;
  const path = location.pathname
  const ref = useRef(null);
  const modalState = new URLSearchParams(search).get('pm');
  let filters = useSelector(state => state.filters.filtersLesson);
  const openNavbar = useSelector((state) => state.navigations.openNavbar)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)




  useEffect(() => {
    let teachers = [filters.teachers]
    dispatch(fetchWeekType({teachers, start_at, end_at}))
}, [start_at, filters]);



  useEffect(() => {
      if (modalState == 'm') {
          ref.current?.onShow()
      } else {
          ref.current?.onHide()
      }
  }, [modalState]);

  const changeDate = (date) => {

  }


  const handleSelect = (newEvent) => {
    navigate(`?slot=${JSON.stringify(newEvent)}&pm=m&state=new_slot&teacher=${filters?.teachers?.value}`)
}

  return (
    <div className="main-content">
    <div className= {`page-container ${(openNavbar||responsiveFilter) && 'responsive-hidden'}`} >

        <Header adminAgenda />
        {( filters.teachers?.label && location.pathname == "/admin_week_type") && <div style={{marginBottom:'40px'}} > <Avatar calendar/> </div>  }
        <Calendar data={weekType} date={dt} changeDate={changeDate} selectable={true} event={Event} scope="admin_week_type" handleSelect={handleSelect} />

        </div>
    <Modal ref={ref}>
        <StateManagement />
    </ Modal>
</div>
  )


}

import React from "react"
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function UpsellButton({ trainingId, ticket_upsell, note }) {

  const user = useSelector(state => state.user.userData)
  const navigate = useNavigate();

  const overlay = (
    <Popover id="popover-basic">
     <Popover.Content style={{ padding: '10px' }}>
      {note}
    </Popover.Content>
    </Popover>
  )

  if (ticket_upsell) {
    if(user?.role==='HumanResource' || user?.role==='Teacher' || user?.role==='Admin' ){
      return (
      <OverlayTrigger
        key={trainingId}
        trigger="hover"
        placement="bottom"
        containerPadding={20}
        rootClose
        overlay={
          overlay
        }
      >

        <div
          className={`general-icon small-medium ${ticket_upsell ? 'upsell' : 'upsell-non'}  `}
          style={{ cursor: user?.role === 'HumanResource'  || user?.role ==='Admin' ? 'not-allowed' : '' }}
          onClick={() => {
            user?.role === 'Teacher' &&
              (!ticket_upsell ? navigate(`?u=${trainingId}&pm=m&state=training_upsell`) : navigate(`?uc=${trainingId}&pm=m&state=training_upsell_end`))
          }}
        ></div>
      </OverlayTrigger>
    )
  }

  }

      return (
        <div
          className={`general-icon small-medium ${ticket_upsell ? 'upsell' : 'upsell-non'}  `} style={{ cursor: user?.role === 'HumanResource'  || user?.role ==='Admin' ? 'not-allowed' : '' }}
          onClick={() => {
            user?.role === 'Teacher' &&
              (!ticket_upsell ? navigate(`?u=${trainingId}&pm=m&state=training_upsell`) : navigate(`?uc=${trainingId}&pm=m&state=training_upsell_end`))
          }}
        ></div>
      )


}

import React from "react";
import Impersonate from "../../user/impersonate";
import SkillsTooltip from "../components/skillsTooltip";

const columns = [
  {
    name: "id",
    selector: row => row.id,
    omit: true
  },
  {
    name: "Professeur",
    selector: row => {
      return (
        <div className="table-sub-list">
          <div className="list-item bold">{row["0 Prénom"]} {row["1 Nom"]}</div>
          <div className="list-item small">{row["8 Email"]}</div>
        </div>
      )
    },
    width: "20%",
  },
  {
    name: "Manager",
    selector: row => {
      return (
        <div className="table-sub-list">
          <div className="list-item bold">{row.manager}</div>
        </div>
      )
    },
    width: "10%",
  },
  {
    name: "Statut",
    selector: row => {
      return (
        <div className="table-sub-list">
          <div className="list-item"><div className="label">Statut :</div> {row.status}</div>
          <div className="list-item"><div className="label">Veut des élèves :</div> {row.want_student ? "Oui" : "Non"}</div>
        </div>
      )
    },
    width: "10%"
  },
  {
    name: "Français",
    selector: row => row.french_level,
    width: "6%",
  },
  {
    name: "Enseignement",
    width: "20%",
    wrap: true,
    selector: row => {
      return (
        <div className="table-sub-list">
          <div className="list-item"><div className="label">Type d'anglais :</div><div className="value">{row.english_type?.join(', ')}</div></div>
          <div className="list-item"><div className="label">Niveaux souhaités :</div><div className="value">{row.required_students?.join(', ')}</div></div>

          <div className="list-item"><div className="label">Compétences :</div> <div className="value">
            <SkillsTooltip skills={row.professional_competences} />
          </div> </div>
        </div>
      )
    },
  },
  {
    name: "Modalités",
    selector: row => {
      return (
        <div className="table-sub-list">
          {row.teaching_model.map(tm => {
            return <div className="list-item">{tm}</div>
          })}
        </div>
      )
    },
    width: "12%",
  },
  {
    name: "Facturation",
    selector: row => {
      return (
        <div className="table-sub-list">
          <div className="list-item"><div className="label">Pays : </div>{row.country_code}</div>
          <div className="list-item"><div className="label">Devise : </div>{row.currency_code}</div>
          <div className="list-item"><div className="label">CP tx jour : </div>{row.day_rate}</div>
        </div>
      )
    },
    width: "10%",
  },
  {
    name: "Tx Occupation",
    width: "5%",
    selector: row => <div>{row.fill_rate}</div>,
  },
  {
    name: "",
    width: "8%",
    selector: row => <Impersonate user_id={row.user_id} />,
  }
];

export default columns

import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
	setResponsiveFilterCleanBtn,
	setFiltersAvailabilities,
	setFiltersTeachers
} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'


const ByWantStudent = ({model, action, loading}) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.filters[model].want_student)
	const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
	const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
	const ref = useRef()

	const [options, setOptions] = useState([
		{ value: true, label: `${i18n.t('active_admin.status_tag.yes')}` },
		{ value: false, label: `${i18n.t('active_admin.status_tag.no')}` },
		])

	const dispatcher = {
		"setFiltersAvailabilities": (value) => dispatch(setFiltersAvailabilities({want_student: value})),
		"setFiltersTeachers": (value) => dispatch(setFiltersTeachers({want_student: value})),
	}

	const  onSelectChange = value => {
		// if(responsiveFilter){
		// 	dispatch(setResponsiveFiltersArray({action,value,type:'type'}))
		// }
		// else{
			dispatcher[action](value)
		//}
	}

	if(isCleaned){
		ref.current?.select.clearValue();
	}

	return (
		<div className='filter by_level'>
			<div className="line">
				<label htmlFor="filter_type" id="level" className="s_label">
        {i18n.t('filters.by_want_student')}
          </label>
				<Select
					ref={ref}
					isLoading={loading}
					isDisabled={loading}
					cacheOptions
					onChange={onSelectChange}
					isMulti = {false}
					options={options}
					defaultValue={current}
          placeholder={`${i18n.t('helpers.select.prompt')}...`}				/>
			</div>
		</div>
	)

};

export default ByWantStudent;

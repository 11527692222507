

import React from "react";


export default function TeacherCardItem({ event, item, onRowClicked, rootPage }) {


  return (
    <div className={`item-container ${rootPage}`} >
      <div className="header">
        <span className="teacher">Professeur: {item["0 Prénom"]} {item["1 Nom"]} </span>
        {<span className="teacher">Manager: {item?.manager} </span>}

        <span className={`status ${item?.state}`}>{ }</span>

      </div>
      <div className="date-card-list">
        <span className="date-card-list-string"> {item?.date} </span>

      </div>
      <div className="level-area">
        <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
        <div className="teacher-status level-type">
        <span className="statusOne" >Statut : {item.status}</span>
        <span className="statusOne" >Veut des élèves :{item.want_student ? "Oui" : "Non"}</span>

        </div>

        <span className="level"> {item.french_level} </span>
        </div>



      </div>

      <div className="icon-area" >

        <span className="level"> {
          item?.app_document ?
            <div > <a target="_blank" href={item?.app_document}> <FontAwesomeIcon className="icon-details" icon={faDownload} color="#AFAFAF" /> </a></div>
            : ''
        }  </span>

      </div>



    </div>
  )
}

import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {setFiltersLesson, setFiltersTraining} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'


const ByAlertStatus = ({model, action, single, loading}) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.filters[model].alert_status)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
  const ref = useRef()
  const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)



	const [options, setOptions] = useState([
		{ value: true, label: `${i18n.t('active_admin.status_tag.yes')}`
      },
		{ value: false, label: `${i18n.t('active_admin.status_tag.no')}` },
	])


	//to-do: options will take from backend

	const dispatcher = {
		"setFiltersTraining": (status) => dispatch(setFiltersTraining({alert_status: status})),
	}

	const  onSelectChange = value => {
    // if(responsiveFilter){
    //   dispatch(setResponsiveFiltersArray({action,value,type:'alertStatus'}))
    // }
    // else{
      dispatcher[action](value)
    //}
	}


  if(isCleaned){
    ref.current?.select.clearValue();

  }


	return (
		<div className='filter by_alert_status'>
			<div className="line">
				<label htmlFor="filter_type" id="alert_status" className="s_label">{i18n.t('views.trainings.alert')}</label>
				<Select
        ref={ref}

					cacheOptions
					onChange={onSelectChange}
					isMulti={!single}
					options={options}
					defaultValue={current}
					isLoading={loading}
					isDisabled={loading}
          placeholder={`${i18n.t('helpers.select.prompt')}...`}
				/>
			</div>
		</div>
	)

};

export default ByAlertStatus;

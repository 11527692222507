import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
 setFiltersAvailabilities,
 setFiltersTeachers
} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'


const ByTarif = ({ model, action }) => {
  const dispatch = useDispatch()
  const current = useSelector(state => state.filters[model].tarif)
  const [options, setOptions] = useState([])
  const responsiveFilter = useSelector((state) => state.navigations.openResponsiveFilters)
  const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
  const ref = useRef(null)


  const dispatcher = {
    "setFiltersTeachers": (value) => dispatch(setFiltersTeachers({tarif: value})),
  }

  const onChange = () => {
      if (event.target.value > 10 ||  event.target.value == "") {
        dispatcher[action](event.target.value)
      }
  }


  if(isCleaned){
    //ref.current?.clearValue();
  }


  return (
    <div className='filter by_tarif'>
      <div className="line">
        <label htmlFor="filter_type" id="tarif" className="s_label">
          {i18n.t('filters.by_tarif')}
        </label>
        <input className="input-filter" type='number' id="tarif" defaultValue={  current } onChange={ ()=>{ onChange()} }></input>
      </div>
    </div>
  )

};

export default ByTarif;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import {
  setFiltersLesson,
  setFiltersTraining,
  setFiltersConferences,
  setFiltersAvailabilities,
  setFiltersCoursCollectives, setFiltersTeachers, setFiltersTickets,
  setResponsiveFilterCleanBtn
} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'

const ByTeacher = ({ model, action, single, loading }) => {
  const dispatch = useDispatch()
  const current = useSelector(state => state.filters[model].teachers)
  const responsiveFilter = useSelector((state) => state.navigations.openResponsiveFilters)
  const isCleaned = useSelector((state) => state.filters.responsiveFilterCleanBtn)
  const ref = useRef(null)
  const [options, setOptions] = useState([])


  const loadTeacherOptions = (inputValue, callback) => {
    const url = `/api/v2/filters/get_teacher_options?input=${inputValue}&scope=${model}`
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
      .content.value;
    const promise = fetch(url, { credentials: "same-origin" }).then(r =>
      r.json()
    ).then(data => {
      callback(data)
    });
  }

  const loadTeacherOptionsForSelect = () => {
    const url = model === 'filtersCoursCollectives' ? `/api/v2/filters?filter=collective_lesson_teacher` : `/api/v2/filters?filter=conference_teacher`
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
      .content.value;
    const promise = fetch(url, { credentials: "same-origin" }).then(r =>
      r.json()
    ).then(data => {
      setOptions(data)
    });
  }

  useEffect(() => {
    if(model ==='filtersCoursCollectives' || model === 'filtersConferences'){
      loadTeacherOptionsForSelect()
    }
  }, []);

  const dispatcher = {
    "setFiltersLesson": (ids) => dispatch(setFiltersLesson({ teachers: ids })),
    "setFiltersTraining": (ids) => dispatch(setFiltersTraining({ teachers: ids })),
    "setFiltersConferences": (ids) => dispatch(setFiltersConferences({ teachers: ids })),
    "setFiltersAvailabilities": (ids) => dispatch(setFiltersAvailabilities({ teachers: ids })),
    "setFiltersCoursCollectives": (ids) => dispatch(setFiltersCoursCollectives({ teachers: ids })),
    "setFiltersTeachers": (ids) => dispatch(setFiltersTeachers({ teachers: ids })),
    "setFiltersTickets": (ids) => dispatch(setFiltersTickets({ teachers: ids })),
  }

  const onSelectChange = value => {
    // if (responsiveFilter) {
    //   dispatch(setResponsiveFiltersArray({ action, value, type: 'teacher' }))
    // }
    // else {
    dispatcher[action](value)
    //}
  }


  if (isCleaned) {

    ref.current?.select?.select.setValue('');

  }

  return (
    <div className='filter by_student'>
      <div className="line">
        <label htmlFor="filter_type" id="teacher" className="s_label">
          {i18n.t('filters.by_teacher')}

        </label>

        {
          model === 'filtersCoursCollectives' || model === 'filtersConferences' ?
          <Select
            ref={ref}
            cacheOptions
            onChange={(value) => { onSelectChange(value) }}
            isMulti
            isLoading={loading}
            isDisabled={loading}
            options={options}
            defaultValue={current}
            placeholder={`${i18n.t('helpers.select.prompt')}...`}
          /> :
            <AsyncSelect
              ref={ref}
              cacheOptions
              onChange={onSelectChange}
              isMulti={!single}
              isLoading={loading}
              isDisabled={loading}
              defaultValue={current}
              noOptionsMessage={() => `${i18n.t('filters.start_search')}`}
              loadOptions={loadTeacherOptions}
              placeholder={`${i18n.t('helpers.select.prompt')}...`}

            />
        }

      </div>
    </div>
  )

};

export default ByTeacher;

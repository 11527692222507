import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from "redux-logger";
import { thunk } from 'redux-thunk'

import App from "./app";

import userReducer from './user/userSlice';
import lessonsReducer from './lessons/lessonsSlice';
import teachersReducer from './teachers/teachersSlice';
import filtersReducer from './filters/filtersSlice';
import trainingsReducer from './trainings/trainingsSlice';
import lessonTransitionReducer from './lessons/lessonTransitionSlice'
import elearningReducer from './e-learning/elearningSlice'
import availabilitiesReducer from './availabilities/availabilitiesSlice';
import conferenceReducer from './conferences/conferencesSlice';
import invoicesReducer from './invoices/invoicesSlice';
import ticketsReducer from './tickets/ticketsSlice';
import coursCollectivesReducer from './cours-collectives/coursCollectivesSlice'
import studentReducer from './student/studentSlice';
import navigationReducer from './navigation/navigationSlice'
import notificationsReducer from './notifications/notificationsSlice'
import managersReducer from './managers/managerSlice'


const logger = createLogger();

export function makeStore() {
  return configureStore({
    devTools: true,
    middleware: (getDefaultMiddleware) => {
      return [logger, thunk]
    },
    reducer: {
      user: userReducer,
      lessons: lessonsReducer,
      teachers: teachersReducer,
      filters: filtersReducer,
      trainings: trainingsReducer,
      lessonTransition: lessonTransitionReducer,
      elearnings: elearningReducer,
      availabilities: availabilitiesReducer,
      conferences: conferenceReducer,
      invoices: invoicesReducer,
      coursCollectives: coursCollectivesReducer,
      tickets: ticketsReducer,
      students: studentReducer,
      navigations: navigationReducer,
      notifications: notificationsReducer,
      managers: managersReducer
    },
  });
}

const store = makeStore();

Sentry.init({
  dsn: "https://cdf904313c0fe25872413c53c0697dca@o877596.ingest.us.sentry.io/4508102776520704",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app.cercledeslangues.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const ele = document.getElementById('app')
if (ele) {
  const root = createRoot(ele);
  root.render(
      <Provider store={store}>
          <App />
      </Provider>
  );
}
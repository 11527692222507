import React, { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector, ReactReduxContext } from "react-redux";
import { fetchTickets, resetTickets } from "./ticketsSlice";
import { resetFiltersTickets, setFiltersLesson, setFiltersTickets } from "../filters/filtersSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "../tickets/components/header";
import DataTable from "../toolbox/datatable";
import AvailabilityColumns from './columns/availability';
import RecreditColumns from './columns/recredit';
import AdminAvailabilityColumns from './columns/admin_availability';
import AdminRecreditColumns from './columns/admin_recredit';
import StudentColumns from './columns/student';
import AdminStudentColumns from './columns/admin_student';
import CardList from "../toolbox/cardList";
import TicketListItem from "./components/ticketListItem";



const Tickets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname
  const filters = useSelector(state => state.filters.filtersTickets);
  const user = useSelector(state => state.user.userData);
  const { ticketsList, loading } = useSelector((state) => state.tickets)
  const [currentRow, setCurrentRow] = useState(null);
  const ref = useRef(null);
  const { store } = useContext(ReactReduxContext)
  const state = store.getState();
  const [page, setPage] = useState(1);
  const [columns, setColumns] = useState(AvailabilityColumns);
  const openNavbar = useSelector((state) => state.navigations.openNavbar)
  const responsiveFilter = useSelector((state) => state.navigations.openResponsiveFilters)
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)


  useEffect(()=>{
    dispatch(resetFiltersTickets())
  },[])

  useEffect(() => {
    if (path == '/tickets/recredit') {
      dispatch(setFiltersTickets({ type: "lessons" }));
      if (user.role == "Admin") {
        setColumns(AdminRecreditColumns)
      } else {
        setColumns(RecreditColumns);
      }
    }
    else if (path == '/tickets/availability') {
      dispatch(setFiltersTickets({ type: "availabilities" }));
      if (user.role == "Admin") {
        setColumns(AdminAvailabilityColumns);
      } else {
        setColumns(AvailabilityColumns);
      }
    } else if (path == '/tickets/students') {
      dispatch(setFiltersTickets({ type: "students" }));
      if (user.role == "Admin") {
        setColumns(AdminStudentColumns);
      } else {
        setColumns(StudentColumns);
      }
    } else {
      dispatch(setFiltersTickets({ type: "availabilities" }));
    }
  }, [user, path]);

  useEffect(() => {
    const state = store.getState();
    const filters = state.filters.filtersTickets
    setPage(1)
    dispatch(resetTickets())
    dispatch(fetchTickets({ page: 1, filters }))
  }, [filters]);


  useEffect(() => {
    const state = store.getState();
    const filters = state.filters.filtersTickets
    if (page && !loading && filters.type) {
      // dispatch(resetTrainings())
      dispatch(fetchTickets({ page, filters }))
    }
  }, [page, filters]);


  return (
    <div className={`main-content`}>

      <div className={`datatable page-container ${(openNavbar || responsiveFilter) && 'responsive-hidden'}`} >

        <Header />
        {!responsiveOne ? <DataTable
          columns={columns}
          data={ticketsList}
          setPassedPage={setPage}
          passedPage={page}
          loading={loading}
          ref={ref}
          filters={filters}
        /> : <CardList

          data={ticketsList}
          onRowClicked={(row) => setCurrentRow(row)}
          setPassedPage={setPage}
          passedPage={page}
          loading={loading}
          // futurePage={futurePage}
          // setFuturePage={setFuturePage}
          ref={ref}
          rootPage='ticket'
          card={<TicketListItem />} />

        }

      </div>
    </div>
  )
}

export default Tickets;

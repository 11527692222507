import React from "react";
import TableCancelAction from "./tableCancelAction";
import TableDemandAction from "./tableDemandAction";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommentTooltip from "../components/commentTooltip";
import i18n from "../../../i18n";

const recreditColumns = [
  {
    name: "id",
    selector: row => row.id,
    omit: true
  },
  {
    name: i18n.t('activerecord.attributes.tickets.created_at'),
    selector: row => row.date,
    width:'8%'
  },
  {
    name: i18n.t('activerecord.attributes.tickets.training'),
    selector: row => row.training,
  },

  {
    name: i18n.t('activerecord.attributes.tickets.lesson_date'),
    selector: row => {
      return (
        <div className="table-sub-list">
          <div className="list-item"> {row?.event_date}</div>
          <div className="list-item"><div className="ref">{row?.event_hours}</div></div>
        </div>
      )
    },
  },

  {
    name: i18n.t('activerecord.attributes.tickets.reason'),
    selector: row => row.recredit_reason,
  },
  {
    name: i18n.t('activerecord.attributes.tickets.teacher_note'),
    selector: row => <CommentTooltip note={row.note} />
  },
  {
    name: i18n.t('activerecord.attributes.tickets.manager_note'),
    selector: row => (
      <div style={{ marginLeft: '10px' }}>
        <CommentTooltip note={row.admin_note} />
      </div>

    ),
  },

  {
    name: i18n.t('activerecord.attributes.tickets.statuses'),
    selector: row => {
      return (
        <div style={{marginLeft:'4px'}} className="table-sub-list">
          <div className="list-item"><div className="label">Stt. initial</div> {row?.previous_state}</div>
          <div className="list-item"><div className="label">Stt. demandé</div><div className="value">{row?.expected}</div></div>
        </div>
      )
    },
  },


  {
    name: i18n.t('activerecord.attributes.tickets.cancel_button'),
    selector: row => {
      return (
        <div className="table-sub-list">
        { (row?.state !== 'refused' && row?.state !== 'validated') &&  <TableCancelAction data={row?.canceled} id={row.id} />}
        </div>
      )
    },
  },
  {
    name: i18n.t('activerecord.attributes.tickets.status'),
    selector: row => {
      return (
        <TableDemandAction data={row?.state} id={row.id} />
      )
    },
  },

  {
    name: i18n.t('activerecord.attributes.tickets.document'),
    selector: row => {
      return (
        <div className="table-sub-list">
          {
            row?.app_document ?
            <div className="list-item" > <a target="_blank" href={row?.app_document}> <FontAwesomeIcon className="icon-details" icon={faDownload} color="#AFAFAF" /> </a></div>
              : ''
          }
        </div>
      )
    },
  },

];

export default recreditColumns

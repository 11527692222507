import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import i18n from "../../../i18n";

const CreditCount = () => {
  const user = useSelector(state => state.user.userData)
  const location = useLocation();
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)


  if (user.role == "Student") {
    if (responsiveOne) {
      return (
        <div className="responsive-credit-count">
            <span className="res-circle"  > {(location.pathname.includes("collective")) ? user.collective_credits : user.credits} </span>
          </div>
      )
    }
    else {
      return (
        <div className='credit-count'>
          {(location.pathname.includes("collective")) ?
          user.collective_credits <= 1 ? `${user.collective_credits} ${i18n.t('credits.none_or_single_left')} ` : `${user.collective_credits} ${i18n.t('credits.left')}`
          :
          user.credits <= 1 ? `${user.credits} ${i18n.t('credits.none_or_single_left')}` : `${user.credits} ${i18n.t('credits.left')} `}
        </div>
      )
    }

  } else {
    return <></>
  }

};

export default CreditCount;

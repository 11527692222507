import React from "react"
import TableDemandAction from "../columns/tableDemandAction"
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";

export default function AtasListItem({ event, item, onRowClicked, rootPage }) {

  const location = useLocation();
  const path = location.pathname

  const getTrad = (value) => {
    if (value == "canceled") {
      return 'Annulé'
    } else if (value == "late_cancelation") {
      return 'Annulé tardivement'
    } else if (value == "canceled_by_teacher") {
      return 'Annulé par le professeur'
    } else if (value == "booked") {
      return 'Réservé'
    } else if (value == "done") {
      return 'Réalisé'
    } else if (value == "passed") {
      return 'En attente'
    } else if (value == "issue") {
      return 'Problème'
    } else if (value == "create") {
      return 'Ouvert'
    } else if (value == "delete") {
      return 'Fermé'
    } else if (value == "pending") {
      return 'En cours'
    } else if (value == "noshow") {
      return 'Absent'
    } else if (value?.includes("planning")) {
        return 'Réservé'
    }
    else {
      return value
    }
  }


  return (
    <div className={`item-container ${rootPage}`} >
      <div className="header">
        <span className="teacher">Professeur: {path?.includes('students') ? item?.student_manager : item?.teacher} </span>
        {(item?.training) && <span className="teacher">Formation: {item?.training} </span>}
        <span className={`status ${item?.expected}`}>{getTrad(item?.expected)}</span>

      </div>
      <div className="date-card-list">
        <span className="date-card-list-string"> {item?.date} </span>
        <span className="date-card-list-string-hour" > {item?.event_hours} </span>

      </div>
      <div className="level-area">
        <span className="note">{(item?.note)}</span>

      </div>

    </div>
  )
}

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
 setFiltersLesson,
 setFiltersELearning,
 setFiltersConferences
} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'


const ByContent = ({ model, action }) => {
  const dispatch = useDispatch()
  const current = useSelector(state => state.filters[model].content)
  const responsiveFilter = useSelector((state) => state.navigations.openResponsiveFilters)
  const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)


  const dispatcher = {
    "setFiltersLesson": (value) => dispatch(setFiltersLesson({content: value})),
    "setFiltersELearning": (value) => dispatch(setFiltersELearning({content: value})),
    "setFiltersConferences": (value) => dispatch(setFiltersConferences({content: value})),


  }

  const onChange = () => {
      if (event.target.value.length > 2 || event.target.value == "") {
        dispatcher[action](event.target.value)
      }
  }


  if(isCleaned){
    //ref.current?.clearValue();
  }


  return (
    <div className='filter by_content'>
      <div className="line">
        <label htmlFor="filter_type" id="content" className="s_label">
          {i18n.t('filters.by_content')}
        </label>
        <input className="input-filter" type='text' id="content" defaultValue={  current } onChange={ ()=>{ onChange()} }></input>
      </div>
    </div>
  )

};

export default ByContent;

import React, {useState, forwardRef, useEffect} from "react";
import DataTable from 'react-data-table-component';
import NoData from "./noData";

const DataTableBase = forwardRef(function DataTableBase({columns, data, onRowClicked, setPassedPage, passedPage, loading, futurePage, setFuturePage, conditionalRowStyles, clickable=true, filters={}}, ref) {
	const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0 });
	const [hasFilter, setHasFilter] = useState(false)


	//TO DO: handle hasMore for both future and passed page

	// wheel is used for element in future (wheelup)
	const handleWheel = (event) => {
		if (ref.current && !loading && event.deltaY < 0 && setFuturePage) {
			if (ref.current.scrollTop == 0 && scrollPosition.scrollTop == ref.current.scrollTop) {
				setFuturePage(futurePage + 1);
				//setLoading(true)
			}
		}
	}

	// scroll is used for element in past (srolldown)
	const handleScroll = () => {
		if (ref.current && !loading && setPassedPage) {
		  const { scrollTop } = ref.current;
		  const tableHeight = document.getElementsByClassName('rdt_Table')[0].clientHeight
		  if (scrollTop + window.innerHeight + 200 >= tableHeight && scrollPosition.scrollTop < scrollTop) {
			setPassedPage(passedPage + 1);
			//setLoading(true)
		  }
		  setScrollPosition({ scrollTop });
		}
	  };

	useEffect(() => {
		let values = Object.keys( filters ).filter( key => {
			return (filters[key] && filters[key].length !== 0 && key != 'type')
		})
		setHasFilter(values.length > 0 ? true : false)
		// return setHasFilter()
	}, [filters])


	return (
		<div className={`datatable-container ${hasFilter && 'has_active_filters'}`} ref={ref} onScroll={() => handleScroll()} onWheel={handleWheel}>
			<DataTable
				pointerOnHover={clickable}
				highlightOnHover={clickable}
				selectableRowsHighlight={clickable}
				columns={columns}
				data={data}
				onRowClicked={onRowClicked}
        noDataComponent={<NoData/>}
				conditionalRowStyles={conditionalRowStyles}
			/>
		</div>
	);
})

export default DataTableBase;

import React, { useState } from "react"
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from '../../../i18n'
import { Collapse } from "react-bootstrap";

export default function TestResult({ data, isFinal }) {
  const [openSummary, setOpenSummary] = useState(false)
  const scope = data?.test_type?.includes("360") ? "360" : "others"

  const renderTabLine = (intitule, value, display, weight, is_new_score) => {
    const percantageChar = is_new_score && value !== '' ? '%' : ''
    if (display) {
      return (
        <tr valign="top">
          <td className={`intitule ${weight}`}>{intitule}</td>
          <td>
            {value !== null ? `${value}${percantageChar}` : i18n.t('activerecord.attributes.student.status.empty')}
          </td>
        </tr>
      )
    } else {
      return <></>
    }
  }

  const toggleSummary = () => {
    setOpenSummary(!openSummary)
  }


  if (!data?.test_score_cdl && isFinal) {
    return <p>  {i18n.t('views.trainings.chart.no-results')}</p>
  }


  return (
    <>
      <div className="toggle-title" aria-expanded={openSummary} onClick={() => { toggleSummary(openSummary) }}>
        <p>  {i18n.t('views.trainings.chart.feedback')}
        </p>
        <FontAwesomeIcon size={"2x"} color="#FFC400" className="icon-details" icon={openSummary ? faChevronUp : faChevronDown} />
      </div>
      <Collapse in={openSummary}>
        <table className="skills training-table">
          <tbody>
            {/* 360 */}
            {renderTabLine(i18n.t('views.trainings.test.total_level'), data?.test_score_360, (scope === "360" && !isFinal), 'strong')}

            {/* {renderTabLine(i18n.t('views.trainings.test.listening'), data?.test_listening_360, (scope == "360" && !isFinal))} */}
            {renderTabLine(i18n.t('views.trainings.test.reading'), data?.test_reading_360, (scope == "360" && !isFinal))}
            {renderTabLine(i18n.t('views.lessons.review.grammar'), data?.test_grammar_360, (scope == "360" && !isFinal))}
            {renderTabLine(i18n.t('views.trainings.test.writing'), data?.test_writing_360, (scope == "360" && !isFinal))}
            {renderTabLine(i18n.t('views.trainings.test.spoken_interaction'), data?.test_spoken_int_360, (scope == "360" && !isFinal))}
            {renderTabLine(i18n.t('views.trainings.test.spoken_production'), data?.test_spoken_prod_360, (scope == "360" && !isFinal))}
            {/* {this.renderTabLine("Résumé", training.test_resume_360, (scope == "360" && !isFinal),'strong')} */}

            {/* others */}
            {renderTabLine(i18n.t('views.trainings.test.test_score_total'), data?.test_score_total, (scope == "others" && !isFinal))}
            {renderTabLine(i18n.t('views.trainings.test.listening'), data?.test_score_listening, (scope == "others" && !isFinal))}
            {renderTabLine(i18n.t('views.trainings.test.reading'), data?.test_score_reading, (scope == "others" && !isFinal))}
            {/* {this.renderTabLine("Résumé", training.test_resume_others, (scope == "others" && !isFinal))} */}

            {/* final */}
            {renderTabLine(i18n.t('views.trainings.test.test_score_total'), data?.test_score_cdl, isFinal, 'strong', data?.is_new_score_version)}
            {renderTabLine(i18n.t('views.lessons.review.grammar'), data?.test_score_cdl_grammar, isFinal, undefined, data?.is_new_score_version)}
            {renderTabLine(i18n.t('views.trainings.test.listening'), data?.test_score_cdl_listening, isFinal, undefined, data?.is_new_score_version)}
            {renderTabLine(i18n.t('views.trainings.test.reading'), data?.test_score_cdl_reading, isFinal, undefined, data?.is_new_score_version)}
          </tbody>
        </table>
      </Collapse>
    </>
  )



}

import React, { useEffect, useState } from "react"
import calendarIcon from '../../images/icons/Group.svg'
import studentIcon from '../../images/icons/student-thin.svg'
import avatar from '../../images/student.png'
import i18n from "../../../i18n"

export default function ConferenceCard({ item }) {
   const [thumbnail, setThumbnail] = useState("")

   useEffect(() => {
    if (item.teacher_avatar){
      const id = item.teacher_avatar.split('/')[5]
      id && setThumbnail(`https://drive.google.com/thumbnail?id=${id}&sz=w1000`)
    }
  }, [item])

  const renderSubscribe = () => {
    if (item.status == "replay") {
      return <a style={{backgroundColor:'#1abc9c'}} href={item.youtube_link} target="_blank" className="card-btn">{i18n.t('activerecord.attributes.conference.incoming_options.replay')}</a>
    } else if (item.status == "done") {
      return <a style={{backgroundColor:'#b0b0b0'}} onClick={(e) => e.preventDefault()} href={item.youtube_link} target="_blank" className="card-btn disabled">{i18n.t('activerecord.attributes.conference.incoming_options.done')}</a>
    } else {
      return <a href={item.subscribe_link} target="_blank" className="card-btn">{i18n.t('subscribe')}</a>
    }
  }

  const formatDate = (startAt) => {

    let formatedDate = startAt?.split('T')[0]?.split('-')?.reverse()?.join('/')
    return (
      <div className="date-container">
        <img className="icon" src={calendarIcon} />
        <span className="date-string"> {formatedDate} </span>
        <span className="date-string"> {item.hours} </span>
      </div>

    )
  }


  return (
    <div className="card-container" >
      <div className="card-avatar" >
        <img src={thumbnail} />
      </div>
      <div className="content" >
        <div className="card-header">

          <div className="card-header-top-row">
            <span className="teacher" > {item?.teacher} </span>

            {
              item?.level?.map((level, index) => {
                return (

                  <span key={index} className={`level ${level}`} > {level} </span>
                )
              })
            }

          </div>
          <div>
          <span className="title"> {item?.header} </span>

          </div>

        </div>

        <div className="card-content">
          {formatDate(item?.start_at)}
          <div className="conference-type" >
            {
              item?.conference_type?.map((type, index) => {
                return (
                  <div key={index} className="type-container">
                    <img className="conference-icon" src={studentIcon} />
                    <span className="conference-string">{type}</span>

                  </div>

                )
              })
            }
          </div>
          {renderSubscribe()}
        </div>
      </div>

    </div>
  )
}

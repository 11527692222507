import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import StepOne from "./steps/stepOne.js";
import StepTwo from "./steps/stepTwo";
import Modal from '../../toolbox/modal.js'
import StepThree from "./steps/stepThree";
import StepFour from "./steps/stepFour";
import StepFive from "./steps/stepFive";
import i18n from '../../../i18n.js'



const Chart = () => {
  const training = useSelector(state => state.trainings.trainingShow)
  const [step, setStep] = useState(null)

  const ref = useRef(null)

  const showModal = () => {
    ref.current?.onShow()
  }


  return (
    <>
      <div className="chart bloc">

        <div className="progression-header">
          <div className="left"></div>
          <div className="middle"><p className="header-content">{i18n.t('views.trainings.training')}</p></div>
          <div className="right"></div>
        </div>

        <div className="progression-body">

          <div className="left">
            <div className="title">{i18n.t('views.trainings.chart.video')}
            </div>
            <div className="progression-line">
              <div className="icon" onClick={() => {
                setStep('1')
                showModal()
              }}></div>
              <div className="line"></div>
            </div>
            <div className="info"></div>
          </div>


          <div className="middle">

            <div className="main-line">
              <div className="title"></div>
              <div className="progression-line"></div>
              <div className="info"></div>
            </div>

            <div className="sub-left">
              <div className="title">{i18n.t('views.trainings.chart.start')}
              </div>
              <div className="progression-line">
                <div className="icon" onClick={() => {
                  setStep('2')
                  showModal()
                }}></div>
                <div className="line"></div>
              </div>
              <div className="info">{training.start_at}</div>
            </div>

            <div className="sub-right">
              <div className="title">{i18n.t('views.trainings.chart.midterm')}
              </div>
              <div className="progression-line">
                <div className="line"></div>
                <div className="icon" onClick={() => {
                  setStep('3')
                  showModal()
                }}></div>
              </div>
              <div className="info">{training.certifying_end_at}</div>
            </div>

            <div className="main-line">
              <div className="title"></div>
              <div className="progression-line"></div>
              <div className="info"></div>
            </div>
          </div>

          <div className="right">
            <div className="sub-left">
              <div className="title">{i18n.t('views.trainings.chart.overview')}
              </div>
              <div className="progression-line">
                <div className="line"></div>
                <div className="icon" onClick={() => {
                  setStep('4')
                  showModal()
                }}></div>
                <div className="line"></div>
              </div>
              <div className="info">{training.bff_date}</div>
            </div>

            <div className="sub-right">
              <div className="title">{i18n.t('views.trainings.chart.end')}
              </div>
              <div className="progression-line">
                <div className="line"></div>
                <div className="icon" onClick={() => {
                  setStep('5')
                  showModal()
                }}></div>
              </div>
              <div className="info">{training.end_at}</div>
            </div>
          </div>
        </div>

        <div className="progression-footer">
          <div className="left">
            <div className="info">
              <div className="title">
                {training.certifying_hours}{i18n.t('views.trainings.chart.average_hours')}

              </div>
            </div>
          </div>


          <div className="middle-container">
            <div className="middle">
              <div className="footer-line">
                <div className="c-text">
                  <span className="first-letter">
                    {i18n.t('navigation.student.lessons').charAt(0)}
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {i18n.t('navigation.student.lessons').slice(1)}
                  </span>
                </div>
                <div className="c-line"></div>
              </div>
              <div className="footer-line">
                <div className="c-text"><span className="first-letter">{i18n.t('navigation.student.collective_lessons').charAt(0)}</span><span style={{ fontWeight: "bold" }}>
                {i18n.t('navigation.student.collective_lessons').slice(1)}</span></div>
                <div className="c-line"></div>
              </div>
              <div className="footer-line">
                <div className="c-text"><span className="first-letter">{i18n.t('navigation.student.elearnings').charAt(0)}</span><span style={{ fontWeight: "bold" }}>{i18n.t('navigation.student.elearnings').slice(1)}</span></div>
                <div className="c-line"></div>
              </div>
              <div className="footer-line">
                <div className="c-text"><span className="first-letter">{i18n.t('navigation.student.conferences').charAt(0)}</span><span style={{ fontWeight: "bold" }}>{i18n.t('navigation.student.conferences').slice(1)}</span></div>
                <div className="c-line"></div>
              </div>
            </div>
          </div>

          <div className="right">
            <div className="footer-line">
              <div className="c-line"></div>
            </div>
            <div className="footer-line">
              <div className="c-line"></div>
            </div>
            <div className="footer-line">
              <div className="c-line"></div>
            </div>
            <div className="footer-line">
              <div className="c-line"></div>
            </div>
          </div>
        </div>

      </div>

      <Modal ref={ref}>
        {
          (() => {
            switch (step) {
              case "1":
                return <StepOne data={training} />
              case "2":
                return <StepTwo data={training} />
              case "3":
                return <StepThree data={training} />
              case "4":
                return <StepFour data={training} />
              case "5":
                return <StepFive data={training} />
              default:
                return null;
            }
          })()
        }
      </ Modal>
    </>

  )

};

export default Chart;

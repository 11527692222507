import React, { useEffect, useRef } from "react"
import Card from "./card"
import i18n from '../../../i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';

export default function CardLine({exercices, type,setSelectedCard}){
    const ref = useRef(null);

    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
    };

    return(
        <div className="exercice-card-line">
            <div>
                <h3>{i18n.t(`views.elearnings.card-line.title.${type}`)}</h3>
            </div>
            <div ref={ref} className="card-line-container">
                <FontAwesomeIcon className="line-navigation left" icon={faChevronCircleLeft} color="#D8D8D8" onClick={() => scroll(-400)} />
                {
                        exercices?.map((item,idx)=>{
                            return <Card key={idx} data={item} setSelectedCard={setSelectedCard} />
                        })
                }
                <FontAwesomeIcon className="line-navigation right" icon={faChevronCircleRight} color="#D8D8D8" onClick={() => scroll(+400)} />
            </div>
        </div>
    )
}

import React from "react"
import { useNavigate } from "react-router-dom"


export default function OpenInvoice({row, children}){

  const navigate = useNavigate()

  return(
      <span onClick={() => {navigate(`/invoices?i=${row?.id}&pm=s`)}}>
          {children}
      </span>
  )
}

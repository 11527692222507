import React, { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector, ReactReduxContext } from "react-redux";
import { fetchInvoices } from "./invoicesSlice";
import { setFiltersInvoice } from "../filters/filtersSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DataTable from '../toolbox/datatable';
import Columns from './columns/columns';
import Panel from '../toolbox/panel';
import Invoice from './invoice';
import Header from './components/header';
//column rendering depending of role / sided

const Invoices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const path = location.pathname
  const filters = useSelector(state => state.filters.filtersInvoice);
  const panelState = new URLSearchParams(search).get('pm');
  const user = useSelector(state => state.user.userData);
  const { invoicesList, loading } = useSelector((state) => state.invoices)
  const [currentRow, setCurrentRow] = useState(null);
  const [columns, setColumns] = useState(Columns);
  const [page, setPage] = useState(1);
  const ref = useRef(null);
  const { store } = useContext(ReactReduxContext)
  const openNavbar = useSelector((state) => state.navigations.openNavbar)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
  const responsiveOne = useSelector((state)=>state.navigations.responsiveOne)



  useEffect(() => {
    const state = store.getState();
    const filters = state.filters.filtersInvoice
    setPage(1)
    dispatch(fetchInvoices({ page: 1, filters }))
  }, [filters]);

  useEffect(() => {
    (currentRow) &&
      navigate(`/invoices?i=${currentRow?.id}&pm=s`)
  }, [currentRow]);

  const expand = () => {
    navigate(`/invoices/${currentRow?.id}`)
  }

  useEffect(() => {
    const state = store.getState();
    const filters = state.filters.filtersInvoice
    if (page && !loading) {
      dispatch(fetchInvoices({ page, filters }))
    }
  }, [page]);


  return (


    <div className={`main-content`}>

      <div className={`datatable page-container ${(openNavbar||responsiveFilter | (responsiveOne && panelState=='s')) && 'responsive-hidden'} ${panelState == 's' && 'sided'}`} >


        <Header />
        <DataTable
          columns={columns}
          data={invoicesList}
          onRowClicked={(row) =>
            setCurrentRow(row)
          }
          setPassedPage={setPage}
          passedPage={page}
          loading={loading}
          ref={ref}
        />

      </div>
      <Panel state={panelState} unselect={setCurrentRow} expand={expand}>
        <Invoice />
      </Panel>
    </div>
  )

};

export default Invoices;



import React from "react";
import Status from "./status";
import i18n from "../../../i18n"


export default function CardListItem({event,item,onRowClicked, rootPage}){


  return(
    <div className={`item-container ${item?.state}`}  onClick={()=>{
      onRowClicked(item)

      }} >
      <div className="header">
      <span className="teacher"> {item?.student_name} </span>
      <Status status={item.state} id={item.id} />

      </div>
      <div className="date-card-list">
      <span className="date-card-list-string"> {item?.date} </span>
      <span className="date-card-list-string-hour"> {item?.hour}</span>

      </div>
      <div className="level-area">
      <span className="level-type">{item?.teacher_name}</span>
      <span className="level">{item?.subject}</span>

      </div>



    </div>
  )
}

import React, { useRef } from 'react'
import i18n from '../../../i18n'
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { setFiltersAvailabilities, setFiltersTeachers, setFiltersTickets } from '../filtersSlice';


export default function ByLanguageTaught({ model, action, loading }){

  const dispatch = useDispatch()
  const current = useSelector(state => state.filters[model].levels)
  const responsiveFilter = useSelector((state) => state.navigations.openResponsiveFilters)
  const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
  const ref = useRef(null)

  const options = [
    { value: 'french', label:`${i18n.t('reasons.languages.french')}` },
    { value: 'english', label: `${i18n.t('reasons.languages.english')}` },
    { value: 'german', label: `${i18n.t('reasons.languages.german')}` },
    { value: 'spanish', label: `${i18n.t('reasons.languages.spanish')}` },
    { value: 'italian', label: `${i18n.t('reasons.languages.italian')}` },
  ]

  const dispatcher = {
		"setFiltersTeachers": (value) => dispatch(setFiltersTeachers({teaching_languages: value})),
		"setFiltersAvailabilities": (value) => dispatch(setFiltersAvailabilities({teaching_languages: value}))




  }

  const onSelectChange = (value) => {
    // if (responsiveFilter) {
    //   dispatch(setResponsiveFiltersArray({ action, value, type: 'language' }))

    // }
    // else {
      dispatcher[action](value)
    //}
  }


   if(isCleaned){
     ref.current?.select.clearValue();

   }

  return (
    <div className='filter by_level'>
      <div className="line">
        <label htmlFor="filter_type" id="level" className="s_label">
        {i18n.t('filters.by_language_taught')}

          </label>
        <Select
          ref={ref}
          cacheOptions
          onChange ={ (value)=>{ onSelectChange(value)} }
           isLoading={loading}
           isDisabled={loading}
          isMulti
          options={options}
          defaultValue={  current }
          placeholder={`${i18n.t('helpers.select.prompt')}...`}
        />
      </div>
    </div>
  )

}

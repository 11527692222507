
import { createSlice, createSelector } from '@reduxjs/toolkit'

const navigationSlice = createSlice({
  name: 'navigations',
  initialState: {
    openNavbar: false,
    responsiveOne:false, //for the change navigation when screen is chnged
    openResponsiveFilters:false,
    responsiveFiltersArray:[],

  },
  reducers: {
    setOpenNavbar: (state) => {
      state.openNavbar = !state?.openNavbar
    },

    setResponsiveOne: (state, action) => {
      state.responsiveOne = action?.payload
    },

    setOpenResponsiveFilters: (state, action) => {
      state.openResponsiveFilters = action?.payload
    },
    setResponsiveFiltersArray:(state,action)=>{
      const index = state.responsiveFiltersArray.findIndex(
        (item) => item.type === action.payload.type && item.model === action.payload.model
      );

      if (index !== -1) {
        state.responsiveFiltersArray[index] = action.payload;
      } else {
        state.responsiveFiltersArray.push(action.payload);
      }
    },
    resetResponsiveFiltersArray: (state, action) => {
      const filteredOne= state.responsiveFiltersArray.filter(item => item.model !== action.payload);

      state.responsiveFiltersArray = filteredOne

    },

  },

})

export const navigation = createSelector(
  (state) => ({
    openNavbar: state.openNavbar,
    responsiveOne: state.responsiveOne,
    openResponsiveFilters: state.openResponsiveFilters,
    responsiveFiltersArray:state.responsiveFiltersArray,
    responsivePanel: state.responsivePanel
  }),
  (state) => state.navigations
)

export const { setOpenNavbar, setResponsiveOne, setOpenResponsiveFilters, setResponsiveFiltersArray,resetResponsiveFiltersArray} = navigationSlice.actions
export default navigationSlice.reducer

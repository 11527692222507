import React, {forwardRef, useState, useImperativeHandle} from "react";
import { Modal } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const WrapModal = forwardRef(function WrapModal({children, large, closeAdditional}, ref) {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const basePath = location.pathname;
    const responsiveOne = useSelector((state) => state.navigations.responsiveOne)


    useImperativeHandle(ref, () => ({
        onShow() {
            setOpen(true)
        },
        onHide() {
            setOpen(false)
            closeAdditional && closeAdditional()
        },
        open
      }));


    const close = () => {
        const panelState = new URLSearchParams(location.search).get('pm');
        if (panelState !== "s") {
            navigate(basePath)
        }
        setOpen(false)
        closeAdditional && closeAdditional()
    }

    return (
        <Modal id="calendarModal" show={open} onHide={close} className={large && 'large' || responsiveOne && 'sided-modal'}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    )

});

export default WrapModal;

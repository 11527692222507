import React, { useRef, useState, useEffect } from "react"
import { useSelector } from "react-redux"
import i18n from '../../../i18n'
import openArrow from "../../images/icons/openArrow.svg";
import closeArrow from "../../images/icons/closeArrow.svg";
import ReactHtmlParser from 'react-html-parser';

export default function InactifTeacher() {

  const teacher = useSelector((state) => state.teachers.teacherShow)
  const [showReasons, setShowReasons] = useState(false)
  const ref = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowReasons(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
     teacher?.active_status === i18n.t('activerecord.attributes.teacher.active_status.inactive') && (
      <div className="teacher-inactive-alert" style={{ fontFamily: "Montserrat", marginRight: '10px' }}>
          <div>
            {ReactHtmlParser(i18n.t('activerecord.attributes.teacher.inactive_warning'))}
            <img
              style={{ cursor: 'auto'}}
              className="icon"
              onClick={() => setShowReasons(!showReasons)}
              src={showReasons ? closeArrow : openArrow}
            />
          </div>


        {showReasons && (
          <div ref={ref} style={{ position: 'absolute', width: '20%', background: 'white', border: '1px solid #b0b0b0', borderRadius: '5px', padding: '10px', zIndex:'10' }}>
            {teacher?.inactive_reason?.map((reason, index) => (
              <div key={index}>
                <span>{reason}</span>
                <br />
              </div>
            ))}
          </div>
        )}
      </div>
    )
  );

}

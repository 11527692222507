import React from "react";
import Status from "../components/status";
import i18n from '../../../i18n'
import ReviewLink from "../components/reviewLink";


const columns = [
  {
    name: "id",
    selector: row => row.id,
    omit: true
  },
  {
    name: `${i18n.t('views.lessons.date')}`,
    selector: row => row.date
  },
  {
    name: `${i18n.t('views.lessons.hours')}`,
    selector: row => row.hour,
    omit: true
  },
  {
    name: `${i18n.t('views.lessons.review.theme')}`,
    selector: row => row.theme
  },
  {
    name: `${i18n.t('views.lessons.theme')}`,
    selector: row => row.subject,
    omit: true
  },
  {
    name: `${i18n.t('views.lessons.status')}`,
    cell: (row, index, column, id) => <Status status={row.state} id={row.id} />,
    ignoreRowClick: true,
  },
  {
    name: `${i18n.t('views.lessons.review.state')}`,
    cell: (row,) => (
      <ReviewLink lesson={row} />
    ),
    ignoreRowClick: true,
  }

];

export default columns

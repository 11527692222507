import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

// Async thunk for fetching tickets list
export const fetchTickets = createAsyncThunk(
  'user/fetchTickets',
  async ({ page, filters }) => {
    try {
      const response = await axios.get(`/api/v2/tickets?filters=${JSON.stringify(filters)}&page=${page}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)

export const fetchAtas = createAsyncThunk(
  'user/fetchAtas',
  async ({ page, filters }) => {
    try {
      const response = await axios.get(`/api/v2/admin/tickets?filters=${JSON.stringify(filters)}&page=${page}&per_page=20`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)

export const handleTicket = createAsyncThunk(
  'user/handleTicket',
  async ({ id, state, note }) => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
    const body = { state, note }

    try {
      const response = await axios.put(`/api/v1/tickets/${id}/handle`, body, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)


const ticketsSlice = createSlice({
  name: 'tickets',
  initialState: {
    loading: false,
    currentRequestId: "",
    ticketsList: [],
    error: null,
    atasList: []
  },
  reducers: {
    setTicketsList: (state, action) => {
      state.ticketsList = [...state.ticketsList, ...action.payload]
    },
    resetTickets: (state, action) => {
      state.ticketsList = []
    },
    resetAtas: (state, action) => {
      state.atasList = []
    },
    setAtasList: (state, action) => {
      state.atasList = [...state.atasList, ...action.payload]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTickets.pending, (state, action) => {
      if (!state.loading) {
        state.loading = true
        state.currentRequestId = action.meta.requestId
      }
    }),
      builder.addCase(fetchTickets.fulfilled, (state, action) => {
        const { requestId } = action.meta
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false
          state.ticketsList = [...state.ticketsList, ...action.payload]
          state.currentRequestId = undefined
        }
      }),
      builder.addCase(fetchTickets.rejected, (state, action) => {
        const { requestId } = action.meta
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false
          state.error = action.error
          state.currentRequestId = undefined
        }
      }), builder.addCase(fetchAtas.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true
          state.currentRequestId = action.meta.requestId
        }
      }),
      builder.addCase(fetchAtas.fulfilled, (state, action) => {
        const { requestId } = action.meta
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false
          state.atasList = [...state.atasList, ...action.payload]
          state.currentRequestId = undefined
        }
      }),
      builder.addCase(fetchAtas.rejected, (state, action) => {
        const { requestId } = action.meta
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false
          state.error = action.error
          state.currentRequestId = undefined
        }
      }),
      builder.addCase(handleTicket.fulfilled, (state, action) => {
        
        if (action.payload.state == "canceled") {
          let oldList = state.ticketsList?.filter(e => {
            return e.id !== action.payload.id
          })
          state.ticketsList = oldList
        } else {
          let oldList = state.ticketsList?.filter(e => {
            return e.id === action.payload.id
          })
  
          const index = state.ticketsList.indexOf(oldList[0])
          let copiedState = state.ticketsList.slice(0);
          copiedState[index] = action.payload
          state.ticketsList = copiedState;
        } 
      })
  }
})

export const ticketsShow = createSelector(
  (state) => ({
    ticketsList: state.ticketsList,
    atasList: state.atasList
  }),
  (state) => state.ticketsList
)

export const { setTicketsList, resetTickets, setAtasList, resetAtas } = ticketsSlice.actions
export default ticketsSlice.reducer

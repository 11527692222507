import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Modal from '../../toolbox/modal'
import { useLocation, useNavigate } from "react-router-dom";
import StateManagement from "../../lessons/components/state_management";
import { useSelector } from "react-redux";
import TrainingManagement from "./trainingManagement";



const Issue = ({ id, has_issue, note }) => {


  const location = useLocation();
  const navigate = useNavigate()
  const modalState = new URLSearchParams(search).get('pm');
  const search = location.search;
  const user = useSelector((state) => state.user.userData)


  const ref = useRef()

  const overlay = (
    <Popover id="popover-basic">
       <Popover.Content style={{ padding: '10px' }}>
      {note}
    </Popover.Content>
    </Popover>
  )

  useEffect(() => {
    if (modalState == 'm') {
      ref.current?.onShow()
    } else {
      ref.current?.onHide()
    }
  }, [modalState]);




  if (has_issue) {
    if(user?.role==='HumanResource' || user?.role==='Teacher' || user?.role==='Admin' ){
      return(
        <OverlayTrigger
        key={id}
        trigger="hover"
        placement="bottom"
        containerPadding={20}
        rootClose
        overlay={
          overlay
        }
      >
        <div
          onClick={() => {
            if (user?.role === 'Teacher') {
              navigate(`?te=${id}&pm=m&state=training_alert_end`);
            }
          }}
          className={`general-icon small-medium issue`} style={{ cursor: user?.role === 'HumanResource'  || user?.role ==='Admin' ? 'not-allowed' : '' }}
        >
          <Modal ref={ref}>
            <TrainingManagement />
          </Modal>
        </div>

      </OverlayTrigger>
      )
    }

  }
  else{
    return (
      <div onClick={() => {
        user?.role === 'Teacher' &&
          navigate(`?t=${id}&pm=m&state=training_alert`)
      }

      }  style={{ cursor: user?.role === 'HumanResource'  || user?.role ==='Admin' ? 'not-allowed' : '' }} className={`general-icon small-medium no-issue`}>
        <Modal ref={ref}>
          <TrainingManagement />
        </Modal>
      </div>
    )
  }



};

export default Issue;

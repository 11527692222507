import React, { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector, ReactReduxContext } from "react-redux";
import { fetchTeachers, resetTeachers } from "./teachersSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DataTable from '../toolbox/datatable';
import Columns from './columns/columns';
import SidedColumns from './columns/sided';
import Panel from '../toolbox/panel';
import Teacher from './teacher';
import Header from './components/header';
import ListHeader from "./components/list_header";
import { resetTrainings } from "../trainings/trainingsSlice";
import CardList from "../toolbox/cardList";
import TeacherCardItem from "./components/teacherCardItem";
//column rendering depending of role / sided

const Teachers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const path = location.pathname
  const filters = useSelector(state => state.filters.filtersTeachers);
  const panelState = new URLSearchParams(search).get('pm');
  const user = useSelector(state => state.user.userData);
  const { teachersList, loading } = useSelector((state) => state.teachers)
  const [currentRow, setCurrentRow] = useState(null);
  const [columns, setColumns] = useState(Columns);
  const [page, setPage] = useState(1);
  const ref = useRef(null);
  const { store } = useContext(ReactReduxContext)
  const openNavbar = useSelector((state) => state.navigations.openNavbar)
  const responsiveFilter = useSelector((state) => state.navigations.openResponsiveFilters)
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)



  useEffect(() => {
    const state = store.getState();
    const filters = state.filters.filtersTeachers
    setPage(1)
    dispatch(resetTeachers())
    dispatch(fetchTeachers({ page: 1, filters }))
  }, [filters]);

  useEffect(() => {
    if (panelState == 's') {
      setColumns(SidedColumns)
    } else {
      setColumns(Columns)
    }
  }, [panelState]);

  useEffect(() => {
    (currentRow) &&
      navigate(`/teachers?t=${currentRow?.id}&pm=s`)
  }, [currentRow]);

  const expand = () => {
    navigate(`/teachers/${currentRow?.id}`)
  }

  useEffect(() => {
    const state = store.getState();
    const filters = state.filters.filtersTeachers
    if (page && !loading) {
      dispatch(fetchTeachers({ page, filters }))
    }
  }, [page]);



  return (
    <div className={`main-content teachers`}>

      <div className={`datatable page-container ${(openNavbar || responsiveFilter) && 'responsive-hidden'} ${panelState == 's' && 'sided'}`} >


        <ListHeader />

        {
          !responsiveOne ?

            <DataTable
              columns={columns}
              data={teachersList}
              //onRowClicked={(row) => setCurrentRow(row)}
              setPassedPage={setPage}
              passedPage={page}
              loading={loading}
              ref={ref}
              filters={filters}
            />
            : <CardList

              data={teachersList}
              // onRowClicked={(row) => setCurrentRow(row)}
              setPassedPage={setPage}
              passedPage={page}
              loading={loading}
              // futurePage={futurePage}
              // setFuturePage={setFuturePage}
              ref={ref}
              rootPage='teacher'
              card={<TeacherCardItem />}

            />

        }


      </div>
      <Panel state={panelState} unselect={setCurrentRow} expand={expand}>
        <Teacher />
      </Panel>
    </div>
  )

};

export default Teachers;

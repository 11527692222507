
import React from "react"
import i18n from '../../../../i18n'
import { useSelector } from "react-redux"


export default function StepOne({ data }) {

  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)


  const renderVideo = (link) => {
    if (link?.includes("loom")) {
      return <iframe width={560} height={310} src={link} frameborder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen></iframe>
    } else {
      return <iframe width={560} height={310} src={link} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    }
  }

  return (

    <div className={`detail-box ${responsiveOne && "responsive"} `}>
      <div className={`informations ${responsiveOne && "responsive"} `}>
        <div className="title">{i18n.t('views.trainings.chart.intro_video')}</div>

        <h3>{i18n.t('views.trainings.chart.intro')}
          </h3>
        {renderVideo(data?.onboarding_video)}
      </div>
    </div>

  )
}

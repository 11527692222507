import { createSlice, createSelector } from '@reduxjs/toolkit'
import { nanoid } from '@reduxjs/toolkit';

const filtersSlice = createSlice({
  name: 'filters',
  initialState: {
    filtersLesson: {},
    filtersTeacher: {},
    filtersTraining: {},
    filtersELearning:{},
    filtersCoursCollectives:{},
    filtersConferences: {},
    filtersTickets: {},
    filtersAvailabilities: {},
    filtersTeachers: {},
    currentRequestId: "",
    responsiveFilterCleanBtn:false
    // filtersTeacher ...
  },
  reducers: {
    setFiltersLesson: (state, action) => {
      state.filtersLesson = { ...state.filtersLesson, ...action.payload }
      state.currentRequestId = nanoid()
    },
    resetFiltersLesson: (state) => {
      const { types } = state.filtersLesson;
      state.filtersLesson = { types };
      state.currentRequestId = nanoid();
    },
    setFiltersTraining: (state, action) => {
      state.filtersTraining = { ...state.filtersTraining, ...action.payload }
      state.currentRequestId = nanoid()
    },
    resetFiltersTraining: (state) => {
      state.filtersTraining = {};
      state.currentRequestId = nanoid();
    },
     setFiltersELearning: (state, action) => {
        state.filtersELearning = {...state.filtersELearning, ...action.payload}
        state.currentRequestId = nanoid()
    },
    resetFiltersElearning: (state) => {
      state.filtersELearning = {};
      state.currentRequestId = nanoid();
    },
    setFiltersConferences: (state, action) => {
      state.filtersConferences = { ...state.filtersConferences, ...action.payload }
      state.currentRequestId = nanoid()
  },
  resetFiltersConferences: (state) => {
    state.filtersConferences = {};
    state.currentRequestId = nanoid();
  },
  setFiltersCoursCollectives: (state, action) => {
    state.filtersCoursCollectives = {...state.filtersCoursCollectives, ...action.payload}
    state.currentRequestId = nanoid()
  },
  resetFiltersCoursCollectives: (state) => {
    state.filtersCoursCollectives = {};
    state.currentRequestId = nanoid();
  },
  setFiltersTickets: (state, action) => {
      state.filtersTickets = { ...state.filtersTickets, ...action.payload }
      state.currentRequestId = nanoid()
    },
  resetFiltersTickets: (state) => {

    const { type } = state.filtersTickets;
    state.filtersTickets = { type };
    state.currentRequestId = nanoid();
  },
  setFiltersAvailabilities: (state, action) => {
      state.filtersAvailabilities = { ...state.filtersAvailabilities, ...action.payload }
      state.currentRequestId = nanoid()
    },
  setFiltersTeachers: (state, action) => {
    state.filtersTeachers = { ...state.filtersTeachers, ...action.payload }
    state.currentRequestId = nanoid()
  },
  resetFiltersTeachers: (state) => {
    state.filtersTeachers = {};
    state.currentRequestId = nanoid();
  },
  resetFilters: (state) => {
    state.currentRequestId = nanoid();
    const { types } = state.filtersLesson;
    const { type } = state.filtersTickets;
    state.filtersLesson = { types };
    state.filtersTeacher = {};
    state.filtersTraining = {};
    state.filtersELearning = {};
    state.filtersCoursCollectives = {};
    state.filtersConferences = {};
    state.filtersTickets = { type };
    state.filtersAvailabilities = {};
    state.filtersTeachers = {};
  },
  setResponsiveFilterCleanBtn:(state,action)=>{
    state.responsiveFilterCleanBtn = action.payload
  }
  }
})

export const filters = createSelector(
  (state) => ({
    filtersLesson: state.filtersLesson,
    filtersTraining: state.filtersTraining,
    filtersCoursCollectives: state.filtersCoursCollectives,
    filtersELearning: state.filtersELearning,
    filtersConferences: state.filtersConferences,
    filtersAvailabilities: state.filtersAvailabilities,
    filtersTeachers: state.filtersTeachers
  }),
  (state) => state
)

export const { setFiltersLesson, setFiltersTraining, setFiltersCoursCollectives,
   setFiltersELearning, setFiltersConferences, setFiltersTickets, setFiltersAvailabilities,
    setFiltersTeachers,resetFiltersLesson,setResponsiveFilterCleanBtn,resetFiltersElearning,resetFiltersTraining,resetFiltersCoursCollectives,resetFiltersConferences,resetFiltersTickets,resetFiltersTeachers, resetFilters } = filtersSlice.actions
export default filtersSlice.reducer

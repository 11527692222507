import React, { useRef, useState } from "react"
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import i18n from '../../../i18n'
import { faPhoneAlt, faMale, faDownload, faExternalLinkAlt, faChevronUp, faChevronDown, faUserFriends, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "react-bootstrap";

const Summary = ({data, type}) => {

    const [openSummary, setOpenSummary] = useState(false)

    const toggleSummary = () => {
        setOpenSummary(!openSummary)
      }


  const renderSummaryRows = (type) => {
    if (type == "kickoff") {
      return (
        <tbody>
          <tr>
            <td className="strong">{i18n.t('views.trainings.chart.cv')}
             </td>
            <td>{data?.cv}</td>
          </tr>
          <tr>
            <td className="strong">{i18n.t("views.trainings.chart.field_history")}

              </td>
            <td>{data?.field_history}</td>
          </tr>
          <tr>
            <td className="strong">{i18n.t("views.trainings.chart.field_ambition")}
             </td>
            <td>{data?.field_ambitions}</td>
          </tr>
          <tr>
            <td className="strong">{i18n.t("views.trainings.chart.base_level")}
              </td>
            <td>{data?.base_level}</td>
          </tr>
        </tbody>
      )
    } else if (type == "midterm") {
      return (
        <tbody>
          <tr>
            <td className="strong">{i18n.t("views.lessons.review.teacher_note")}</td>
            <td>{data?.bpt_resume}</td>
          </tr>
        </tbody>
      )
    } else if (type == "final") {
      return (
        <tbody>
          <tr>
            <td className="strong">{i18n.t("views.lessons.review.teacher_note")}</td>
            <td>{data?.bff_resume}</td>
          </tr>
        </tbody>
      )
    }
  }

    if (data) {
        return (
          <>
            <div className="toggle-title" aria-expanded={openSummary} onClick={() => { toggleSummary() }}>
              <p>{i18n.t('views.trainings.chart.feedback')}</p>
              <FontAwesomeIcon size={"2x"} color="#FFC400" className="icon-details" icon={openSummary ? faChevronUp : faChevronDown} />
            </div>
            <Collapse in={openSummary}>
              <table className="training-table">
                {renderSummaryRows(type)}
              </table>
            </Collapse>
          </>
        )
      }
    
};

export default Summary
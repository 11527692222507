import React, { useEffect, useRef, useState } from "react"
import DataTable from "../../toolbox/datatable"
import Columns from '../columns/documentColumns';
import { useDispatch, useSelector } from "react-redux";
import { dlDocuments, getDocuments } from "../trainingsSlice";
import { Button, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export default function Document({ training, type }) {

  const [page, setPage] = useState(1);
  const { loading } = useSelector((state) => state.trainings)
  const ref = useRef(null);
  const dispatch = useDispatch()
  const documents = useSelector(state => state.trainings.documents)
  const [hiddenLoading, setHiddenLoading] = useState(true)
  const user = useSelector(state => state.user.userData)
  const search = useLocation().search;
  const trainingId = new URLSearchParams(search).get('t') || location.pathname.split("/")[2]




  useEffect(() => {
    dispatch(getDocuments({ training_id: training?.id }))
  }, [training])


  const columns = Columns();

  const syncDocuments = () => {
    setHiddenLoading(false )

    dispatch(dlDocuments({ training_id: trainingId })).then(() => {
      setHiddenLoading(true)

    })

  }

  const renderSyncBtn = () => {
    if (user.role == "Admin") {
      return (
        <OverlayTrigger trigger={["hover"]}
          overlay={
            <Popover id="popover-basic" className='exercice-actions-popin'>
              <Popover.Content>
                <div>L'application va se synchroniser avec les documents enregistrés dans les notes de la transaction HubSpot.</div>
              </Popover.Content>
            </Popover>
          }
        >
          <Button
          style={{float:'right', marginTop:'5px', marginRight:'5px'}}
            className="dl-doc-btn"
            onClick={syncDocuments}
          >
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              hidden={hiddenLoading}
            />
            &nbsp; Récupérer les documents
          </Button>
        </OverlayTrigger>
      )
    }
  }


  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className={`datatable page-container embeded documents`}>
          <>
            {renderSyncBtn()}
            <div style={{ marginTop: '45px', display: 'flex' }}>
              <DataTable
                columns={columns}
                data={documents}
                onRowClicked={(row) => setCurrentRow(row)}
                setPassedPage={setPage}
                passedPage={page}
                loading={loading}
                ref={ref}
              />
            </div>
          </>

      </div>
    </div>
  );

}

import React from "react";
import Status from "../components/status";
import Issue from '../components/issue';
import i18n from "../../../i18n";
import UpsellButton from "../components/upsellBtn";
import OpenTraining from "../components/openTraining";

const columns = [
    {
        name: "id",
        selector:  row => row.id,
        omit: true
    },
    {
        name: `${i18n.t('views.trainings.alert')}`,
        width:'20%',
        selector: row =>
            <Issue has_issue={row.ticket_issue} note={row.ticket_issue_note}  id={row.id} />

    },
    {
      name: `${i18n.t('views.trainings.chart.upsell')}`,
      selector: row => <UpsellButton trainingId={row.id} ticket_upsell={row?.ticket_upsell} note={row.ticket_upsell_note} />,
      width: "10%",


    },
    {
        name: `${i18n.t('views.trainings.training')}`,
        selector:  row => <OpenTraining row={row}><div className="bold">{row.student}</div></OpenTraining>,
        width: "20%"
    },
    {
      name: `${i18n.t('views.trainings.card.labels.teacher')}`,
      width: "20%",
      selector: row => {
        return (
          <div className="label">{row.teacher} </div>
        )
      }
    },
    {
        name: `${i18n.t('views.trainings.dates')}`,
        selector:  row => {
            return (
                <div className="table-sub-list">
                    <div className="list-item"><div className="label">DEBUT :</div> {row.start_at}</div>
                    <div className="list-item"><div className="label">FIN :</div> {row.end_at}</div>
                </div>
            )
        },
        width: "15%",
    },
    {
        name: `${i18n.t('views.trainings.status')}`,
        selector: row => {
            return (
              <div className={`table-sub-list ${row.student_status}`}>
                  {i18n.t(`activerecord.attributes.student.status.${row.student_status}`)}
              </div>
            )
          },
          width: "15%",
    },
    {   name: "Avancement",
        selector:  row => {
            return (
                <div className="table-sub-list">
                    <div className="list-item"><div className="label">CP : </div>{row.lessons_done}</div>
                    <div className="list-item"><div className="label">CC : </div>{row.cc_lessons_done}</div>
                    <div className="list-item"><div className="label">Elearning : </div>{row.elearning}</div>
                </div>
            )
        },
        omit: true
    },
    {
        name: "Cours",
        selector:  row => {
        return (
            <div>
                <div>Dernier : {row.last_lesson_date}</div>
                <div>prochain : {row.next_lesson_date}</div>
            </div>
            )
        },
        omit: true
    },
    {
        name: "Evaluation",
        selector:  row => {
        return (
            <div>
                <div>Type : {row.test_type}</div>
                <div>Date : {row.planned_test_date}</div>
                <div>Statut : {row.evaluation_status}</div>
            </div>
            )
        },
        omit: true
    },
  ];

  export default columns

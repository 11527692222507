
import React, { useEffect, useState } from "react"
import AssigneButton from "./assigneButton"
import rightArrow from '../../images/icons/right-arrow.svg'
import { useLocation, useNavigate } from "react-router-dom"
import playIcon from '../../images/icons/play-icon.svg'
import emptyArrowIcon from '../../images/icons/white-arrow.svg'
import playBtnYellowNewOne from '../../images/icons/btn-yellow-icon.svg'
import i18n from "../../../i18n";

export default function Card({data,setSelectedCard}){
    const navigate = useNavigate();
    const [thumbnail, setThumbnail] = useState("")

    const onClickArrow = (id) => {
        navigate(`/elearnings_card_view?e=${id}&pm=s`)
      };

    const renderPanelArrow = () => {
        return (
            <img className="icon" onClick={() => {
                onClickArrow(data?.id)
                setSelectedCard(data?.id)
            }} src={rightArrow} />
        )
    }

    useEffect(() => {
        if (data.thumbnail_link){
          const id = data.thumbnail_link.split('/')[5]
          id && setThumbnail(`https://drive.google.com/thumbnail?id=${id}&sz=w1000`)
        }
      }, [data])


    const renderLink = () => {
      return (
          (
              (data?.assignment && (data?.state === 'assign' || data?.state === "")) ||
              data?.assignment === null
          ) && (
              <a target="_blank" href={data?.exercice_link}>
                  <div className="icon-button-elearning">
                      <div className="uncolor-btn">
                          <img className="arrow-one" src={emptyArrowIcon} />
                          <img className="circle" src={playBtnYellowNewOne} />
                      </div>
                  </div>
              </a>
          )
      );
  };


  return(
    <div className="exercice-card">
        <div className={`image ${data.exercice_type.toLowerCase()}`} style={{backgroundImage: `url(${thumbnail}`}}></div>
        <div className="content">
            <div className="titles">
                <h4>{i18n.t(`views.elearnings.card-line.title.${data?.exercice_type}`)}</h4>
                <h3>{data?.name}</h3>
            </div>
            <div className="elearning-actions">
                <div>
                    {renderLink()}
                    <AssigneButton state={data?.state} id={data?.id} assigne={data?.assignment} canUnassign={data?.can_unassign} />
                </div>
                <div>
                    {renderPanelArrow()}
                </div>
            </div>
        </div>
    </div>
  )

}

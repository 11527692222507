import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector, ReactReduxContext } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAvStateForm} from "../../availabilities/availabilitiesSlice";
import { Button } from 'react-bootstrap';
import { getTraining, trainingTicket } from "../trainingsSlice";
import { handleTicket } from "../../tickets/ticketsSlice";
import i18n from '../../../i18n'



const TrainingManagement = () => {
  const dispatch = useDispatch()
  const location = useLocation();
  const navigate = useNavigate();
  const search = location.search;

  const training = new URLSearchParams(search).get('t');
  const trainingEnd = new URLSearchParams(search).get('te');
  const ticketStudent = new URLSearchParams(search).get('ts');
  const ticketStudentValidated = new URLSearchParams(search).get('tv');
  const trainingIdUpsell = new URLSearchParams(search).get('u');
  const trainingIdCancelUpsell = new URLSearchParams(search).get('uc');



  const [note, setNote] = useState(null)
  const [document, setDocument] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const { store } = useContext(ReactReduxContext)
  const state = store.getState()
  const [form, setForm] = useState()
  const basePath = useLocation().pathname;
  const wantedState = new URLSearchParams(search).get('state');

  const user = useSelector(state => state.user.userData);

  const to_state = (wantedState == "booking" && user.role == "Teacher") ? "closing" : wantedState

  const [choice, setChoice] = useState()
  const [noteCreateTicket, setNoteCreateTicket] = useState()
  const [disabledBtn, setDisabledBtn] = useState()
  const [refuseDemandNote, setRefuseDemandNote] = useState()
  const [validateDemandNote, setValidateDemandeNote] = useState()
  const [choiceUpsell, setChoiceUpsell] =useState()
  const [upsellNote, setUpsellNote] = useState()


  const setChoiceTraining = (event) => {
    setChoice(event.target.value)
    if (event?.target?.value?.length > 0 && noteCreateTicket?.length > 0 &&  noteCreateTicket?.trim()?.length > 0) {
      setDisabledBtn(false)

    }
  }

  const setChoiceUp = (event) => {
    setChoiceUpsell(event.target.value)
    if (event?.target?.value?.length > 0 && upsellNote?.length > 0 &&  upsellNote?.trim()?.length > 0) {
      setDisabledBtn(false)
    }
  }

  const setNoteTraining = (event) => {
    setNoteCreateTicket(event.target.value)
    if (event?.target?.value?.length > 0 && choice?.length > 0 && event.target.value?.trim()?.length>0) {
      setDisabledBtn(false)

    }
    else{
      setDisabledBtn(true)

    }

  }

  const handleUpsellNote=(event)=>{

    setUpsellNote(event.target.value)
    if ((event?.target?.value?.length > 0 && choiceUpsell?.length > 0 && event.target.value?.trim()?.length>0)) {
      setDisabledBtn(false)

    }
    else{
      setDisabledBtn(true)

    }

  }


  useEffect(() => {
    if (training) {
      setDisabledBtn(true)
      dispatch(getTraining(training)).then(() => {
        setTimeout(() => {
          const state = store.getState();
          setForm({ form_submit: i18n.t('activerecord.attributes.lesson.state_form.submit.training_alert') });
        }, 100);
      })
    }
  }, [training]);

  useEffect(() => {
    if (trainingEnd) {
      dispatch(getTraining(training)).then(() => {
        setTimeout(() => {
          const form_title = `Are you sure you want to cancel the request`
          const form_submit = i18n.t('confirm')
          setForm({ form_title: form_title, form_submit: form_submit });
        }, 100);
      })
    }
  }, [trainingEnd]);

  useEffect(()=>{

    if(trainingIdUpsell){
     setDisabledBtn(true)

      if (trainingIdUpsell) {
         setForm({ form_submit: i18n.t('activerecord.attributes.lesson.state_form.submit.training_alert_upsell')  });

      }
    }
  },[trainingIdUpsell])


  useEffect(() => {
    if (ticketStudent) {

      const form_title = i18n.t('activerecord.attributes.lesson.state_form.title.ticket-students-refused')
      const form_submit = i18n.t('validate')

      setForm({ form_title: form_title, form_submit: form_submit });

    }
  }, [ticketStudent]);

  useEffect(() => {
    if (ticketStudentValidated) {

      const form_title = `Souhaitez-vous valider cette demande ?`
      const form_submit =  i18n.t('validate')

      setForm({ form_title: form_title, form_submit: form_submit });

    }
  }, [ticketStudentValidated]);

  useEffect(()=>{
    if(trainingIdCancelUpsell){

      dispatch(getTraining(trainingIdCancelUpsell)).then((action) => {

        const trainingData = action.payload;
        setTimeout(() => {
          const form_title = `Vous êtes sur le point d’annuler votre demande concernant la formation de ${trainingData?.student}`
          const form_submit = i18n.t('confirm')
          setForm({ form_title: form_title, form_submit: form_submit });
        }, 100);
      })

    }
  },[trainingIdCancelUpsell])



  useEffect(() => {
    if ((note == null || note == "") && form?.required_note) {
      setDisabled(true)
    } else if (document == null && form?.required_document) {
      setDisabled(true)
    }
    else {
      setDisabled(false)
    }
  }, [form, note, document]);


  const renderTrainingAlert = (training) => {
    if (training) {
      return (
        <div className="lesson-modal">
          {i18n.t('activerecord.attributes.lesson.state_form.title.training_alert') }
          <div id="choices" className="choice-box">
            <div className="line form-radio">
              <input
                className="boolean optional form-radio-input"
                type="radio"
                name="choice"
                id="last_two"
                value="Mon élève est injoignable depuis plus de 2 semaines"
                onChange={(event) => { setChoiceTraining(event) }} />
              <label htmlFor="last_two">{i18n.t(`reasons.${'Mon élève est injoignable depuis plus de 2 semaines'}`) }</label>
            </div>
            <div className="line form-radio">
              <input
                className="boolean optional form-radio-input"
                type="radio"
                name="choice"
                id="late"
                value="Mon élève est en retard dans sa formation"
                onChange={setChoiceTraining} />
              <label htmlFor="late">{i18n.t(`reasons.${'Mon élève est en retard dans sa formation'}`) }</label>
            </div>
            <div className="line form-radio">
              <input
                className="boolean optional form-radio-input"
                type="radio"
                name="choice"
                id="innapropriate"
                value="Mon élève a eu un comportement inapproprié (incivilité, ponctualité, irrespect...)"
                onChange={setChoiceTraining} />
              <label htmlFor="innapropriate">{i18n.t(`reasons.${'Mon élève a eu un comportement inapproprié'}`) }</label>
            </div>
            <div className="line form-radio">
              <input
                className="boolean optional form-radio-input"
                type="radio"
                name="choice"
                id="technical"
                value="Mon élève à un problème technique que j’arrive pas à résoudre (Whereby, paramètres de l’ordinateur, connexion elearning)"
                onChange={setChoiceTraining} />
              <label htmlFor="technical">{i18n.t(`reasons.${'Mon élève à un problème technique que j’arrive pas à résoudre (Whereby, paramètres de l’ordinateur, connexion elearning)'}`) }</label>
            </div>
            <div className="line form-radio">
              <input
                className="boolean optional form-radio-input"
                type="radio"
                name="choice"
                id="noprogram"
                value="Mon élève n’a pas de programme de formation disponible"
                onChange={setChoiceTraining} />
              <label htmlFor="noprogram">{i18n.t(`reasons.${'Mon élève n’a pas de programme de formation disponible'}`) }</label>
            </div>
            <div className="line form-radio">
              <input
                className="boolean optional form-radio-input"
                type="radio"
                name="choice"
                id="manager"
                value="Mon élève n’a pas de manager assigné dans 360 learning"
                onChange={setChoiceTraining} />
              <label htmlFor="manager">{i18n.t(`reasons.${'Mon élève n’a pas de manager assigné dans 360 learning'}`) }</label>
            </div>
            <div className="line form-radio">
              <input
                className="boolean optional form-radio-input"
                type="radio"
                name="choice"
                id="other"
                value="Autre"
                onChange={setChoiceTraining} />
              <label htmlFor="other">{i18n.t(`reasons.${'Autre'}`) }</label>
            </div>

          </div>
          <p style={{ marginBottom: '1px', marginTop: '30px' }}>{i18n.t(`reasons.comment`) }</p>
          <input type="text" name="name" onChange={setNoteTraining} style={{ width: '100%', minHeight: 60, marginBottom: 30, marginTop: 15 }} hint="Soyez le plus précis possible" />

        </div>
      )
    }

  }

  const refuseDemende=(ticketStudent)=>{

    if(ticketStudent){
      return(
        <input  placeholder="Justification..." type="text" name="name" onChange={(event)=>{setRefuseDemandNote(event.target.value)}} style={{ width: '100%', minHeight: 60, marginBottom: 30, marginTop: 15 }} />
      )
    }

  }

  const validateDemande=(ticketStudentValidated)=>{

    if(ticketStudentValidated){
      return(
        <input  placeholder="Justification..." type="text" name="name" onChange={(event)=>{
          setValidateDemandeNote(event.target.value)}} style={{ width: '100%', minHeight: 60, marginBottom: 30, marginTop: 15 }} />
      )
    }

  }


  const trainingUpsell=(trainingIdUpsell)=>{

    if(trainingIdUpsell){
      return (
        <div className="lesson-modal cancel">
          <div id="choices" className="choice-box">
            <div className="line form-radio">
              <input
                className="boolean optional form-radio-input"
                type="radio"
                name="choice"
                id="upsell"
                value="Mon élève a formulé le souhait de reprendre une formation"
                onChange={setChoiceUp}/>
              <label htmlFor="upsell">{i18n.t(`reasons.${'Mon élève a formulé le souhait de reprendre une formation'}`) }</label>
            </div>
            <div className="line form-radio">
              <input
                className="boolean optional form-radio-input"
                type="radio"
                name="choice"
                id="failed"
                value="Mon élève a besoin de poursuivre son apprentissage (objectif non atteint, progression en cours, lacunes identifiées…)"
                onChange={setChoiceUp}/>
              <label htmlFor="failed">{i18n.t(`reasons.${'Mon élève a besoin de poursuivre son apprentissage (objectif non atteint, progression en cours, lacunes identifiées…)'}`) }</label>
            </div>
          </div>
          <p style={{marginBottom: '1px', marginTop: '30px'}}>{i18n.t(`reasons.comment`) }</p>
           <input type="text" name="name" onChange={handleUpsellNote} style={{width: '100%', minHeight: 60, marginBottom: 30, marginTop:15}} hint="Soyez le plus précis possible"/>

        </div>
      )
    }

  }


  const dispatcher = {

    "training_alert": () => dispatch(trainingTicket({training, type:'issue', note:`${choice} : ${noteCreateTicket}`, mode:'show'})),
    "training_alert_end": () => dispatch(trainingTicket({training:trainingEnd, type:'issue', note:'', mode:"canceling"})),
    "ticket-students-refused": ()=>  dispatch(handleTicket({id:ticketStudent, state:'refused', note: refuseDemandNote})),
    "ticket-students-validated": ()=>  dispatch(handleTicket({id:ticketStudentValidated, state:'validated', note: validateDemandNote})),
    "training_upsell": ()=> dispatch(trainingTicket({training:trainingIdUpsell, type:'upsell', note:`${choiceUpsell} : ${upsellNote}`, mode:"show"})),
    "training_upsell_end": () => dispatch(trainingTicket({training:trainingIdCancelUpsell, type:'upsell', note:'', mode:"canceling"}))

  }



  const mapActions = (to_state) => {
    navigate(basePath)
    dispatcher[to_state](to_state)
  }

  const renderSubmit = () => {

      return <Button action="submit" onClick={() => mapActions(to_state)} disabled={disabled || disabledBtn} className={`btn btn-modal
        ${(disabledBtn && training) ? "disabledBtn" : ""}
        ${ trainingIdUpsell ? "upsellBtn" : ""}
      `}>{form?.form_submit}</Button>
  }

  if (form) {
    return (
      <>
        <h3>{form?.form_title}</h3>

        {renderTrainingAlert(training)}
        {refuseDemende(ticketStudent)}
        {validateDemande(ticketStudentValidated)}
        {trainingUpsell(trainingIdUpsell)}

        {renderSubmit()}
      </>
    )
  } else {
    return (
      <p>Loading ...</p>
    )
  }

}

export default TrainingManagement;

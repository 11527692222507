import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
	setFiltersAvailabilities, setFiltersConferences,
	setFiltersTeachers
} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'


const ByIncoming = ({ model, action, loading }) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.filters[model].incoming)
	const [options, setOptions] = useState([])
	const responsiveFilter = useSelector((state) => state.navigations.openResponsiveFilters)
	const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
	const ref = useRef(null)

	const loadPCOptions = () => {
		const url = `/api/v2/filters?filter=conference_incoming`
		const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
			.content.value;
		const promise = fetch(url, { credentials: "same-origin" }).then(r =>
			r.json()
		).then(data => {
			setOptions(data)
		});
	}

	useEffect(() => {
		loadPCOptions()
	}, []);

	const dispatcher = {
		"setFiltersConferences": (value) => dispatch(setFiltersConferences({incoming: value})),
	}

	const onSelectChange = (value) => {
		// if (responsiveFilter) {
		//   dispatch(setResponsiveFiltersArray({ action, value, type: 'level' }))

		// }
		// else {
		dispatcher[action](value)
		//}
	}


	if(isCleaned){
		ref.current?.select.clearValue();

	}


	return (
		<div className='filter by_incoming'>
			<div className="line">
				<label htmlFor="filter_type" id="by_incoming" className="s_label">
					{i18n.t('filters.by_incoming')}

				</label>
				<Select
					ref={ref}
					cacheOptions
					onChange ={ (value)=>{ onSelectChange(value)} }
					isMulti={false}
					options={options}
					defaultValue={  current }
					placeholder={`${i18n.t('helpers.select.prompt')}...`}
					isLoading={loading}
					isDisabled={loading}
				/>
			</div>
		</div>
	)

};

export default ByIncoming;

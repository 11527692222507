import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { OverlayTrigger, Popover } from "react-bootstrap"
import React from "react"
import {
 faCircle
} from '@fortawesome/free-solid-svg-icons';
import i18n from '../../../i18n'

export default function RenderIcon({status}){


    const getColor=()=>{
      switch(i18n.t(`evaluation_status.${status}`)) {
        case i18n.t('evaluation_status.done'):
          return "#1abc9c"
        case i18n.t('evaluation_status.planned'):
          return "#FFC400"
        case i18n.t('evaluation_status.not_done'):
          return "#cc8574"
        case i18n.t('evaluation_status.none'):
          return "grey"
      }
    }

  return(
    <div>
       <OverlayTrigger trigger={["hover"]}
          overlay={
            <Popover id="popover-basic" className='exercice-actions-popin'>
              <Popover.Content>
                <div>{ i18n.t(`evaluation_status.${status}`) }</div>
              </Popover.Content>
            </Popover>
          }
        >
          <FontAwesomeIcon icon={faCircle} color={getColor()} size="lg" pull="left" />
      </OverlayTrigger>
    </div>
  )
}

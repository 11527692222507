import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import attendanceGreen from '../../images/icons/attendanceGreen.svg';
import attendanceRed from '../../images/icons/attendanceRed.svg';

const ReviewLink = ({ lesson }) => {
   const navigate = useNavigate();
   const location = useLocation();

   const handleNavigate = () => {
      if (location?.pathname?.includes('trainings')) {
         return `/lessons/?l=${lesson.id}&pm=s`;
      } else {
         return `?l=${lesson.id}&pm=s`;
      }
   };

   const link = handleNavigate();

   return (
      <div className="table-sub-list reviewlink">
         {lesson?.state === 'passed' ? (
            <img
               onClick={() => {
                  const targetLink = handleNavigate();
                  if (targetLink) {
                    navigate(targetLink)
                    //  window.location.href = targetLink;
                  }
               }}
               src={attendanceRed}
               alt="Passed"
            />
         ) : lesson?.state === 'done' ? (
            <img
               onClick={() => {
                  const targetLink = handleNavigate();
                  if (targetLink) {
                    navigate(targetLink)

                    //  window.location.href = targetLink;
                  }
               }}
               src={attendanceGreen}
               alt="Done"
            />
         ) : (
            <></>
         )}
      </div>
   );
};

export default ReviewLink;


import React from "react"
import { useSelector } from "react-redux"
import i18n from '../../i18n'


export default function NoData() {

  const openNavbar = useSelector((state) => state.navigations.openNavbar)


  return (
    <div className="main-content">
      <div className={`page-container ${openNavbar && 'responsive-hidden'} `} >


        <div className="nodata-container" >
          <span>
          {i18n.t("nodata")}
            </span> </div>


      </div>

    </div>
  )

}

import { scopedCssBaselineClasses } from '@mui/material'
import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'



export const fetchExercises = createAsyncThunk(
  'user/fetchExercises',
  async ({filters={}, lessonId=null, trainingId=null}) => {
    try {
      const response = await axios.get(`/api/v2/exercices?filters=${JSON.stringify(filters)}${lessonId ? `&lesson_id=${lessonId}` : ''}${trainingId ? `&training_id=${trainingId}` : ''}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)

export const fetchExercisesBaseList = createAsyncThunk(
  'user/fetchExercisesBaseList',
  async (filters) => {
    try {
      const response = await axios.get(`/api/v2/exercices/list?filters=${JSON.stringify(filters)}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)

export const fetchOneExercise = createAsyncThunk(
  'user/fetchOneExercise',
  async (id) => {
    try {
      const response = await axios.get(`/api/v2/exercices/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)

export const assigneExercise = createAsyncThunk(
  'user/assigneExercise',
  async ({ exercice_id, student_id, state, training_id, lesson_id }) => {
    try {
      const body = { state, student_id, training_id, lesson_id };
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;

      const response = await axios.patch(
        `/api/v2/exercices/${exercice_id}/assign`,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        }
      );

      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)

export const unAssigneExercise = createAsyncThunk(
  'user/unassigneExercise',
  async ({ assignment_id, lesson_id }) => {
    try {
      let body={
        assignment_id: assignment_id,
        lesson_id: lesson_id
      }
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;

      const response = await axios.patch(
        `/api/v2/exercices/unassign`,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        }
      );

      console.log('response?.data', response?.data)
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)

const elearningSlice = createSlice({
  name: 'elearnings',
  initialState: {
    loading: false,
    currentRequestId: "",
    exercisesList: [],
    exercicesBaseList: [],
    exercise: {},
    changeIconAfterAssigne: false

  },
  reducers: {
    setExercisesList: () => {
      state.exercisesList = [...state.exercisesList, ...action.payload]
    },

    setOneExercise: () => {
      state.exercise = action.payload

    }

  },
  extraReducers: (builder) => {

    builder.addCase(fetchExercises.pending, (state, action) => {
      if (!state.loading) {
        state.loading = true
        state.currentRequestId = action.meta.requestId
      }
    }),
      builder.addCase(fetchExercises.fulfilled, (state, action) => {
        const { requestId } = action.meta
        if (
          state.loading &&
          state.currentRequestId === requestId
        ) {
          state.loading = false
          state.exercisesList = action.payload
          state.currentRequestId = undefined
        }
      }),
      builder.addCase(fetchExercises.rejected, (state, action) => {
        const { requestId } = action.meta
        if (
          state.loading &&
          state.currentRequestId === requestId
        ) {
          state.loading = false
          state.error = action.error
          state.currentRequestId = undefined
        }
      }),
      builder.addCase(fetchExercisesBaseList.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true
          state.currentRequestId = action.meta.requestId
        }
      }),
      builder.addCase(fetchExercisesBaseList.fulfilled, (state, action) => {
        const { requestId } = action.meta
        if (
          state.loading &&
          state.currentRequestId === requestId
        ) {
          state.loading = false
          state.exercicesBaseList = action.payload
          state.currentRequestId = undefined
        }
      }),
      builder.addCase(fetchOneExercise.fulfilled, (state, action) => {
        state.exercise = action.payload
      }),
      builder.addCase(assigneExercise.fulfilled, (state, action) => {

        state.exercise = action.payload

        let exerciceBase = state.exercicesBaseList.filter(e => {
          return e.id === action.payload.id
        })
        const index = state.exercicesBaseList.indexOf(exerciceBase[0])
        let copiedStateCal = state.exercicesBaseList.slice(0);
        copiedStateCal[index] = action.payload
        state.exercicesBaseList = copiedStateCal;

        state.exercisesList?.forEach(e => {
          e?.subcategories?.forEach(t => {
            t.exercices.forEach((e, index) => {
              if (e.id === action.payload.id) {
                t.exercices[index] = action.payload;
              }
            });
          });
        });

      }),
      builder.addCase(unAssigneExercise.fulfilled, (state, action) => {

        state.exercise = action.payload

        let exerciceBase = state.exercicesBaseList.filter(e => {
          return e?.id === action?.payload?.id
        })
        const index = state.exercicesBaseList.indexOf(exerciceBase[0])
        let copiedStateCal = state.exercicesBaseList.slice(0);
        copiedStateCal[index] = action.payload
        state.exercicesBaseList = copiedStateCal;

        state.exercisesList?.forEach(e => {
          e?.subcategories?.forEach(t => {
            t.exercices.forEach((e, index) => {
              if (e.id === action.payload.id) {
                t.exercices[index] = action.payload;
              }
            });
          });
        });

      })
  }

})

export const elearnings = createSelector(
  (state) => ({
    exercisesList: state.exercisesList,
    exercise: state.exercise,
    changeIconAfterAssigne: state.changeIconAfterAssigne
  }),
  (state) => state.elearnings
)

export const { setExercisesList, setOneExercise } = elearningSlice.actions
export default elearningSlice.reducer

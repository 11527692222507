import React, { useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector, ReactReduxContext} from "react-redux"
import { fetchAtas, resetAtas } from "./ticketsSlice"
import DataTable from "../toolbox/datatable";
import atasColumns from "./columns/admin_atas";
import AtasHeader from "./components/atas_header";
import CardList from "../toolbox/cardList";
import AtasListItem from "./components/atasListItem";
import { resetFiltersTickets, setFiltersTickets } from "../filters/filtersSlice";

export default function Atas(){

  const [page, setPage] = useState(1);
  const {atasList, loading} = useSelector((state)=>state.tickets)
  const [currentRow, setCurrentRow] = useState(null);
  const ref = useRef(null);
  const openNavbar = useSelector((state)=>state.navigations.openNavbar)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
  const filters = useSelector(state => state.filters.filtersTickets);
  const { store } = useContext(ReactReduxContext)
  const state = store.getState();
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(resetFiltersTickets())
  },[])

  useEffect(() => {
    const state = store.getState();
    const filters = state.filters.filtersTickets
    setPage(1)
    dispatch(resetAtas())
    dispatch(fetchAtas({ page: 1, filters }))
  }, [filters]);


  useEffect(() => {

    const state = store.getState();
    const filters = state.filters.filtersTickets
    if (page) {
      dispatch(fetchAtas({ page, filters }))
    }
  }, [page, filters]);


  return(
    <div className="main-content">
    <div className= {`datatable page-container ${(openNavbar||responsiveFilter) && 'responsive-hidden'}`} >

        <AtasHeader />

        {
          !responsiveOne ?
          <DataTable
          columns={atasColumns}
          data={atasList}
          setPassedPage={setPage}
          passedPage={page}
          loading={loading}
          ref={ref}
      /> : <CardList

      data={atasList}
      onRowClicked={(row) => setCurrentRow(row)}
      setPassedPage={setPage}
      passedPage={page}
      loading={loading}
      // futurePage={futurePage}
      // setFuturePage={setFuturePage}
      ref={ref}
      rootPage='atas'
      card={<AtasListItem />} />



        }


    </div>

</div>
  )

}

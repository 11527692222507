import React, {useEffect, useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeacher } from "../../teachers/teachersSlice";
import TeacherProfile from "../../teachers/teacher_profile";
import Modal from "../../toolbox/modal";
import { Link, useNavigate } from "react-router-dom";
import { getManager } from "../../managers/managerSlice";
import ManagerProfile from "../../managers/components/managerProfile";

const ListItem = ({label, value, status, link, valueType, teacherId, managerId}) => {
    const dispatch = useDispatch()
    const teacher = useSelector(state => state.teachers.teacherShow)
    const ref = useRef(null);

    useEffect(() => {
        if (teacherId) {
            dispatch(getTeacher(teacherId))
        }
    }, [teacherId]);

    useEffect(() => {
      if (managerId) {
          dispatch(getManager(managerId))
      }
  }, [managerId]);

    const showModal = () => {
        ref.current?.onShow()
    }


    const renderValue = () => {
        if (teacherId) {
            return (
                <>
                    <div className="value green-link" onClick={showModal}>{value}</div>
                    <Modal ref={ref}>
                        <TeacherProfile />
                    </ Modal>
                </>
            )
        }
        if (managerId) {
          return (
              <>
                  <div className="value green-link" onClick={showModal}>{value}</div>
                  <Modal ref={ref}>
                      <ManagerProfile />
                  </ Modal>
              </>
          )
      }
        else if (link) {
            return (
                //  <Link className="green-link" >
                   <a className={ (link && link!=='#') ? "green-link" : 'empty-link'} href={ (link && link!=='#') && link }target="_blank" >
                    <div className="value">{value}</div>

                   </a>
                // </Link>
            )
        }  else {
            return (
                <>
                    <div className="value">{value}</div>
                </>
            )
        }
    }

    const renderLabel = () => {
        if (label) {
           return <div className="label">{label}</div>
        }
    }


    return (
        <div className={`list-item ${status}`}>
            {renderLabel()}
            {renderValue()}
        </div>
    )

};

export default ListItem;

import React, {useEffect, useState, useRef} from "react";
import { useLocation } from "react-router-dom";
import Mapper from "./mapper";
import { useDispatch, useSelector } from "react-redux";
import { setOpenResponsiveFilters } from "../navigation/navigationSlice";
import {resetFilters} from "./filtersSlice";
import ApplyButton from "./component/applyButton";
import i18n from '../../i18n'



const Filters = ({model}) => {
  const search = useLocation().search;
  const panelState = new URLSearchParams(search).get('pm');
  const [open, setOpen] = useState(false);
  const newRef = useRef(null);
  const responsiveOne = useSelector((state)=>state.navigations.responsiveOne)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)

  const dispatch = useDispatch()


    useEffect(() => {
      model == 'TeacherAgenda' &&
      setOpen(true)
    }, [])



    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
          document.removeEventListener("mousedown", handleOutsideClick);
        };
      });

      //for maanging show bottom navbar, have to change openresponsivefilter parameter
    const handleClick = () => {
        setOpen(!open)
        if ( window.screen.width<768 || window.innerWidth<768 ) {
          dispatch(setOpenResponsiveFilters(true))

        }

    }


    const handleOutsideClick = (e) => {
        if (newRef.current && !newRef.current.contains(e.target)) {
          setOpen(!open)
          if ( window.screen.width<768 || window.innerWidth<768 ) {
            dispatch(setOpenResponsiveFilters(false))

          }

        }
    };

    const handleReset = () => {
      dispatch(resetFilters())
      setOpen(!open)
    }



    const renderFilterBox = () => {
        if (open) {
            return (
                <div className={`filterbox ${responsiveFilter && 'responsive-filter' }`} ref={newRef}>
                  <div className="filterbox-header">
                      <h3 style={{display:'flex'}}>{i18n.t('active_admin.sidebars.filters')}</h3>
                      <p className="black-link" onClick={() => {handleReset()}}>{i18n.t('reinitialize')}</p>
                    </div>
                    <Mapper model={model}/>

                   {/* {responsiveFilter && <ApplyButton setOpen={setOpen} open={open} />} */}

                </div>
            )
        }
    }

    return (
        <>
        <div className={`filters`} onClick={handleClick}>
           <div className="action">
                <div className="icon-filters"></div>
                {
                        (panelState !== 's' && !responsiveOne ) &&
                        <span className="btn-title">{i18n.t('active_admin.sidebars.filters')}</span>
                }
            </div>

        </div>
        {renderFilterBox()}
        </>
    )

};

export default Filters;

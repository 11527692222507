import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getInvoice = createAsyncThunk(
    'user/getInvoice',
    async (id) => {
      try {
        const response = await axios.get(`/api/v1/invoices/${id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const updateInvoice = createAsyncThunk(
    'user/updateInvoice',
    async (id) => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
      try {
        const response = await axios.put(`/api/v1/invoices/${id}/send_invoice`, [], {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const fetchInvoices = createAsyncThunk(
    'user/fetchInvoices',
    async ({page, filters}) => {
      try {
        const response = await axios.get(`/api/v1/invoices?filters=${JSON.stringify(filters)}&page=${page}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: {
    loading: false,
    currentRequestId: "",
    invoiceShow: {},
    invoicesList: []
  },
  reducers: {
    setInvoiceShow: () => {
        state.invoiceShow = action.payload
    },
    setInvoicesList: () => {
      state.invoicesList = [...state.invoicesList, ...action.payload]
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getInvoice.fulfilled, (state, action) => {
      state.invoiceShow = action.payload
    }),
    builder.addCase(updateInvoice.fulfilled, (state, action) => {
      state.invoiceShow = action.payload

      let oldList = state.invoicesList.filter(e => {
        return e.id === action.payload.id
      })

      const index = state.invoicesList.indexOf(oldList[0])
      let copiedState = state.invoicesList.slice(0);
      copiedState[index] = action.payload
      state.invoicesList = copiedState;
    }),
    builder.addCase(fetchInvoices.pending, (state, action) => {
      if (!state.loading) {
        state.loading = true
        state.currentRequestId = action.meta.requestId
      }
    }),
    builder.addCase(fetchInvoices.fulfilled, (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading &&
        state.currentRequestId === requestId
      ) {
        state.loading = false
        state.invoicesList = [...state.invoicesList, ...action.payload]
        state.currentRequestId = undefined
      }
    }),
    builder.addCase(fetchInvoices.rejected, (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading &&
        state.currentRequestId === requestId
      ) {
        state.loading = false
        state.error = action.error
        state.currentRequestId = undefined
      }
    })
  }
})

export const invoicesShow = createSelector(
  (state) => ({
    invoiceShow: state.invoiceShow,
    invoicesList: state.invoicesList
  }),
  (state) => state.invoiceShow
)

export const { setInvoiceShow, setInvoicesList } = invoicesSlice.actions
export default invoicesSlice.reducer

import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Filters from "../../filters/filters";
import ActiveFilters from "../../filters/activeFilters";
import i18n from "../../../i18n";

const Header = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const path = location.pathname
	const user = useSelector(state => state.user.userData)

	return (
		<>
			<div className="main-header">
				<div className="main-title">
					<h2>{i18n.t('navigation.teacher.tickets')}</h2>
				</div>
				<div className="main-actions">
					<Filters model={"Tickets"}/>
				</div>
			</div>
			<ActiveFilters model={"filtersTickets"} action={"setFiltersTickets"}  />
		</>
	)

};

export default Header;
import React from "react"
import Issue from "./issue"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClipboardCheck, faClipboard
} from '@fortawesome/free-solid-svg-icons';
import RenderIcon from "./renderIcon";
import UpsellButton from "./upsellBtn";

export default function TrainingListItem({ event, item, onRowClicked,rootPage}) {



  return (
    <div className={`item-container ${rootPage}`} onClick={() => {
      onRowClicked(item)

    }} >
      <div className="header">
        <span className="teacher"> {item?.student} </span>
        <span onClick={(e) => {
          e.stopPropagation()
          }} className={`status ${item?.state}`} style={{right:'48px'}}> {<Issue id={item.id} has_issue={item.ticket_issue} note={item.ticket_issue_note} />} </span>
          <span onClick={(e) => {
          e.stopPropagation()
          }} className="header-upsell" > <UpsellButton trainingId={item?.id} ticket_upsell={item?.ticket_upsell} note={item?.ticket_upsell_note} /> </span>


      </div>
      <div className="date-card-list">

        <span className="date-card-list-string"> {item?.start_at} </span>
        <span className="date-card-list-string-hour"> {item?.end_at}</span>

      </div>
      <div className="level-area">

        <span className={`status-training ${item?.student_status}`}>{item?.student_status}</span>


      </div>

      <div className="icon-area">
        <span className="level-type"> <RenderIcon status={item.evaluation_status} /></span>
        <span className="level" >  {item.review_done ? (
          <a className="list-link" href={item.show_link}>
            <FontAwesomeIcon icon={faClipboardCheck} color="#1abc9c" size="lg" pull="left" />
          </a>
        ) : (
          <a className="list-link" href={item.show_link}>
            <FontAwesomeIcon icon={faClipboard} color="#cc8574" size="lg" pull="left" />
          </a>
        )} </span>


      </div>



    </div>
  )
}

import React from "react"
import { Link, useLocation } from "react-router-dom"
import Filters from "../../filters/filters"
import ActiveFilters from "../../filters/activeFilters"
import CreditCount from "../../lessons/components/credit_count";
import i18n from "../../../i18n";

export default function Header() {
  const location = useLocation();



  return (
    <>
      <div className="main-header">
        <div className="main-title">
          <h2>{i18n.t('activerecord.attributes.collective_lesson.title')}</h2>
          <CreditCount />
        </div>
        <div className="main-actions">

          <div className="actions">
            <div className="list-switch">
              <Link className="switch-el" to={'/collective_lessons'}>
                <div className={`icon lessons ${location?.pathname == '/collective_lessons' ? 'active' : ''}`}></div>
              </Link>
              <Link className="switch-el" to={'/collective_planning'}>
                <div className={`icon planning ${location?.pathname == '/collective_planning' ? 'active' : ''}`}></div>
              </Link>
            </div>
          </div>

          <Filters model={"Collectives"} />
        </div>
      </div>
      <ActiveFilters model={"Lessons"} />
    </>
  )

}


import React, { useRef, useState } from "react"
import { faPhoneAlt, faMale, faDownload, faExternalLinkAlt, faChevronUp, faChevronDown, faUserFriends, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from '../../../../i18n'
import Summary from "../summary";
import { Collapse } from "react-bootstrap";
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from "react-redux";
import TestWidget from "../testWidget";
import TestResult from "../testResult";


export default function StepFour({data}){
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)


  const renderBffDone = () => {
    // 5.1 BFF REALISE
    if (data?.bff_date) {
      return (
        <>
          <h3>{i18n.t('views.trainings.chart.final_overview')}</h3>
          <p><FontAwesomeIcon className="icon-details" icon={faUserFriends} color="#AFAFAF"/> <a target="_blank" href="https://www.cercledeslangues.com/parrainage-eleves">{i18n.t('views.trainings.chart.sponsor')}</a></p>
          <p><FontAwesomeIcon className="icon-details" icon={faRedo} color="#AFAFAF"/><a target="_blank" href={data?.reclosing_link}>
            {i18n.t('views.trainings.chart.upsell')}
            </a>
          </p>
          <p>{i18n.t("views.trainings.chart.passed_overview", {date: data?.bff_date, manager: data?.bff_with})}</p>
          {/* RAJOUTER LE DETAIL ELEARNING */}
          <Summary data={data} type={"final"} />
        </>
      )
    } else {
      // 5.2 BFF NON REALISE
      return (
        <>
          <h3 className="bloc-divider3"> {i18n.t('views.trainings.chart.final_overview')}</h3>
          <p >
            <a href={data?.bff_link} target="_blank">{i18n.t('views.trainings.chart.final_overview_link')}</a>
          </p>
          <p className="bloc-divider">
            <FontAwesomeIcon className="icon-details" icon={faUserFriends} color="#AFAFAF"/> <a target="_blank" href="https://www.cercledeslangues.com/parrainage-eleves">
            {i18n.t('views.trainings.chart.sponsor')}</a>
          </p>
          <p><FontAwesomeIcon className="icon-details" icon={faRedo} color="#AFAFAF"/>
          <a target="_blank" href={data?.reclosing_link}> {i18n.t('views.trainings.chart.upsell')}</a></p>
        </>
      )
    }
  }


  return (
    <div className={`detail-box ${responsiveOne && "responsive"} `}>
    <div className={`informations ${responsiveOne && "responsive"} `}>
      <h3>
        {i18n.t('views.trainings.chart.overview')}
      </h3>
      {data?.cdl_test_done ? (
        <>
          <div className="flex-container">
            <div className="sub-container">
              <h3>
                {i18n.t('views.trainings.chart.final_evaluation')}
              </h3>
              <p>{ ReactHtmlParser (i18n.t("views.trainings.chart.passed_final_test", {date: data?.cdl_test_date}))}</p>
            </div>
            <TestWidget score={data?.cdl_test_lvl} />
          </div>
          <TestResult data={data} isFinal/>
          {/* {renderTestFeedback(data, true)} */}
          {renderBffDone()}
        </>
      ) : (
        <>
          <h3>{i18n.t('views.trainings.chart.final_evaluation')}</h3>
          <p>{i18n.t("views.trainings.chart.final_evaluation_link")} :</p>
          <p className="bloc-divider2">
            <a className="hybrid-link" href='https://form.typeform.com/to/mRzuD8WE?typeform-source=www.google.com' target="_blank">
              {i18n.t('views.trainings.chart.final_evaluation_link_label')}
            </a>
            </p>
          <p className="bloc-divider2">
            {i18n.t('views.trainings.chart.manager_contact')}
          </p>
          {renderBffDone()}
        </>
      )}
    </div>
    </div>
  );

}

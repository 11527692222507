import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Filters from "../../filters/filters";
import ActiveFilters from "../../filters/activeFilters";
import i18n from '../../../i18n'

const Header = ({lessonId, status}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname
    const user = useSelector(state => state.user.userData)
    const hasFilter = !path.includes("/week_type")

    const renderActiveFilters = () => {
        return hasFilter &&
        <ActiveFilters model={"Availabilities"} />
    }

    const renderFilters = () => {
        return hasFilter &&
        <Filters model={"Availabilities"}/>
    }

    return (
        <>
            <div className="main-header">
                <div className="main-title">
                    <h2>{path.includes("/week_type") ? i18n.t('navigation.teacher.week_type') : i18n.t('navigation.teacher.availabilities')}</h2>
                </div>
                <div className="main-actions">
                    <div className="actions">
                    </div>
                    {renderFilters()}
                </div>
            </div>
            {renderActiveFilters()}
        </>
    )
    
};

export default Header;
import React from "react";
import TableCancelAction from "./tableCancelAction";
import TableDemandAction from "./tableDemandAction";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommentTooltip from "../components/commentTooltip";

const recreditColumns = [
  {
    name: "id",
    selector: row => row.id,
    omit: true
  },
  {
    name: "Date",
    selector: row => row.date,
    width:'10%'
  },
  {
    name: "Formation",
    selector: row => row.training,
    width:'10%'
  },

  {
    name: "Professeur",
    selector: row => row.teacher,
    width:'10%'
  },

  {
    name: "Date cours",
    selector: row => {
      return (
        <div className="table-sub-list">
          <div className="list-item">{row?.event_date}</div>
          <div className="list-item"><div className="ref">{row?.event_hours}</div></div>
        </div>
      )
    },
    width:'10%'
  },

  {
    name: "Raison",
    selector: row => row.recredit_reason,
    width:'10%'
  },
  {
    name: "Comm. Prof",
    selector: row =>  <CommentTooltip note={row.note} />,
    width:'10%'
  },
  {
    name: "Comm. Mngr.",
    selector: row => (
      <div style={{ marginLeft: '10px'}}>
        <CommentTooltip note={row.admin_note} />
      </div>

    ),
    width:'10%'
  },

  {
    name: "Statut",
    selector: row => {
      return (
        <div className="table-sub-list">
          <div className="list-item"><div className="label">Stt. initial</div> {row?.previous_state}</div>
          <div className="list-item"><div className="label">Stt. demandé</div><div className="value">{row?.expected}</div></div>
        </div>
      )
    },
    width:'10%'
  },
  {
    name: "Stt. de la demande",
    selector: row => {
      return (
        <TableDemandAction data={row?.state} id={row.id} />
      )
    },
    width:'10%'
  },

  {
    name: "Document",
    selector: row => {
      return (
        <div className="table-sub-list">
          {
            row?.app_document ?
            <div className="list-item" > <a target="_blank" href={row?.app_document}> <FontAwesomeIcon className="icon-details" icon={faDownload} color="#AFAFAF" /> </a></div>
              : ''
          }
        </div>
      )
    },
    width:'10%'
  },

];

export default recreditColumns

import React from "react";
import i18n from "../../../i18n";

const Status = ({status}) => {

        return (
            <div className={`training-status ${status}`}>
                {i18n.t(`activerecord.attributes.student.status.${status}`)}
            </div>
        )

};

export default Status;


import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'




export const getProfile = createAsyncThunk(
  'user/getProfile',
  async () => {
    try {
      const response = await axios.get(`/api/v2/profiles`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)


const studentSlice = createSlice({
  name: 'students',
  initialState: {
    profile:{},

  },
  reducers: {

    setProfile: () => {
      state.profile = action.payload
  },

  },
  extraReducers: (builder) => {

    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profile = action.payload
  })


  }
})

export const students = createSelector(
  (state) => ({
    profile: state.profile,
  }),
  (state) => state.teacherShow
)

export const { setProfile } = studentSlice.actions
export default studentSlice.reducer

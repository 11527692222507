import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit"
import axios from 'axios'

export const getManager = createAsyncThunk(
  'user/getManager',
  async (id) => {
    try {
      const response = await axios.get(`/api/v2/account_managers/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)


const managersSlice = createSlice({
  name: 'managers',
  initialState: {
    managerShow: {},
    currentRequestId: null
  },
  reducers: {
    setManagerShow: () => {
        state.managerShow = action.payload
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getManager.fulfilled, (state, action) => {
        state.managerShow = action.payload
    })
  }

})

export const managerShow = createSelector(
  (state) => ({
    managerShow: state.managerShow,
  }),
  (state) => state.managersShow
)

export const { setManagerShow } = managersSlice.actions
export default managersSlice.reducer

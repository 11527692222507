import React from "react";

const conditionalRowStyles = [
    {
      when: row => new Date(`${row.date?.split("-")[1]}-${row.date?.split("-")[0]}-${row.date?.split("-")[2]} ${row.hour?.split(' - ')[0]}`) < new Date(),
      style: {
        color: '#AFAFAF',
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: 'rgba(0, 0, 0, .12)',
        borderBottomWidth: '0px!important',
        borderBottomColor: 'transparent'
      },
    }
  ];

  export default conditionalRowStyles;

  
import React, { useEffect, useRef, useState, useContext } from "react"
import { useDispatch, useSelector, ReactReduxContext } from "react-redux"
import Select, { components } from "react-select";
import { changeSelectLanguage, getUserProfile } from "../userSlice";
import i18n from '../../../i18n'
import frenchFlag from '../../images/icons/france-flag.png'
import englishFlag from '../../images/icons/uk-flag.png'
import germanFlag from '../../images/icons/german-flag.png'
import spanishFlag from '../../images/icons/spain-flag.png'
import italianFlag from '../../images/icons/italy-flag.png'
import { useLocation, useNavigate } from "react-router-dom";

export default function MultiLanguage() {
  const { store } = useContext(ReactReduxContext)
  const userProfiles = useSelector((state) => state.user.userProfiles)
  const ref = useRef()
  const dispatch = useDispatch()
  const currentProfile = useSelector((state) => state.user.userData.active_profile)
  const [selectedLng, setSelectedLng] = useState()
  const { SingleValue, Option } = components;
  const navigate = useNavigate();
  const [options, setOptions] = useState([])

  useEffect(() => {
    dispatch(getUserProfile()).then((value) => {
      const state = store.getState();
      const user = state.user.userData
      setSelectedLng(value.payload?.filter((item) => item?.id === user.active_profile && { value: item?.id, language: i18n.t(`reasons.languages.long_form.${item?.language}`) }))
    }
    )
  }, [])

  useEffect(() => {
    const options = userProfiles?.filter((item) => item?.id !== selectedLng?.value)?.map((item) => ({ value: item?.id, language: item?.language }))
    setOptions(options)
  }, [selectedLng])


  const flags = [
    { name: 'french', flag: frenchFlag },
    { name: 'english', flag: englishFlag },
    { name: 'german', flag: germanFlag },
    { name: 'spanish', flag: spanishFlag },
    { name: 'italian', flag: italianFlag },
  ]

  const onSelectLangChange = (value) => {
    dispatch(changeSelectLanguage({ id: value?.value })).then((v) => {
      setSelectedLng({ value: v.payload?.id, language: v.payload?.language })
      navigate('/planning')
    })
  }

  const IconSingleValue = (props) => {
    const flagItem = flags?.find(item => item?.name?.includes(props.data.language));

    return (
      <SingleValue {...props}>
        <img src={flagItem?.flag}
          style={{ height: '25px', width: '25px', borderRadius: '50%', marginRight: '10px' }} />
        {i18n.t(`reasons.languages.long_form.${props.data.language}`)}
      </SingleValue>
    )

  }

  const IconOption = (props) => {
    const flagItem = flags?.find(item => item?.name?.includes(props.data.language));
    return (
      <Option {...props}>
        <img
          src={flagItem?.flag}
          style={{ height: '25px', width: '25px', borderRadius: '50%', marginRight: '10px' }}
        />

         {i18n.t(`reasons.languages.long_form.${props?.data?.language}`)}
      </Option>
    );
  };

  const styles = {
    control: (provided) => ({
      ...provided,
      border: 'none',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      paddingLeft: 0,
      '& > div': {
        paddingLeft: 0,
        fontSize:'12px'
      }

    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: state.isFocused ? '#ebe8e8' : 'transparent',
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
      paddingLeft: 0,
      textAlign: 'left',
      marginLeft: 0,
      maxWidth: '100%'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#AFAFAF",
      cursor: 'pointer',
      padding: 0
    })

  }

  return (
    <>
      {
        userProfiles?.length > 1 ? <div style={{ width: '100%' }}>
          <Select
            ref={ref}
            cacheOptions
            isSearchable={false}
            onChange={(value) => { onSelectLangChange(value) }}
            options={options}
            defaultValue={selectedLng}
            components={{ SingleValue: IconSingleValue, Option: IconOption }}
            styles={styles}
          />

        </div> : <div style={{ width: '100%' }}> <div className="role">
          <img src={flags?.find(item => item?.name?.includes(userProfiles[0]?.language))?.flag} style={{ height: '25px', width: '25px', borderRadius: '50%', marginRight: '10px' }} />

          {i18n.t(`reasons.languages.long_form.${userProfiles[0]?.language}`)}
        </div> </div>
      }
    </>

  )
}

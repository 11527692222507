import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {setFiltersLesson, setFiltersAvailabilities} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'

const ByLessonType = ({model, action, loading}) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.filters[model].types)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
  const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
  const ref = useRef()

	const [options, setOptions] = useState([
			{ value: 'personal', label: `${i18n.t('filters.options.individual')}`
    },
			{ value: 'collective', label:`${i18n.t('filters.options.collective')}` },
		])


	//to-do: options will take from backend

	const dispatcher = {
		"setFiltersLesson": (value) => dispatch(setFiltersLesson({types: value})),
		"setFiltersAvailabilities": (value) => dispatch(setFiltersAvailabilities({lesson_type: value})),
	}

	const  onSelectChange = value => {
    // if(responsiveFilter){
    //   dispatch(setResponsiveFiltersArray({action,value,type:'lessonType'}))
    // }
    // else{
      dispatcher[action](value)
    //}
	}

  if(isCleaned){
    ref.current?.select.clearValue();

  }
	return (
		<div className='filter by_lesson_types'>
			<div className="line">
				<label htmlFor="filter_type" id="lesson_types" className="s_label">
        {i18n.t('filters.by_lesson_type')}

         </label>
				<Select
					ref={ref}
					isLoading={loading}
					isDisabled={loading}
					cacheOptions
					onChange={onSelectChange}
					isMulti
					options={options}
					defaultValue={current}
          placeholder={`${i18n.t('helpers.select.prompt')}...`}
				/>
			</div>
		</div>
	)

};

export default ByLessonType;

import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Filters from "../../filters/filters";
import ActiveFilters from "../../filters/activeFilters";

export default function AtasHeader ()  {


	return (
		<>
			<div className="main-header">
				<div className="main-title">
					<h2>Annulations & absences</h2>
				</div>
				<div className="main-actions">
					<Filters model={"Tickets"}/>
				</div>
			</div>
			<ActiveFilters model={"filtersTickets"} action={"setFiltersTickets"}  />
		</>
	)

};


import React from "react"
import i18n from '../../../../i18n'
import { useSelector } from "react-redux"


export default function StepFive({data}){

  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)


  return (
    <div className={`detail-box ${responsiveOne && "responsive"} `}>
      <div className={`informations ${responsiveOne && "responsive"} `}>
        <h3>{i18n.t('views.trainings.chart.end')}</h3>
        <p>
          {i18n.t("views.trainings.chart.closing_explanation")} {data?.end_at}.</p>
        <p>
        {i18n.t("views.trainings.chart.closing_conference_invite")} <a target="_blank" href="https://www.youtube.com/c/CercledesLangues">Youtube</a>.</p>
      </div>
    </div>
  )
}

import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (thunkAPI) => {
    try {
      const response = await axios.get('/api/v1/users', {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)

export const getUserProfile = createAsyncThunk(
  'user/getUserProfile',
  async (id) => {
    try {
      const response = await axios.get(`/api/v2/user_profiles`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)

export const changeSelectLanguage = createAsyncThunk(
  'user/changeSelectLanguage',
  async ({ id }) => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
    try {
      const response = await axios.patch(`/api/v2/user_profiles/${id}`, {}, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: {},
    userProfiles: [],
    currentProfile: {},

  },
  reducers: {
    setUserData: () => {
      state.userData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.userData = action.payload
    }),
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.userProfiles = action.payload
    })
    builder.addCase(changeSelectLanguage.fulfilled, (state, action) => {
      state.currentProfile = action.payload
    })
  },
})

export const userData = createSelector(
  (state) => ({
    userData: state.userData,
    userProfiles: state.userProfiles,
    currentProfile: state.currentProfile
    //isSubscribed: state.isSubscribed,
  }),
  (state) => state.userData
)

export const { setUserData,setCurrentProfile } = userSlice.actions
export default userSlice.reducer

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import ByStudent from "./filters/byStudent";
import ByLevel from './filters/byLevel';
import ByTeacher from "./filters/byTeacher";
import ByType from "./filters/byType";
import ByLessonType from "./filters/byLessonType";
import ByLessonStatus from "./filters/byLessonStatus";
import ByAlertStatus from "./filters/byAlertStatus";
import ByStudentStatus from "./filters/byStudentStatus";
import ByTestType from "./filters/byTestType";
import ByTestStatus from "./filters/byTestStatus";
import ByReviewStatus from "./filters/byReviewStatus";
import ByDemandStatus from "./filters/byDemandStatus";
import ByDemandType from "./filters/byDemandType";
import ByProfessionalCompetences from "./filters/byProfessionalCompetences";
import ByTeacherManager from "./filters/byTeacherManager";
import ByStudentManager from "./filters/byStudentManager";
import ByWantStudent from "./filters/byWantStudent";
import ByNationality from "./filters/byNationality";
import ByTeachingModel from "./filters/byTeachingModels";
import ByTeacherStatus from "./filters/byTeacherStatus";
import ByFrenchLevel from "./filters/byFrenchLevel";
import ByCurrency from "./filters/byCurrency";
import ByTarif from "./filters/byTarif";
import ByFillRate from "./filters/byFillRate";
import ByConferenceType from "./filters/byConferenceType";
import ByDate from './filters/byDate'
import ByContent from './filters/byContent';
import ByCCourseTheme from "./filters/byCCourseTheme";
import ByTheme from "./filters/byTheme";
import ByExerciseStatus from "./filters/byExerciseStatus";
import ByIncoming from "./filters/byIncoming";
import ByHasYoutubeLink from "./filters/byHasYoutubeLink";
import ByUpsell from "./filters/byUpsell";
import ByLanguage from "./filters/byLanguage";
import ByLanguageTaught from "./filters/byLanguageTaught";
import ByWeekType from "./filters/byWeekType";
import ByNonPassedTeacherStatus from "./filters/byNonPassedTeacherStatus";

const Mapper = ({ model }) => {
  const user = useSelector(state => state.user.userData);
  const location = useLocation();
  const path = location.pathname

  if (model == "Lessons") {
    const loading = useSelector((state) => state.lessons.loading)
    return (
      <>
        {user.role != 'Student' && <ByStudent model={"filtersLesson"} action={"setFiltersLesson"} loading={loading} />}
        {user.role != 'Student' && <ByLevel model={"filtersLesson"} action={"setFiltersLesson"} loading={loading} />}
        {user.role == 'Student' && <ByDate model={"filtersLesson"} action={"setFiltersLesson"} loading={loading} />}
        {<ByContent model={"filtersLesson"} action={"setFiltersLesson"} loading={loading} />}
        {user.role == 'Admin' && <ByTeacher model={"filtersLesson"} action={"setFiltersLesson"} loading={loading} />}
        {user.role != 'Student' && <ByLessonType model={"filtersLesson"} action={"setFiltersLesson"} loading={loading} />}
        <ByLessonStatus model={"filtersLesson"} action={"setFiltersLesson"} loading={loading} />
        {user.role == 'Admin' && <ByLanguage model={"filtersLesson"} action={"setFiltersLesson"} loading={loading} />}

      </>
    )
  }

  if (model == "Collectives") {
    const loading = useSelector((state) => state.coursCollectives.loading)
    return (
      <>
        <ByLevel model={"filtersCoursCollectives"} action={"setFiltersCoursCollectives"} loading={loading} />
        <ByTeacher model={"filtersCoursCollectives"} action={"setFiltersCoursCollectives"} loading={loading} />
        <ByType model={"filtersCoursCollectives"} action={"setFiltersCoursCollectives"} loading={loading} />
        <ByCCourseTheme model={"filtersCoursCollectives"} action={"setFiltersCoursCollectives"} loading={loading} />

      </>
    )
  }

  if (model == "Teachers") {
    const loading = useSelector((state) => state.teachers.loading)
    return (
      <>
        <ByTeacher model={"filtersTeachers"} action={"setFiltersTeachers"} loading={loading} />
        <ByWantStudent model={"filtersTeachers"} action={"setFiltersTeachers"} single loading={loading} />
        <ByProfessionalCompetences model={"filtersTeachers"} action={"setFiltersTeachers"} loading={loading} />
        <ByType model={"filtersTeachers"} action={"setFiltersTeachers"} loading={loading} />
        <ByNationality model={"filtersTeachers"} action={"setFiltersTeachers"} loading={loading} />
        <ByTeachingModel model={"filtersTeachers"} action={"setFiltersTeachers"} loading={loading} />
        <ByTeacherStatus model={"filtersTeachers"} action={"setFiltersTeachers"} loading={loading} />
        <ByFrenchLevel model={"filtersTeachers"} action={"setFiltersTeachers"} loading={loading} />
        <ByCurrency model={"filtersTeachers"} action={"setFiltersTeachers"} loading={loading} />
        <ByTarif model={"filtersTeachers"} action={"setFiltersTeachers"} loading={loading} />
        <ByFillRate model={"filtersTeachers"} action={"setFiltersTeachers"} loading={loading} />
        <ByLanguageTaught model={"filtersTeachers"} action={"setFiltersTeachers"} loading={loading} />
      </>
    )
  }

  if (model == "Tickets") {
    const loading = useSelector((state) => state.tickets.loading)
    return (
      <>
        { path == '/tickets/availability' && <ByDate model={"filtersTickets"} action={"setFiltersTickets"} loading={loading} /> }
        { path != '/tickets/availability' && <ByStudent model={"filtersTickets"} action={"setFiltersTickets"} loading={loading} /> }
        { user.role == 'Admin' && <ByTeacher model={"filtersTickets"} action={"setFiltersTickets"} loading={loading} />}
        { user.role == 'Admin' && <ByTeacherManager model={"filtersTickets"} action={"setFiltersTickets"} loading={loading} />}
        { path != '/tickets/availability' &&   user.role == 'Admin' && <ByStudentManager model={"filtersTickets"} action={"setFiltersTickets"} loading={loading} />}
        { path != '/atas' && <ByDemandStatus model={"filtersTickets"} action={"setFiltersTickets"} loading={loading} />}
        { path == '/tickets/students' && <ByDemandType model={"filtersTickets"} action={"setFiltersTickets"} loading={loading} /> }
        { path == '/atas' && <ByLessonStatus model={"filtersTickets"} action={"setFiltersTickets"} loading={loading} />}
        { path == '/tickets/availability' && <ByWeekType model={"filtersTickets"} action={"setFiltersTickets"} loading={loading} /> }
        {user.role == 'Admin' && <ByLanguage model={"filtersTickets"} action={"setFiltersTickets"} loading={loading} />}

      </>
    )
  }

  if (model == "Trainings") {
    const loading = useSelector((state) => state.trainings.loading)
    return (
      <>
        {user?.role === 'HumanResource' && <ByLanguage model={"filtersTraining"} action={"setFiltersTraining"} loading={loading} />}
        <ByStudent model={"filtersTraining"} action={"setFiltersTraining"} loading={loading} />
        <ByLanguage model={"filtersTraining"} action={"setFiltersTraining"} loading={loading} />
        <ByAlertStatus model={"filtersTraining"} action={"setFiltersTraining"} single loading={loading} />
        <ByStudentStatus model={"filtersTraining"} action={"setFiltersTraining"} loading={loading} />
        <ByTestType model={"filtersTraining"} action={"setFiltersTraining"} loading={loading} />
        <ByTestStatus model={"filtersTraining"} action={"setFiltersTraining"} loading={loading} />
        <ByReviewStatus model={"filtersTraining"} action={"setFiltersTraining"} loading={loading} />
        <ByUpsell model={"filtersTraining"} action={"setFiltersTraining"} loading={loading} />
      </>
    )
  }


  if (model == "Conferences") {
    const loading = useSelector((state) => state.conferences.loading)
    return (
      <>
        <ByLevel model={"filtersConferences"} action={"setFiltersConferences"} loading={loading} />
        <ByTeacher model={"filtersConferences"} action={"setFiltersConferences"} loading={loading} />
        <ByConferenceType model={"filtersConferences"} action={"setFiltersConferences"} loading={loading} />
        <ByIncoming model={"filtersConferences"} action={"setFiltersConferences"} loading={loading} />
        <ByContent model={"filtersConferences"} action={"setFiltersConferences"} loading={loading} />

      </>
    )
  }

  if (model == "ELearning") {
    const loading = useSelector((state) => state.elearnings.loading)
    return (
      <>
        <ByLevel model={"filtersELearning"} action={"setFiltersELearning"} loading={loading} />
        <ByContent model={"filtersELearning"} action={"setFiltersELearning"} loading={loading} />
        <ByType model={"filtersELearning"} action={"setFiltersELearning"} loading={loading} />
        <ByTheme model={"filtersELearning"} action={"setFiltersELearning"} loading={loading} />
        <ByExerciseStatus model={"filtersELearning"} action={"setFiltersELearning"} single loading={loading} />
      </>
    )

  }

  if (model == "TeacherAgenda") {
    return (
      <>
        <ByTeacher model={"filtersLesson"} action={"setFiltersLesson"} single />
      </>
    )

  }

  if (model == "Availabilities") {
    const loading = useSelector((state) => state.availabilities.loading)
    return (
      <>
        <ByLanguageTaught model={"filtersAvailabilities"} action={"setFiltersAvailabilities"} loading={loading} />
        <ByTeacher model={"filtersAvailabilities"} action={"setFiltersAvailabilities"} loading={loading} />
        <ByLessonType model={"filtersAvailabilities"} action={"setFiltersAvailabilities"} single loading={loading} />
        <ByType model={"filtersAvailabilities"} action={"setFiltersAvailabilities"} single loading={loading} />
        <ByLevel model={"filtersAvailabilities"} action={"setFiltersAvailabilities"} single loading={loading} />
        <ByProfessionalCompetences model={"filtersAvailabilities"} action={"setFiltersAvailabilities"} loading={loading} />
        <ByNationality model={"filtersAvailabilities"} action={"setFiltersAvailabilities"} loading={loading} />
        <ByTeachingModel model={"filtersAvailabilities"} action={"setFiltersAvailabilities"} loading={loading} />
        <ByFrenchLevel model={"filtersAvailabilities"} action ={"setFiltersAvailabilities"} loading={loading} />
        <ByNonPassedTeacherStatus model={"filtersAvailabilities"} action={"setFiltersAvailabilities"} loading={loading} />
      </>
    )

  }

};

export default Mapper;

import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {setFiltersLesson, setFiltersTraining, setResponsiveFilterCleanBtn} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'

const ByReviewStatus = ({model, action, loading}) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.filters[model].review_status)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
  const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
  const ref = useRef()

	const [options, setOptions] = useState([
		{ value: true, label: `${i18n.t('filters.options.filled')}`
      },
		{ value: false, label: `${i18n.t('filters.options.empty')}`
  },
	])


	//to-do: options will take from backend

	const dispatcher = {
		"setFiltersTraining": (review_status) => dispatch(setFiltersTraining({review_status: review_status})),
	}

	const  onSelectChange = value => {
    // if(responsiveFilter){
    //   dispatch(setResponsiveFiltersArray({action,value,type:'reviewStatus'}))
    // }
    // else{
      dispatcher[action](value)
   //}
	}
  if(isCleaned){
    ref.current?.select.clearValue();

  }

	return (
		<div className='filter by_review_statuses'>
			<div className="line">
				<label htmlFor="filter_type" id="review_statuses" className="s_label">{i18n.t('filters.by_review_status')}</label>
				<Select
        	ref={ref}
					cacheOptions
					onChange={onSelectChange}
					isMulti = { false }
					options={options}
					defaultValue={current}
          placeholder={`${i18n.t('helpers.select.prompt')}...`}
					isLoading={loading}
					isDisabled={loading}
				/>
			</div>
		</div>
	)

};

export default ByReviewStatus;

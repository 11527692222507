import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import {setFiltersLesson, setFiltersTraining, setFiltersConferences, setFiltersTickets, setResponsiveFilterCleanBtn, setFiltersCoursCollectives} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'


const ByStudent = ({model, action, loading}) => {
    const dispatch = useDispatch()
    const current = useSelector(state => state.filters[model].students)
    const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
    const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
    const ref = useRef()

    const loadTeacherOptions = (inputValue, callback) => {
        const url = `/api/v2/filters/get_student_options?input=${inputValue}`
            const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
                .content.value;
            const promise = fetch(url, { credentials: "same-origin" }).then(r =>
                r.json()
            ).then(data => {
                callback(data)
            });
      }

    const dispatcher = {
        "setFiltersLesson": (ids) => dispatch(setFiltersLesson({students: ids})),
        "setFiltersTraining": (ids) => dispatch(setFiltersTraining({students: ids})),
        "setFiltersConferences": (ids) => dispatch(setFiltersConferences({students: ids})),
        "setFiltersTickets": (ids) => dispatch(setFiltersTickets({students: ids})),
        "setFiltersCoursCollectives": (ids) => dispatch(setFiltersCoursCollectives({students: ids})),

    }

    const  onSelectChange = value => {
      // if(responsiveFilter){
      //   dispatch(setResponsiveFiltersArray({action,value,type:'student'}))
      // }
      // else{
        dispatcher[action](value)
      //}
    }
    if(isCleaned){
      ref.current?.select?.select.setValue('');
    }


    return (
        <div className='filter by_student'>
            <div className="line">
              <label htmlFor="filter_type" id="student" className="s_label">
                {i18n.t('filters.by_student')}
                </label>
                    <AsyncSelect
                        ref={ref}
                        cacheOptions
                        onChange={onSelectChange}
                        isMulti
                        defaultValue={current}
                        isLoading={loading}
                        isDisabled={loading}
                        noOptionsMessage={() => `${i18n.t('filters.start_search')}`}
                        loadOptions={loadTeacherOptions}
                        placeholder={`${i18n.t('helpers.select.prompt')}...`}
                    />
            </div>
        </div>
    )

};

export default ByStudent;

import React, { useEffect, useRef, useState } from "react"
import i18n from '../../../i18n'
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { setFiltersELearning } from "../filtersSlice";


export default function ByTheme({ model, action, loading }){

  const dispatch = useDispatch()
  const ref = useRef(null)
  const [options, setOptions] = useState([])
  const current = useSelector(state => state.filters[model].themes)





  const loadLevelOptions = () => {
    const url = `/api/v2/filters?filter=theme`
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
      .content.value;
    const promise = fetch(url, { credentials: "same-origin" }).then(r =>
      r.json()
    ).then(data => {
      setOptions(data)
    });
  }

  useEffect(() => {
    loadLevelOptions()
  }, []);

  const dispatcher = {

    "setFiltersELearning": (value) => dispatch(setFiltersELearning({ themes: value })),

  }

  const onSelectChange = (value) => {
    // if (responsiveFilter) {
    //   dispatch(setResponsiveFiltersArray({ action, value, type: 'level' }))

    // }
    // else {
      dispatcher[action](value)
    //}
  }



  return(
    <div className='filter by_level'>
      <div className="line">
        <label htmlFor="filter_type" id="level" className="s_label">
        {i18n.t('filters.by_theme')}

          </label>
        <Select
          ref={ref}
          cacheOptions
          onChange ={ (value)=>{ onSelectChange(value)} }
          isMulti
          isLoading={loading}
          isDisabled={loading}
          options={options}
          defaultValue={  current }
          placeholder={`${i18n.t('helpers.select.prompt')}...`}
        />
      </div>
    </div>
  )
}

import React, {useEffect, useState, useRef} from "react";
import {OverlayTrigger, Popover} from 'react-bootstrap';
import { useSelector } from "react-redux";
import EventDetail from './event_detail';
import { Link } from "react-router-dom";
import i18n from '../../../i18n'

const Event = (eventInfo) => {


    const overlay = (
      <Popover id="popover-basic" style={{zIndex:'10'}}>
          <EventDetail event={eventInfo.event?._def}/>
      </Popover>
    )

    // case admin#booking
    if (eventInfo.event.title == "Availability") {
      return (
        <OverlayTrigger key={eventInfo.event?._def?.id} trigger="click" containerPadding={20} rootClose overlay={overlay}>
          <div id={`event-${eventInfo.event?._def?.id}`} className={`event availability`}>
              <div className="inner-event">
                <span></span>
              </div>
          </div>
          </OverlayTrigger>
      )
    // case interactable private lesson
    } else if (eventInfo.event?._def?.extendedProps?.is_current_user_lesson) {
        return(
          <div id={`event-${eventInfo.event?._def?.id}`} className={`event lesson ${eventInfo.event?._def?.extendedProps?.lesson_state}`}>
              <OverlayTrigger key={eventInfo.event?._def?.id} trigger="click" containerPadding={20} rootClose overlay={overlay}>
                <div className="inner-event">
                  <span>{eventInfo.event.title}</span>
                </div>
              </OverlayTrigger>
          </div>
        )

    // case canceling - fermeture en attende
    }
    else if (eventInfo.event.title === i18n.t(`activerecord.attributes.availability.pending_closing`)) {
      return(
        <div id={`event-${eventInfo.event?._def?.id}`} className={`event lesson canceling`}>
        <div className="inner-event">
          <OverlayTrigger key={eventInfo.event?._def?.id} trigger="click"  containerPadding={20} rootClose overlay={overlay}>
                <div className="inner-event">
                  <span>{eventInfo.event.title}</span>
                </div>
              </OverlayTrigger>
        </div>
      </div>
        )
    // case not interactable lesson
    }
    else {
      return(
        <div id={`event-${eventInfo.event?._def?.id}`} className={`event lesson other-student`}>
          <div className="inner-event">
            <span>{eventInfo.event.title}</span>
          </div>
        </div>
      )
    }

};


export default Event;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";

const Impersonate = ({user_id}) => {
  const navigate = useNavigate()
  const user = useSelector(state => state.user.userData)


  const handleImpersonate = () => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
    fetch(`/api/v1/users/${user_id}/impersonate`, {
      method: 'POST',
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": csrfToken
      },
    }).then(response => {
        if (response.ok) {
            navigate(`/lessons`)
            location.reload();
        } else {
          console.error('Failed to impersonate user');
        }
      })
      .catch(error => {
        console.error('Error impersonating user:', error);
      });
  }
  
    if (user.role != "Admin") {
      return <></>
    } 

    return (
      <Button className="impersonate-button" onClick={() => {handleImpersonate()}}><FontAwesomeIcon className="icon-details left" icon={faEye} color="#fff" /></Button>
    )


}

export default Impersonate;
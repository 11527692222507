
import React, { useContext, useEffect } from "react"
import Header from "./component/header"
import { useDispatch, useSelector, ReactReduxContext } from "react-redux"
import { fetchConferences, setConferencesList } from "./conferencesSlice"
import { useLocation } from "react-router-dom"
import { setFiltersConferences } from "../filters/filtersSlice"
import ConferenceCard from "./component/conferenceCard"
import i18n from "../../i18n"

export default function Conferences() {

  const dispatch = useDispatch()
  const location = useLocation();
  const filters = useSelector(state => state.filters.filtersConferences);
  const user = useSelector(state => state.user.userData);
  const path = location.pathname
  const conferenceList = useSelector((state) => state.conferences.conferencesList)
  const openNavbar = useSelector((state) => state.navigations.openNavbar)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
  const { store } = useContext(ReactReduxContext)
  const state = store.getState();
  const profile = useSelector((state)=>state.user.currentProfile)


  useEffect(() => {

    dispatch(fetchConferences(filters))
  }, [filters, profile])

  /*
    useEffect(() => {
           if (path == "/conferences/passed") {
            dispatch(setFiltersConferences({ type: ["passed"] }))
          } else {
            dispatch(setFiltersConferences({ type: ["future"] }))
          }
    }, [user, path]); */


  useEffect(() => {
    setTimeout(() => {
      const state = store.getState();
      const filters = state.filters.filtersConferences
      if (filters?.types) {

        dispatch(fetchConferences(filters))

      }
      if (filters?.teacher) {

        dispatch(fetchConferences(filters))

      }
      if (filters?.level) {

        dispatch(fetchConferences(filters))

      }
    }, 200)
  }, [filters, user, profile]);


  if (conferenceList?.length > 0) {
    return (


      <div className={`main-content`}>

        <div className={`page-container ${(openNavbar || responsiveFilter ) &&  'responsive-hidden'} `} >

          <Header />
          <div className="conference-list-container">
            {conferenceList.map((item) => (
              <div key={item.id} className="conference-item">
                <ConferenceCard key={item?.id} item={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }


  return (
    <div className={`main-content`}>
              <div className={`page-container ${(openNavbar || responsiveFilter) && 'responsive-hidden'} `} >

              <Header />

        <div className="conference-list-empty">
          {i18n.t('nodata')}
        </div>

      </div>
    </div>
  );

}

import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  setFiltersLesson,
  setFiltersConferences,
  setFiltersTraining,
  setFiltersCoursCollectives,
  setResponsiveFilterCleanBtn,
  setFiltersAvailabilities
} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'


const ByConferenceType = ({model, action, loading}) => {
    const dispatch = useDispatch()
    const current = useSelector(state => state.filters[model].conference_types)
    const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
    const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
    const ref = useRef()

    const [options, setOptions] = useState([
      { value: 'Business', label: `${i18n.t('filters.options.business')}`},
      { value: 'Everyday', label: `${i18n.t('filters.options.everyday')}` },
      { value: 'Grammar', label: `${i18n.t('filters.options.grammar')}` },
      { value: 'Test preparation', label: `${i18n.t('filters.options.test')}`}
    ])


      //to-do: options will take from backend

    const dispatcher = {
        "setFiltersConferences": (value) => dispatch(setFiltersConferences({conference_types: value})),
    }

    const  onSelectChange = value => {
      // if(responsiveFilter){
      //   dispatch(setResponsiveFiltersArray({action,value,type:'type'}))
      // }
      // else{
        dispatcher[action](value)
      //}
    }

    if(isCleaned){
      ref.current?.select.clearValue();
    }

    return (
        <div className='filter by_level'>
            <div className="line">
              <label htmlFor="filter_type" id="level" className="s_label">{i18n.t('filters.by_conference_type')}</label>
                    <Select
                        ref={ref}
                        isLoading={loading}
                        isDisabled={loading}
                        cacheOptions
                        onChange={onSelectChange}
                        isMulti
                        options={options}
                        defaultValue={current}
                        placeholder={`${i18n.t('helpers.select.prompt')}...`}
                        />
            </div>
        </div>
    )

};

export default ByConferenceType;

import React, { useContext, useEffect, useState, useRef } from "react"
import { ReactReduxContext, useDispatch, useSelector } from "react-redux"
import { Button } from "react-bootstrap"
import { fetchExercises } from "./elearningSlice"
import Steps from "./components/steps"
import { useLocation, useNavigate } from "react-router-dom"
import i18n from "../../i18n"
import Header from "./components/header"
import Filters from "../filters/filters"

export default function ELearnings({closeElearningModale}) {

  const location = useLocation();
  const search = location.search;
  const lessonId = new URLSearchParams(search).get('l') || location.pathname.split("/")[2];
  const dispatch = useDispatch()
  const filters = useSelector((state) => state.filters.filtersELearning)
  const loading = useSelector((state) => state.elearnings.loading)
  const exercices = useSelector((state) => state.elearnings.exercisesList)
  const [openedNode, setOpenedNode] = useState([]);
  const { store } = useContext(ReactReduxContext)



  useEffect(() => {
    dispatch(fetchExercises({filters: filters,  lessonId: [lessonId] }))
  }, [lessonId])

  useEffect(() => {
    const state = store.getState();
    const filters = state.filters.filtersELearning

    dispatch(fetchExercises({filters: filters,  lessonId: [lessonId]}))
  }, [filters]);

  const closeModal = () => {
    closeElearningModale()
  }

  if (loading) {
    return (
      <div className="elearning-page embeded pending-query">
        {i18n.t('pending_data')}
      </div>
    )
  }

  if (exercices) {
    return (
      <div style={{width:'100%', display:'flex', flexDirection:'column'}}>


        <div className="embeded-filters">
              <Button className="validate-elearning" onClick={() => closeModal()}>{i18n.t("validate")}</Button>
              <Filters model={"ELearning"} />
        </div>
        <div className="elearning-page embeded">

          {
            exercices?.map((item, index) => {
              return (
                <Steps index={index} key={index} node={item} openedNode={openedNode}
                  setOpenedNode={setOpenedNode} />
              )
            })
          }
        </div>
      </div>

    )
  }

  return (
    <></>
  )

}

import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {setFiltersLesson, setFiltersTickets, setResponsiveFilterCleanBtn} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'

const ByDemandType = ({model, action, loading}) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.filters[model].demand_type)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
  const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
  const ref = useRef()

	const [options, setOptions] = useState([
		{value: "issue", label: `${i18n.t('filters.options.alert')}`
      },
		{value: "upsell", label: `${i18n.t('filters.options.reclosing')}`
      },
	])


	//to-do: options will take from backend

	const dispatcher = {
		"setFiltersTickets": (type) => dispatch(setFiltersTickets({demand_type: type}))
	}

	const  onSelectChange = value => {
	// 	if(responsiveFilter){
    //   dispatch(setResponsiveFiltersArray({action,value,type:'demandType'}))
    // }
    // else{
      dispatcher[action](value)
    //}
	}

  if(isCleaned){
    ref.current?.select.clearValue();

  }

	return (
		<div className='filter by_demand_type'>
			<div className="line">
				<label htmlFor="filter_type" id="demand_type" className="s_label">
				{i18n.t('filters.by_ticket_type')}
				</label>
				<Select
        			ref={ref}
							cacheOptions
							onChange={onSelectChange}
							isMulti
							options={options}
							defaultValue={current}
							isLoading={loading}
							isDisabled={loading}
          		placeholder={`${i18n.t('helpers.select.prompt')}...`}
				/>
			</div>
		</div>
	)

};

export default ByDemandType;

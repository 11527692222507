import React from "react";

export default function TableTag({data}){

  return(
    <div>
    <div className="row-tag"> Fermé </div>
    </div>
  )
}

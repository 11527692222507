import React, { useState, useEffect, useRef } from "react";
import calendarIcon from '../../images/icons/Group.svg'
import studentIcon from '../../images/icons/student-thin.svg'
import i18n from "../../../i18n";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { beSubscribeCourseLesson } from "../coursCollectivesSlice";
import { useSelector } from "react-redux";

import { Link, useLocation, useNavigate } from "react-router-dom";

export default function CourseCollectiveCard({ item, index, setControlModal }) {
  const [thumbnail, setThumbnail] = useState("")

  useEffect(() => {
    if (item.teacher_avatar){
      const id = item.teacher_avatar.split('/')[5]
      id && setThumbnail(`https://drive.google.com/thumbnail?id=${id}&sz=w1000`)
    }
  }, [item])

  const getClassNames = (item) => {
    if (item?.im_subscribed === true && item?.max_subscribed === true) {
      return true
    }
    if (item?.im_subscribed === true && item?.max_subscribed === false) {
      return true
    }
    if (item?.im_subscribed === false && item?.max_subscribed === true) {
      return true
    }
    if (item?.im_subscribed === false && item?.max_subscribed === false) {
      return false
    }

  };

  const renderAttendances = (attendances) => {
    //debugger;
    return (
      <>
        {[...Array(5)].map((_, i) => {
          return i < attendances ? <FontAwesomeIcon icon={faUser} color="#282828" size="lg" pull="left" /> : <FontAwesomeIcon icon={faUser} color="#E6E6E6" size="lg" pull="left" />
        })
        }
      </>
    )
  }


  return (
    <div className="course-collectives-container">
      <div className="course-collectives-img">
        <img src={thumbnail} />
      </div>
      <div className="course-collectives-content">
        <div className="course-collective-header">
          <span className="teacher">{item?.teacher_name} </span>
          <span className={`level ${item?.subject}`}> {item?.subject} </span>
        </div>
        <span className="title"> {item?.lesson_theme} </span>

        <div className="course-date-container">
          <img className="course-icon" src={calendarIcon} />

          <span className="course-date-string"> {item?.date} </span>
          <span className="course-date-string"> {item?.hour} </span>

        </div>
        <div className="collective-card-content">
          <div className="collective-type">
            <div className="theme-collective">
              <img className="collective-icon" src={studentIcon} />
              <span className="collective-string" key={index}>{item?.lesson_english_type}</span>
            </div>

            <div className="count">{renderAttendances(item?.count_subscribed)}</div>
          </div>

          <button disabled={item?.im_subscribed || item?.max_subscribed} onClick={() => setControlModal({ state: true, item: item })}
            className={`card-btn ${getClassNames(item)} `}
          >
            {
              item?.max_subscribed
                ? i18n.t('full')
                : item?.im_subscribed
                  ? i18n.t('subscribed')
                  : i18n.t('subscribe')
            }

          </button>

        </div>


      </div>


    </div>


  )
}

import { I18n } from "i18n-js";
import getUserLocale from "get-user-locale";
import fr from "./translations/fr.json";
import en from "./translations/en.json";

const i18n = new I18n();

i18n.store(en);
i18n.store(fr);

i18n.locale = getUserLocale();
i18n.enableFallback = true;

export default i18n;
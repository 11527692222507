import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFiltersLesson, setFiltersTraining, setFiltersCoursCollectives, setFiltersELearning, setFiltersConferences, setFiltersTickets, setFiltersAvailabilities, setFiltersTeachers } from "./filtersSlice";

const ActiveFilters = ({model, action}) => {
    const current = useSelector(state => state.filters[model])
    const dispatch = useDispatch()
    const user = useSelector(state => state.user.userData);


    const dispatcher = {
		    "setFiltersTraining": (key) => dispatch(setFiltersTraining({[key]: []})),
        "setFiltersLesson": (key) => dispatch(setFiltersLesson({[key]: []})),
        "setFiltersTickets": (key) => dispatch(setFiltersTickets({[key]: []})),
        "setFiltersCoursCollectives": (key) => dispatch(setFiltersCoursCollectives({[key]: []})),
        "setFiltersELearning": (key) => dispatch(setFiltersELearning({[key]: []})),
        "setFiltersConferences": (key) => dispatch(setFiltersConferences({[key]: []})),
        "setFiltersAvailabilities": (key) => dispatch(setFiltersAvailabilities({[key]: []})),
        "setFiltersTeachers": (key) => dispatch(setFiltersTeachers({[key]: []})),
	}

    const resetFilter = (key) => {
        dispatcher[action](key)
    }

    const renderFilter = () => (
        Object.keys( current ).map( key => {
            if (current[key] && current[key].length !== 0) {
                if (key == 'types' && user.role == "Student") {
                    return <></>
                }
                if (key == 'type' && user.role != "Student") {
                    return <></>
                }
                if (key == 'scope' && user.role != "Student") {
                    return <></>
                }
                return (
                    <div className="filter-cartouche">
                        <div className="icon" onClick={() => resetFilter(key)}></div>
                        <div>
                            <span className="label">{key} : </span>

                            {
                                Array.isArray(current[key]) &&
                                    current[key].map( (c, idx) => {
                                        return <span className="value" key={idx}>{c?.label}</span>
                                    })
                            }

                            {
                                (!Array.isArray(current[key]) && key.includes('_at'))  &&
                                    <span className="value">{getDate(current[key])}</span>
                            }

                            {
                                (!Array.isArray(current[key]) && !key.includes('_at'))  &&
                                    <span className="value">{current[key]?.label || current[key]}</span>
                            }

                        </div>

                    </div>
                )
            }
        })
    )

    const getDate = (date) => {
        let y = (date.getFullYear()).toString();
        let m = (date.getMonth() + 1).toString();
        let d = (date.getDate()).toString();

        m = m.length < 2 ? '0' + m : m
        d = d.length < 2 ? '0' + d : d

        return `${y}-${m}-${d}`
    }

    if (current) {
        return (
            <div className="active-filters">
                {renderFilter()}
            </div>
        )
    } else {
        return (
            <>
            </>
        )
    }

};

export default ActiveFilters;

import React, { useRef, useMemo, useState } from "react";
import ReactHtmlParser from 'react-html-parser';
import JoditEditor from "jodit-react";
import { useSelector } from "react-redux";

const RichEditor = ({ attr, value, onChange, editable, simple, onBlur, setIsFocused,loading }) => {
  const ref = useRef(null);
  const editorRef = useRef(null)

  const [focused, setFocused] = useState(false)


  const options = ['bold', 'italic', 'underline', 'strikethrough', '|', 'ul', 'ol', '|', 'font', 'fontsize', '|', 'outdent', 'indent', 'align', '|', 'hr', '|', 'brush', '|', 'table', 'link', '|', 'undo', 'redo',];


  const config = useMemo(
    () => ({
      placeholder: '',
      defaultActionOnPaste: 'insert_as_html',
      defaultLineHeight: 1.5,
      enter: 'div',
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      toolbarAdaptive: false,
      allowPasteAsHTML: true,
      askBeforePasteHTML:false,
      addNewLine:false,
      toolbarSticky: false,
      readonly: loading,

    }),
    [loading],
  );

  const handleChange = (e, attr) => {
      onBlur(e, attr)
  }


  if (editable) {
    if (simple) {
      return (
        <input
          type="text"
          className="review-input"
          autoComplete="off"
          value={value}
          onBlur={(e)=> { handleChange(e, attr) }}
          onChange={(e) => { onChange(e, attr) }}
        />
      )
    } else {

        return (
          <div className="lesson-bloc">
            <JoditEditor
              ref={editorRef}
              value={value}
              config={config}
              onBlur={(e)=> handleChange(e, attr)}
            />
          </div>

      )

    }
  } else {
    return ReactHtmlParser(value)
  }
}

export default RichEditor;

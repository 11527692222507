import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  setFiltersTickets
} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'


const ByWeekType = ({model, action, loading}) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.filters[model].upsell)
	const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
	const ref = useRef()

	const [options, setOptions] = useState([
		{ value: 'recurring', label: `${i18n.t('active_admin.status_tag.yes')}` },
		{ value: 'oneshot', label: `${i18n.t('active_admin.status_tag.no')}` },
		])

	const dispatcher = {
		"setFiltersTickets": (value) => dispatch(setFiltersTickets({week_type: value})),
	}

	const  onSelectChange = value => {
			dispatcher[action](value)
	}

	if(isCleaned){
		ref.current?.select.clearValue();
	}

	return (
		<div className='filter by_upsell'>
			<div className="line">
				<label htmlFor="filter_type" id="upsell" className="s_label">
        {i18n.t('filters.by_week_type')}
          </label>
				<Select
					ref={ref}
					isLoading={loading}
					isDisabled={loading}
					cacheOptions
					onChange={onSelectChange}
					isMulti = {false}
					options={options}
					defaultValue={current}
          placeholder={`${i18n.t('helpers.select.prompt')}...`}				/>
			</div>
		</div>
	)

};

export default ByWeekType;

import React from "react";
import TableTag from "./tableTag";
import TableCancelAction from "./tableCancelAction";
import TableDemandAction from "./tableDemandAction";
import CommentTooltip from "../components/commentTooltip";

const availabilityColumns = [
	{
		name: "id",
		selector:  row => row.id,
		omit: true
	},
	{
		name: "Date de la demande",
		selector:  row => {
      return (
          <div className="table-sub-list">
              <div className="list-item"> {row?.date}</div>
          </div>
      )
  },
	},

	{
		name: "Professeur",
		selector: row => row.teacher,
	},

	{
		name: "Prof Mngr.",
		selector:  row => row.manager,
	},
	{
		name: "Date-heure dispo",
		selector:  row => {
      return (
          <div className="table-sub-list">
              <div className="list-item"> {row?.event_date}</div>
              <div className="list-item"><div className="ref">{row?.event_hours}</div></div>
          </div>
      )
  },
	},

	{
		name: "Statuts",
		selector:  row => {
      return (
          <div className="table-sub-list">
              <div className="list-item"><div className="label">Stt. initial</div> {row?.previous_state}</div>
              <div className="list-item"><div className="label">Stt. demandé</div><div className="ref"><TableTag data={row?.expected} /></div></div>
          </div>
      )
  },
	},

	{
		name: "Comm. Prof",
		selector:  row => <CommentTooltip note={row.note} />,
	},
	{
		name: "Comm. Mngr.",
		selector: row => (
      <div style={{ marginLeft: '10px'}}>
        <CommentTooltip note={row.admin_note} />
      </div>

    ),
	},
	{
		name: "Stt. de la demande",
		selector:  row => {
			return(
				<TableDemandAction data={row?.state} id={row.id} />
			)
		},
		width:'10%'
	},


];

export default availabilityColumns

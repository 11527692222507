import React from "react";


export default function TestWidget({ score }) {



  const percent = (score) => {
    if (["A1", "A1+", "A1++"].includes(score)) {
      return "10"
    } else if (["A2", "A2+", "A2++"].includes(score)) {
      return "30"
    } else if (["B1", "B1+", "B1++"].includes(score)) {
      return "50"
    } else if (["B2", "B2+", "B2++"].includes(score)) {
      return "70"
    } else if (["C1", "C1+", "C1++"].includes(score)) {
      return "90"
    } else if (["C2", "C2+", "C2++"].includes(score)) {
      return "100"
    }
  }


  return (
    score && (
      <div className="test-widget donut">
        <div className="donut-default"></div>
        <div className="donut-line"></div>
        <div className="donut-text">
          <span>{score}</span>
        </div>
        <div className={`donut-case donut-case-${percent(score)}`}></div>
      </div>
    )
  );





}

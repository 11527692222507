import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { Modal, NavDropdown } from 'react-bootstrap';
import ActionCable from 'actioncable';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchNotifications, fetchNotificationsCount,
  fetchNotificationTabs,
  readAllNotifications,
  readNotification,
  resetNotificationError,
} from './notificationsSlice';
import NotificationTabs from './notification_tabs';

const Notifications = () => {
  // const [count, setCount] = useState(0);
  const [type, setType] = useState('nav');
  const [showModal, setShowModal] = useState(true);
  const [renderNotifTabs, setRenderNotifTabs] = useState(false);
  const [tabKey, setTabKey] = useState('unRead');
  const [tabNestedKey, setTabNestedKey] = useState('reminder');

  const notifCable = ActionCable.createConsumer('/cable');
  const responsiveOne = useSelector((state)=>state.navigations.responsiveOne)

  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications.notificationsList);
  const count = useSelector((state) => state.notifications.notificationCount);

  const reminderCount = useSelector((state) => state.notifications.reminderCount);
  const overviewCount = useSelector((state) => state.notifications.overviewCount);
  const absenceCount = useSelector((state) => state.notifications.absenceCount);

  useEffect(() => {
    dispatch(fetchNotificationsCount())

    notifCable.subscriptions.create('NotificationChannel', {
      received(data)
      {
        if (data.notification.id){
          dispatch(fetchNotificationsCount())
        }

      },
    })
  }, []);

  const handleClick = () => {
    dispatch(fetchNotifications('unRead', 'reminder'));
    setRenderNotifTabs((prevRenderNotifTabs) => !prevRenderNotifTabs);
  };

  const handleNotifState = (key, nestedKey) => {
    document.getElementById('app').click();
    setType('modal');
    setTabKey(key);
    setTabNestedKey(nestedKey);
  };

  const handleState = (key, nestedKey) => {
    setTabKey(key);
    setTabNestedKey(nestedKey);
  };

  const close = () => {
    setShowModal(false);
    setType('nav');
  };

  const renderBell = () => (
    <>
      <FontAwesomeIcon
        className="icon-details"
        icon={faBell}
        color="#AFAFAF"
        onClick={handleClick}
      />
      <span className="notif-count">{count}</span>
    </>
  );

  const handleNotifSubscribe = (data) => {
    fetchNotificationsCount();
    dispatch(fetchNotifications(tabKey, tabNestedKey));
  };

  return (
    <>
      <div className="bell">
        <NavDropdown
          drop={"right"}
          title={renderBell()}
          id="basic-nav-dropdown"
          className={`notif-menu ${responsiveOne && "responsive"}`}
          rootCloseEvent="click"
        >
          <NotificationTabs
            overviewCount={overviewCount}
            absenceCount={absenceCount}
            reminderCount={reminderCount}
            notifState={type}
            handleNotifState={handleNotifState}
            readAllNotif={() => dispatch(readAllNotifications())}
            fetchNotifications={(key, nestedKey) =>
              dispatch(fetchNotifications(key, nestedKey))
            }
            fetchNotificationsCount={fetchNotificationsCount}
            handleState={handleState}
          />
        </NavDropdown>
      </div>
      <Modal
        id="notifModal"
        show={type === 'modal'}
        onHide={close}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <NotificationTabs
            overviewCount={overviewCount}
            absenceCount={absenceCount}
            reminderCount={reminderCount}
            key={tabKey}
            nestedKey={tabNestedKey}
            fetchNotifications={(key, nestedKey) =>
              dispatch(fetchNotifications(key, nestedKey))
            }
            readAllNotif={() => dispatch(readAllNotifications())}
            fetchNotificationsCount={fetchNotificationsCount}
            handleState={handleState}
            handleNotifState={handleNotifState}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Notifications;


import React, { useEffect, useState } from "react"
import checkBtn from '../../images/icons/check-exercise.svg'
import playBtnYellowNewOne from '../../images/icons/btn-yellow-icon.svg'

import rightArrow from '../../images/icons/right-arrow.svg'
import leftArrow from '../../images/icons/left-arrow.svg'
import { useLocation, useNavigate } from "react-router-dom"
import playIcon from '../../images/icons/play-icon.svg'
import emptyArrowIcon from '../../images/icons/white-arrow.svg'
import greenBtn from '../../images/icons/green-btn.svg'
import unassigneBtnGreen from '../../images/icons/unassignebtn-green.svg'
import assigneeBtnGreen from '../../images/icons/assigneBtn-green.svg'

import checkIcon from '../../images/icons/newCheckIcon.svg'
import AssigneButton from "./assigneButton"


export default function ExerciseContent({ setCurrentRow, data, index, controlArrow, setControlArrow, currentRow }) {

  const navigate = useNavigate()

  const basePath = useLocation().pathname;



  useEffect(() => {
    checkColorByAssignee()
  }, [])

  const onClickArrow = (nodeId) => {
    if (controlArrow === nodeId) {
      setControlArrow(null);
    } else {
      setControlArrow(nodeId);
    }
  };


  const checkColorByAssignee = () => {

    //sarı
    let conditionYellow =
      data?.assignment !== null && data?.state === 'assign'


    //mavimsi
    let conditionGreen =
      data?.assignment !== null && data?.state === 'done'


    if (conditionYellow) {
      return 'yellow';
    } else if (conditionGreen) {
      return 'green';
    }
    else {
      return 'gray';
    }
  };

  const renderPanelArrow = () => {
    if (setCurrentRow) {

        return (
          (currentRow === data?.id ) ?
          <img className="icon" onClick={() => {
            onClickArrow(data?.id)
            setCurrentRow(undefined)
            navigate(basePath)

          }} src={leftArrow} />
          :
          <img className="icon" onClick={() => {
            onClickArrow(data?.id)
            setCurrentRow(data?.id)
          }} src={rightArrow} />
      )
    }

  }

  // let normalizedLevel = data?.level.replace(/[^a-zA-Z0-9]/g, '');

  // console.log('normalizedLevel',normalizedLevel)

  return (
    <div className="exercise-content-container">

      <span className={`exercise-content ${checkColorByAssignee()}`}>{data.name}
      </span>

      <div className="icon-container">

        {data?.assignment &&
        <AssigneButton id={data?.id} sided={false} state={data?.state} assigne={data?.assignment} canUnassign={data?.can_unassign} />
          }


        {data?.state === 'done' && <img style={{cursor:'auto'}} className="icon" src={checkIcon} />}

        {(data?.assignment === null && data?.state === null) &&
        <AssigneButton id={data?.id} sided={false} state={data?.state} assigne={data?.assignment} canUnassign={data?.can_unassign} />
        }

        {(data?.assignment && (data?.state === 'assign' || data?.state === "")) &&
        <a target="_blank" href={data?.exercice_link
        }>
          <div className="icon-button-elearning">
            <div className="uncolor-btn">
            <img className="arrow-one" src={emptyArrowIcon} />
            <img className="circle" src={playBtnYellowNewOne} />
            </div>

          </div>
        </a>}
        {(data?.assignment && data?.state === 'done') && <a target="_blank" href={data?.exercice_link}>
        <div className="icon-button-elearning">

          <div className="uncolor-btn">
            <img className="arrow-one" src={emptyArrowIcon} />

            <img className="circle" src={greenBtn} />
          </div>
          </div>
        </a>}


        {(data?.assignment === null && (data?.state === null  )) &&
          <a target="_blank" href={data?.exercice_link}>
          <div className="icon-button-elearning">

            <div className="uncolor-btn">
              <img className="arrow-one" src={emptyArrowIcon} />
              <img className="circle" src={playIcon} />

              </div>

            </div>
          </a>
        }

        {renderPanelArrow()}

      </div>

    </div>
  )
}

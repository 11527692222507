import React from "react";
import { BrowserRouter as Router, Route, Redirect, Routes } from 'react-router-dom';
import { createBrowserHistory as history } from 'history';
import Lessons from '../lessons/lessons';
import Agenda from '../lessons/agenda';
import AdminAgenda from '../lessons/admin_agenda';
import AvAdminAgenda from '../availabilities/admin_agenda';
import Navigation from '../navigation/navigation';
import Training from '../trainings/training';
import Trainings from '../trainings/trainings';
import CoursCollectives from "../cours-collectives/cours_collectives";
import ELearningCardView from "../e-learning/elearningCardView";
import Conferences from "../conferences/conferences";
import Invoices from '../invoices/invoices';
import ELearnings from "../e-learning/elearnings";
import Tickets from "../tickets/tickets";
import TeacherProfile from "../teachers/profile";
import StudentProfile from "../student/profile";
import Teachers from "../teachers/teachers";
import Atas from "../tickets/atas";
import WeekType from '../availabilities/week_type';
import Sponsorship from '../sponsorship/sponsorship';
import AdminWeekType from "../availabilities/admin_week_type";
import Lesson from "../lessons/lesson";

//

export default (
  <Router history={history}>
    <Navigation />
    <Routes>
      <Route path="/lessons" element={<Lessons />} />
      {/* <Route path="/planning" element={window.screen.width > 768 ? <Agenda /> : <MobilePlanning />} /> */}
      <Route path="/planning" element={ <Agenda />} />
      <Route path="/t_planning" element={ <Agenda />} />
      <Route path="/collective_lessons" element={<Lessons />} />
      <Route path="/lessons/:id" element={<Lesson/>} />
      <Route exact path="/trainings/:id" element={<Training />} />
      <Route exact path="/trainings" element={<Trainings />} />
      <Route path="/elearnings" element={<ELearnings />} />
      <Route path="/elearnings_card_view" element={<ELearningCardView />} />
      <Route path="/conferences" element={<Conferences />} />
      <Route path="/invoices" element={<Invoices />} />
      <Route exact path="/tickets/:ticket_type" element={<Tickets />} />
      <Route path="/collective_planning" element={<CoursCollectives />} />
      <Route path="/teachers" element={<Teachers />} />
      <Route path="/teacher_agenda" element={<AdminAgenda />} />
      <Route path="/availabilities" element={<AvAdminAgenda />} />
      <Route path="/teacher" element={<TeacherProfile />} />
      <Route path="/student" element={<StudentProfile />} />
      <Route path="/atas" element={<Atas />} />
      <Route path="/week_type" element={<WeekType />} />
      <Route path="/parrainage" element={<Sponsorship />} />
      <Route path="/admin_week_type" element={<AdminWeekType />} />







      {/*
        <Route exact path="/events" element={<Events />} />
        <Route path="/events/:id" element={<Event />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/expenses" element={<Expenses />} />
        <Route path="/shared_documents" element={<SharedDocuments />} />
      */}
    </Routes>
  </Router>
);

import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'



//name can change
export const getLessonByID = createAsyncThunk(
    'user/getLessonByID',
    async (id) => {
      try {
        const response = await axios.get(`/api/v2/lessons/${id}/lesson_transitions`, {

          headers: {
            'Content-Type': 'application/json',

          },
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )



const lessonTransitionSlice = createSlice({
  name: 'lessonTransition',
  initialState: {
    lessonStatus: [],
  },
  reducers: {
    setLessonStatus: () => {

      state.lessonStatus = action.payload
    },

  },
  extraReducers: (builder) => {

    builder.addCase(getLessonByID.fulfilled, (state, action) => {

        state.lessonStatus = action.payload

    })



  }
})

export const lessonStatus = createSelector(
  (state) => ({
    lessonStatus: state.lessonStatus,
  }),
  (state) => state.lessonStatus
)

export const { setLessonStatus} = lessonTransitionSlice.actions
export default lessonTransitionSlice.reducer

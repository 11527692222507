
import React from "react";
import Filters from "../../filters/filters";
import i18n from "../../../i18n";
import ActiveFilters from "../../filters/activeFilters";

export default function Header(){


  return (
    <>
      <div className="main-header">
          <div className="main-title">
              <h2>{i18n.t('activerecord.attributes.conference.title')}</h2>
          </div>
          <div className="main-actions">
              <Filters model={"Conferences"}/>
          </div>
      </div>
      <ActiveFilters model={"filtersConferences"} action={"setFiltersConferences"} />
    </>
)

}

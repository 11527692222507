
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function SkillsTooltip({ skills }) {


  const renderSkillsTooltip = (props) => {
    return (

      <Tooltip id='button-tooltip' {...props}>
        <span>  {skills?.join(', ')}  </span>

      </Tooltip>
    )

  }

  return (
    <div>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderSkillsTooltip}
      >
        <FontAwesomeIcon icon={faInfoCircle} color="#000" size="lg" pull="right" />
      </OverlayTrigger>
    </div>
  )
}

import React from "react"
import TableDemandAction from "../columns/tableDemandAction"
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";

export default function TicketListItem({event,item,onRowClicked, rootPage}){

  const location = useLocation();
  const path = location.pathname

  console.log('item', item)
  console.log('path, path', path)

  return(
    <div className={`item-container ${rootPage}`} >
      <div className="header">
      <span className="teacher">Professeur: { path?.includes('students') ? item?.student_manager : item?.teacher} </span>
     { (item?.training ) && <span className="teacher">Formation: {item?.training } </span>}

      <span className={`status ${item?.state}`}>{<TableDemandAction data={item?.state} id={item?.id} />}</span>

      </div>
      <div className="date-card-list">
      <span className="date-card-list-string"> {item?.date} </span>

      </div>
      <div className="level-area">
      <span className="note">{(item?.note)}</span>

      </div>

      <div className="icon-area" >

        <span className="level"> {
            item?.app_document ?
            <div > <a target="_blank" href={item?.app_document}> <FontAwesomeIcon className="icon-details" icon={faDownload} color="#AFAFAF" /> </a></div>
              : ''
          }  </span>

      </div>



    </div>
  )
}

import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setFiltersTickets} from "../filtersSlice";
import i18n from '../../../i18n'

const ByStudentManager = ({model, action, loading}) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.filters[model].student_managers)
	const [options, setOptions] = useState([])

	const loadManagerOptions = () => {
		const url = `/api/v2/filters?filter=manager`
		const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
			.content.value;
		const promise = fetch(url, { credentials: "same-origin" }).then(r =>
			r.json()
		).then(data => {
			setOptions(data)
		});
	}

	useEffect(() => {
		loadManagerOptions()
	}, []);

	const dispatcher = {
		"setFiltersTickets": (student_managers) => dispatch(setFiltersTickets({student_managers: student_managers}))
	}

	const  onSelectChange = value => {
		dispatcher[action](value)
	}

	return (
		<div className='filter by_demande_statuses'>
			<div className="line">
				<label htmlFor="filter_type" id="demande_statuses" className="s_label">
				{i18n.t('filters.by_account_manager')}

          </label>
				<Select
					cacheOptions
					onChange={onSelectChange}
					isMulti
					options={options}
					defaultValue={current}
					isLoading={loading}
					isDisabled={loading}
          placeholder={`${i18n.t('helpers.select.prompt')}...`}
				/>
			</div>
		</div>
	)

};

export default ByStudentManager;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setResponsivePanel } from "../navigation/navigationSlice";


const Panel = ({children, state, unselect, expand}) => {
    const navigate = useNavigate();
    const basePath = useLocation().pathname;
    const location = useLocation()
    const responsiveOne = useSelector((state)=>state.navigations.responsiveOne)
    const search = location.search;
    const lessonId = new URLSearchParams(search).get('l');

    const close = () => {
        unselect(undefined)
        navigate(basePath)
    }


    return (
        <div className={`panel ${state} ${responsiveOne && "responsive-panel"}`}>
           <div className="actions">
              {(!responsiveOne && location?.pathname?.includes('lessons') )&&  <div onClick={() => expand()}className="icon expand-icon"></div>}
              <div onClick={() => close()} className="icon close-icon"></div>
           </div>
           {state == 's' && children}
        </div>
    )

};

export default Panel;

import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ImportantDates from "../importantDates";
import Status from '../status';
import ListItem from "../listItem";
import i18n from '../../../../i18n'
import download from '../../../images/icons/green_background_white_icon.svg'
import noDownload from '../../../images/icons/no-documents-btn.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';



const SidedTrainingCard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(state => state.user.userData)
  const training = useSelector(state => state.trainings.trainingShow)
  const path = location.pathname
  const responsiveOne = useSelector((state)=>state.navigations.responsiveOne)


  // how to handle alternate rendering
  // fullwidth -> trainings/:id
  // sided -> trainings?t={:id}&pm=s (for teacher admin and hr)
  // mobile -> trainings/:id (mobile version)

  // to do clean training object & make a specific call for manager

  const renderPifLine = () => {
    if (training?.documents?.find((item)=>item?.document_type==='program')) {
      return (
        <div className={`bloc-document ${responsiveOne && 'responsive'} `}>
          <ListItem label={`${i18n.t('documents.pif')}`} value={<a target="_blank" href={training?.documents?.find((item)=>item?.document_type==='program')?.url}> <img style={{cursor:training?.documents?.length === 0 && 'not-allowed' }} className="icon" src={ training?.documents?.length === 0 ? noDownload : download} /> </a>}/>
        <div></div>
        </div>
      )
    }
  }

  return (
    <div className="training-card bloc light-grey sided">
      <div className="bloc-header">

        <h2>{training.training_type}</h2>
        <p>{training.base_level}</p>
        <p>{training.test_type}</p>

        <Status status={training.student_status} />

      </div>
      {renderPifLine()}
      <div className={`bloc-body ${responsiveOne && 'responsive'} `}>
      <div className="training-info-list ">

          <ListItem label= {`${i18n.t('views.trainings.card.labels.manager')}`} managerId={training.manager_id} value={training.manager_name} status={"active"} />
          <ListItem label={`${i18n.t('views.trainings.card.labels.teacher')}`} teacherId={training.teacher_id} value={training.teacher_name} status={"active"} />
          <ListItem label={`${i18n.t('navigation.student.lessons')}`} value={`${training.lesson_done}`} status={"active"} />
          <ListItem label={`${i18n.t('navigation.student.collective_lessons')}`} value={`${training.cc_lesson_done}`} status={"active"} />
          <ListItem label={`${i18n.t('navigation.student.elearnings')}`} value={ training.elearnings } status={!training.elearning_access && "inactive"} />
          <ListItem label={`${i18n.t('navigation.student.conferences')}`} value={''} status={!training.conference_access && "inactive"} />
        </div>
      </div>
      <div className="bloc-footer">

      </div>
    </div>
  )

};

export default SidedTrainingCard;

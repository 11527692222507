import React from "react";
import Status from "../components/status";

import attendanceGreen from '../../images/icons/attendanceGreen.svg'
import attendanceRed from '../../images/icons/attendanceRed.svg'
import i18n from '../../../i18n'
import ReviewLink from "../components/reviewLink";


const columns = [
    {
        name: "id",
        selector:  row => row.id,
        omit: true
    },
    {
      name: `${i18n.t('views.lessons.date')}`,
      selector:  row => row.date
    },
    {
      name: `${i18n.t('views.lessons.hours')}`,
      selector:  row => row.hour,
    },
    {
      name: `${i18n.t('views.lessons.review.theme')}`,
      selector:  row => row.theme
    },
    {
      name: `${i18n.t('views.lessons.theme')}`,
      selector:  row => row.subject,

    },
    {
      name: `${i18n.t('views.lessons.teacher')}`,
      selector:  row => row.teacher_name,
    },
    {
      name: `${i18n.t('views.lessons.status')}`,
      cell: (row, index, column, id) => <Status status={row.state} id={row.id} />,
    },

    {
      name: `${i18n.t('views.lessons.review.state')}`,
      selector: row => {
          return (
            <ReviewLink lesson={row}/>
          )
        },
    }
  ];



  export default columns

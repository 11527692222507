import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";


const Sponsorship = () => {
  const openNavbar = useSelector((state) => state.navigations.openNavbar)

  // to update with data from controller & translation
  return (
    <div className={`main-content`}>
      <div className={` page-container ${openNavbar && 'responsive-hidden'} `} >
      <iframe
          id="sponsorship"
          title="Parrainage"
          width="100%"
          height="100%"
          type="text/html"
          src="https://www.cercledeslangues.com/parrainage-eleves">
        </iframe>
      </div>
    </div>
  )

};

export default Sponsorship;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "./teachersSlice";
import Header from "./components/header";
import PersonalInfos from "./components/parts/personalInfos";
import Statistics from "./components/parts/statistics";
import BankInfos from "./components/parts/bankInfos";
import openArrow from "../images/icons/openArrow.svg";
import closeArrow from "../images/icons/closeArrow.svg";
import i18n from "../../i18n";

export default function Profile() {
  const dispatch = useDispatch();
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)


  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);
  const openNavbar = useSelector((state) => state.navigations.openNavbar)

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (

    <div className={`main-content`}>

      <div className={` page-container ${openNavbar && 'responsive-hidden'} `} >


        <Header />

        <div className={`list-infos-teacher ${responsiveOne && 'responsive'} `}>
          <ul className="teacher-list-ul">
            <li className="teacher-list-item" onClick={() => handleClick(0)}>
              <span>
                <img
                  className="icon"
                  src={activeIndex === 0 ? openArrow : closeArrow}
                />
              </span>
              {i18n.t('views.profile.personal_informations')}
              {activeIndex === 0 && <PersonalInfos />}
            </li>
            <li className="teacher-list-item" onClick={() => handleClick(1)}>
              <span>
                <img
                  className="icon"
                  src={activeIndex === 1 ? openArrow : closeArrow}
                />
              </span>
              {i18n.t('views.profile.bank_informations')}
              {activeIndex === 1 && <BankInfos />}
            </li>
            <li className="teacher-list-item" onClick={() => handleClick(2)}>
              <span>
                <img
                  className="icon"
                  src={activeIndex === 2 ? openArrow : closeArrow}
                />
              </span>
              {i18n.t('views.profile.stats')}
              {activeIndex === 2 && <Statistics />}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react"
import { ReactReduxContext, useDispatch, useSelector } from "react-redux"
import Panel from "../toolbox/panel"
import Header from "./components/header"
import { useLocation, useNavigate } from "react-router-dom";
import { fetchExercisesBaseList } from "./elearningSlice"
import CardLine from "./components/cardLine";
import ELearning from "./elearning";

export default function ELearningCardView() {

  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const dispatch = useDispatch()
  const panelState = new URLSearchParams(search).get('pm');
  const filters = useSelector((state) => state.filters.filtersELearning)
  const exercices = useSelector((state) => state.elearnings.exercicesBaseList)
  const { store } = useContext(ReactReduxContext)
  const [currents, setCurrents] = useState([])
  const [business, setBusiness] = useState([])
  const [grammar, setGrammar] = useState([])
  const [everyday, setEveryday] = useState([])
  const [test, setTest] = useState([])
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)
  const openNavbar = useSelector((state) => state.navigations.openNavbar)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
  const [selectedCard, setSelectedCard] =useState()
  const profile = useSelector((state)=>state.user.currentProfile)




  useEffect(() => {
    dispatch(fetchExercisesBaseList(filters))
  }, [])

  useEffect(() => {
    const state = store.getState();
    const filters = state.filters.filtersELearning
    dispatch(fetchExercisesBaseList(filters))
  }, [filters,profile]);


  useEffect(() => {
    setCurrents(exercices.filter((ex) => ex.state == "assign"))
    setBusiness(exercices.filter((ex) => ex.exercice_type == "Business English"))
    setGrammar(exercices.filter((ex) => ex.exercice_type == "Grammar"))
    setEveryday(exercices.filter((ex) => ex.exercice_type == "Everyday"))
    setTest(exercices.filter((ex) => ex.exercice_type == "Test preparation"))
  }, [exercices, filters, profile])

  const onUnselect = () => {
    navigate(`/elearnings_card_view`)
  }

  return (
    <div className="main-content">

      <div className={` page-container ${(openNavbar||responsiveFilter || (responsiveOne && panelState=='s')) && 'responsive-hidden'} ${panelState == 's' && 'sided'}`} >

        <Header />
        <div className="elearning-list">

          {
            currents.length > 0 &&
            <CardLine exercices={currents} type="currents" setSelectedCard={setSelectedCard} />
          }

          {
            business.length > 0 &&
            <CardLine exercices={business} type="business" setSelectedCard={setSelectedCard} />
          }

          {
            grammar.length > 0 &&
            <CardLine exercices={grammar} type="grammar" setSelectedCard={setSelectedCard} />
          }

          {
            everyday.length > 0 &&
            <CardLine exercices={everyday} type="everyday" setSelectedCard={setSelectedCard} />
          }

          {
            test.length > 0 &&
            <CardLine exercices={test} type="test" setSelectedCard={setSelectedCard} />
          }

        </div>

      </div>


      <Panel state={panelState} unselect={onUnselect} >
        <ELearning key={selectedCard} />
      </Panel>

    </div >
  )
}



import React, { forwardRef, useState } from "react"
import CardListItem from "../lessons/components/cardListItem"


const CardList = forwardRef(({ data, setPassedPage, passedPage, loading, futurePage, setFuturePage, card, onRowClicked,rootPage }, ref) => {
  const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0 });



  const handleWheel = (event) => {
    if (ref?.current && !loading && event.deltaY < 0 && setFuturePage) {
      if (ref.current.scrollTop == 0 && scrollPosition.scrollTop == ref.current.scrollTop) {
        setFuturePage(futurePage + 1);
        //setLoading(true)
      }
    }

  }


  const handleScroll = () => {
    console.log('trigger?')
    if (ref.current && !loading && setPassedPage) {
      const { scrollTop } = ref.current;
      const cardListHeight = document.getElementsByClassName('card-list')[0].clientHeight
      if (scrollTop + window.innerHeight + 200 >= cardListHeight && scrollPosition.scrollTop < scrollTop) {
        setPassedPage(passedPage + 1);
        //setLoading(true)
      }
      setScrollPosition({ scrollTop });


    }
  };


  return (
    <div className="card-list" ref={ref} onScroll={() => { handleScroll() }} onWheel={handleWheel}>

      {
        data?.map((item) => {
          return (
            React.cloneElement(card, { item, onRowClicked, rootPage }
            )
          )
        })
      }



    </div>
  )


})

export default CardList

import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  setFiltersLesson,
  setFiltersConferences,
  setFiltersTeachers,
  setFiltersCoursCollectives,
  setResponsiveFilterCleanBtn,
  setFiltersAvailabilities,
  setFiltersELearning
} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'
import { useLocation } from "react-router-dom";


const ByType = ({model, action, loading}) => {
    const dispatch = useDispatch()
    const current = useSelector(state => state.filters[model].english_types)
    const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
    const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
    const ref = useRef()
    const location = useLocation();


    const [options, setOptions] = useState([
      { value: 'Business', label: `${i18n.t('filters.options.business')}`},
      { value: 'Everyday', label: `${i18n.t('filters.options.everyday')}` },
      { value: 'Grammar', label: `${i18n.t('filters.options.grammar')}` },
      { value: 'Test preparation', label: `${i18n.t('filters.options.test')}`}
    ])

    useEffect(() => {
      if (location.pathname === '/collective_planning'|| location.pathname==='/collective_lessons') {
        setOptions(prevOptions =>
          prevOptions.map(option =>
            option.value === 'Business English'
              ? { ...option, value: 'Business' }
              : option
          )
        );
      }
    }, [location.pathname]);


      //to-do: options will take from backend

    const dispatcher = {
        "setFiltersConferences": (value) => dispatch(setFiltersConferences({english_types: value})),
        "setFiltersCoursCollectives": (value) => dispatch(setFiltersCoursCollectives({english_types: value})),
        "setFiltersAvailabilities": (value) => dispatch(setFiltersAvailabilities({english_types: value})),
        "setFiltersELearning": (value) => dispatch(setFiltersELearning({english_types: value})),
        "setFiltersTeachers": (value) => dispatch(setFiltersTeachers({english_types: value})),
    }

    const  onSelectChange = value => {
      // if(responsiveFilter){
      //   dispatch(setResponsiveFiltersArray({action,value,type:'type'}))
      // }
      // else{
        dispatcher[action](value)
      //}
    }

    if(isCleaned){
      ref.current?.select.clearValue();
    }

    return (
        <div className='filter by_level'>
            <div className="line">
              <label htmlFor="filter_type" id="level" className="s_label">{i18n.t('filters.by_type')}</label>
                    <Select
                        ref={ref}
                        isLoading={loading}
                        isDisabled={loading}
                        cacheOptions
                        onChange={onSelectChange}
                        isMulti
                        options={options}
                        defaultValue={current}
                        placeholder={`${i18n.t('helpers.select.prompt')}...`}
                        />
            </div>
        </div>
    )

};

export default ByType;

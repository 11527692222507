import React from "react";
import i18n from "../../../i18n";

const Header = () => {

    return (
        <>
        <div className="main-header">
            <div className="main-title">
                <h2>{i18n.t('navigation.teacher.profile')}</h2>
            </div>
        </div>
        </>
    )

};

export default Header;

import React from "react";
import i18n from '../../../i18n'


const Status = ({status}) => {

        return (
            <div className={`invoice-status ${status}`}>
               {`${i18n.t(`views.invoices.states.${status}`)}`}

            </div>
        )

};

export default Status;

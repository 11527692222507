import React from "react";
import { useSelector } from "react-redux";
import i18n from "../../../../i18n";


export default function PersonalInfos() {

  const teacher = useSelector((state) => state.teachers.profile);


  return (
    <div className="container-profile">
      <div className="item-container-profile">
        <span className="item">{i18n.t('views.profile.first_name')}</span>
        <span className="item-pair"> {teacher?.first_name}</span>
      </div>

      <div className="item-container-profile">
        <span className="item">{i18n.t('views.profile.last_name')}</span>
        <span className="item-pair">{teacher?.last_name}</span>

      </div>
      <div className="item-container-profile">
        <span className="item">{i18n.t('views.profile.address')}</span>
        <span className="item-pair">{teacher?.address}</span>

      </div>
      <div className="item-container-profile">
        <span className="item">{i18n.t('views.profile.zip_code')}
        </span>
        <span className="item-pair">{teacher?.zip_code}</span>
      </div>
      <div className="item-container-profile">
        <span className="item">{i18n.t('views.profile.city')}</span>
        <span className="item-pair">{teacher?.city}</span>

      </div>
      <div className="item-container-profile">
        <span className="item">{i18n.t('views.profile.country')}</span>
        <span className="item-pair">{teacher?.country}</span>

      </div>
      <div className="item-container-profile">
        <span className="item">{i18n.t('views.profile.email')}</span>
        <span className="item-pair">{teacher?.email}</span>

      </div>

      <div className="item-container-profile">
        <span className="item">{i18n.t('views.profile.phone')}</span>
        <span className="item-pair">{teacher?.phone_number}</span>
      </div>

      <div className="item-container-profile">
        <span className="item">{i18n.t('views.profile.company_number')}
        </span>
        <span className="item-pair">{teacher?.bank_account?.company_number}</span>
      </div>














    </div>
  )

}

import React, {useEffect, useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWeekType } from "../availabilities/availabilitiesSlice";
import { setFiltersAvailabilities } from "../filters/filtersSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from './components/header';
import Calendar from "../toolbox/calendar";
import Modal from "../toolbox/modal";
import Event from './components/event';
import StateManagement from "../lessons/components/state_management";

const WeekType = () => {
    const dt = new Date();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [start_at, setStartAt] = useState(null)
    const [end_at, setEndAt] = useState(null)
    const user = useSelector(state => state.user.userData)
    const weekType = useSelector(state => state.availabilities.weekType)
    const location = useLocation();
    const search = location.search;
    const path = location.pathname
    const ref = useRef(null);
    const modalState = new URLSearchParams(search).get('pm');

    const openNavbar = useSelector((state) => state.navigations.openNavbar)
    let filters = useSelector(state => state.filters.filtersAvailabilities);


    useEffect(() => {
        //dispatch(setFiltersAvailabilities({teachers: null}))
    }, [path])

    useEffect(() => {
        dispatch(fetchWeekType(filters))
    }, [start_at, filters]);

    useEffect(() => {
        if (modalState == 'm') {
            ref.current?.onShow()
        } else {
            ref.current?.onHide()
        }
    }, [modalState]);

    const changeDate = () => {
    }

    const handleSelect = (newEvent) => {
        navigate(`?slot=${JSON.stringify(newEvent)}&pm=m&state=new_slot`)
    }

    return (
      <div className={`main-content`}>
      <div className={`page-container ${(openNavbar) && 'responsive-hidden'}`} >


            <Header weekType />
            {/* <Avatar calendar /> */}
            <Calendar data={weekType} date={dt} changeDate={changeDate} selectable={true} event={Event} scope="week_type" handleSelect={handleSelect} />
            </div>
            <Modal ref={ref} large>
                <StateManagement />
            </ Modal>
        </div>
    )

};

export default WeekType;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateInvoice } from "./../invoicesSlice";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Button } from 'react-bootstrap'
import i18n from '../../../i18n'


const ConfirmationModal = (showModal) => {
  const invoice = useSelector(state => state.invoices.invoiceShow)
  const dispatch = useDispatch();
  const basePath = useLocation().pathname;
  const navigate = useNavigate();

  const handleClick = () => {
    dispatch(updateInvoice(invoice.id))
    navigate(basePath)
  }

  const renderContent = () => {
    const account = invoice.validation.account
    const personals = invoice.validation.personals
    if (account && personals) {
      return (
        <>
          <p>
            {i18n.t("views.invoices.definitive")}

          </p>
          <div className="invoice-modal-footer">
            <Button onClick={() => { handleClick() }}>{i18n.t('devise.invitations.new.submit_button')}</Button>
          </div>
        </>
      )
    } else if (account && !personals) {
      return (
        <>
          <p>
            {i18n.t("Vos informations personnelles ne sont pas correctement saisies, merci de les vérifier avant de soumettre votre facture")}
          </p>
          <div className="invoice-modal-footer">
            <div></div>
            <Link to={"/profile"}>
              {i18n.t("Vérifier")}
            </Link>
          </div>
        </>
      )
    } else {
      return (
        <>
          <p>
            {i18n.t("Vos informations bancaires ne sont pas correctement saisies, merci de les vérifier avant de soumettre votre facture")}
          </p>
          <div className="invoice-modal-footer">
            <div></div>
            <Link to={"/profile"}>{i18n.t("Vérifier")}
            </Link>
          </div>
        </>
      )
    }
  }

  return (
    <>
      <h3>{i18n.t('devise.registrations.edit.are_you_sure?')}</h3>

      {renderContent()}
    </>
  )

};

export default ConfirmationModal;

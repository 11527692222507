import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import ActionCable from "actioncable";
import NotificationItem from "./notification_item";
import i18n from '../../i18n';
import { Link } from "@mui/material";
import {
	fetchNotifications,
	readAllNotifications,
	fetchNotificationsCount
} from "./notificationsSlice"; // Adjust the import path as needed

const NotificationTabs = ({ key: initialKey = 'unRead', nestedKey: initialNestedKey = 'reminder', handleState, handleNotifState, notifState, reminderCount, overviewCount, absenceCount	}) => {
	const dispatch = useDispatch();
	const [key, setKey] = useState(initialKey);
	const [nestedKey, setNestedKey] = useState(initialNestedKey);

	const { notificationsList = [], error } = useSelector(state => state.notifications);

	useEffect(() => {
		dispatch(fetchNotifications({ key, nestedKey }));
	}, [dispatch, key, nestedKey]);

  const responsiveOne = useSelector((state)=>state.navigations.responsiveOne)


	const handleTabs = (newKey) => {
		setKey(newKey);
		handleState(newKey, nestedKey);
		dispatch(fetchNotifications({ read: newKey, category: nestedKey }));
	};

	const handleNestedTabs = (newNestedKey) => {
		setNestedKey(newNestedKey);
		handleState(key, newNestedKey);
		dispatch(fetchNotifications({ read: key, category: newNestedKey }));
	};

	const handleReadAll = () => {
		const notificationIds = notificationsList.map(notification => notification.id);
		dispatch(readAllNotifications(notificationIds))
			.then(() => {
				dispatch(fetchNotificationsCount())
			})
			.catch((error) => {
				// Optionally handle error
				console.error("Failed to mark all notifications as read:", error);
			});
	};

	const renderTitle = (title, count) => (
		<>
			<div>{title}</div>
			{key === 'unRead' && <span className="notif-count" style={{ marginLeft: '5px' }}>{count}</span>}
		</>
	);

	const traduction = {
		'read': 'Passées',
		'unRead': 'Actives',
		'overview': 'Bilans',
		'reminder': 'Rappels',
		'absence': 'Absences'
	};

	let counts = {
		reminder: reminderCount,
		overview: overviewCount,
		absence: absenceCount
	};

	return (
		<Row className="notif-container">
			<Col>
				<Tabs
					id="notification-tabs"
					activeKey={key}
					onSelect={(k) => handleTabs(k)}
					className="notif-main-tabs"
				>
					{["unRead", "read"].map((t, idx) => (
						<Tab key={idx} eventKey={t} title={i18n.t(`views.notifications.tabs.${t}`)}>
							<Tabs
								id="notification-tabs"
								activeKey={nestedKey}
								onSelect={(category) => handleNestedTabs(category)}
								className="notif-secondary-tabs justify-content-center w-100"
							>
								{["reminder", "overview", "absence"].map((nt, ntIdx) => (
									<Tab key={ntIdx} eventKey={nt} title={renderTitle(i18n.t(`views.notifications.tabs.${nt}`), counts[nt])}>
										<div className="actions">
											{key === 'unRead' && notificationsList.length > 0 && (
												<Link className="btn small-btn" onClick={handleReadAll}>
													{i18n.t(`views.notifications.read_all`)}
												</Link>
											)}
											{/* {notifState === 'nav' && (
												<Link className="btn small-btn" onClick={() => handleNotifState(key, nestedKey)}>
													Agrandir la fenêtre
												</Link>
											)} */}
										</div>
										<div style={{ overflowY: 'auto', height: '250px' }}>
											{notificationsList.map((notif) => (
												<NotificationItem
													fetchNotificationsCount={fetchNotificationsCount}
													key={notif.id}
													notification={notif}
												/>
											))}
										</div>
									</Tab>
								))}
							</Tabs>
						</Tab>
					))}
				</Tabs>
			</Col>
		</Row>
	);
};

export default NotificationTabs;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
 setFiltersAvailabilities,
 setFiltersTeachers
} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'


const ByFillRate = ({ model, action, loading }) => {
  const dispatch = useDispatch()
  const current = useSelector(state => state.filters[model].fill_rate)
  const [options, setOptions] = useState([])
  const responsiveFilter = useSelector((state) => state.navigations.openResponsiveFilters)
  const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
  const ref = useRef(null)

  const loadPCOptions = () => {
    const url = `/api/v2/filters?filter=fill_rate`
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
      .content.value;
    const promise = fetch(url, { credentials: "same-origin" }).then(r =>
      r.json()
    ).then(data => {
      setOptions(data)
    });
  }

  useEffect(() => {
    loadPCOptions()
  }, []);

  const dispatcher = {
    "setFiltersTeachers": (value) => dispatch(setFiltersTeachers({fill_rate: value})),
  }

  const onSelectChange = (value) => {
    // if (responsiveFilter) {
    //   dispatch(setResponsiveFiltersArray({ action, value, type: 'level' }))

    // }
    // else {
      dispatcher[action](value)
    //}
  }


  if(isCleaned){
    ref.current?.select.clearValue();

  }


  return (
    <div className='filter by_fill_rate'>
      <div className="line">
        <label htmlFor="filter_type" id="fill_rate" className="s_label">
        {i18n.t('filters.by_fill_rate')}

          </label>
        <Select
          ref={ref}
          cacheOptions
          onChange ={ (value)=>{ onSelectChange(value)} }
          isMulti
          options={options}
          defaultValue={  current }
          isLoading={loading}
          isDisabled={loading}
          placeholder={`${i18n.t('helpers.select.prompt')}...`}
        />
      </div>
    </div>
  )

};

export default ByFillRate;

import React, {useEffect, useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCalendarLessons } from "./lessonsSlice";
import { fetchUser } from '../user/userSlice';
import { fetchCalendarAvailabilities } from "../availabilities/availabilitiesSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from './components/header';
import Calendar from "../toolbox/calendar";
import Avatar from "../teachers/avatar";
import Modal from "../toolbox/modal";
import StateManagement from "./components/state_management";
import Event from "./components/event";

//column rendering depending of role / sided

const Agenda = () => {
    const dt = new Date();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [start_at, setStartAt] = useState(null)
    const [end_at, setEndAt] = useState(null)
    const user = useSelector(state => state.user.userData)
    const lessons = useSelector(state => state.lessons.lessonsCal)
    const availabilities = useSelector(state => state.availabilities.availabilitiesCal)
    const error = useSelector(state => state.lessons.error)
    const location = useLocation();
    const search = location.search;
    const ref = useRef(null);
    const modalState = new URLSearchParams(search).get('pm');
    const openNavbar = useSelector((state)=>state.navigations.openNavbar)
    const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
    const profile = useSelector((state)=>state.user.currentProfile)



    useEffect(() => {
        dispatch(fetchCalendarLessons({start_at, end_at}))
    }, [start_at,profile]);

    useEffect(() => {
        if (modalState == 'm') {
            ref.current?.onShow()
        } else {
            ref.current?.onHide()
        }
    }, [modalState]);

    const changeDate = (date) => {
        const temp_wkStart = new Date(new Date(date).setDate(new Date(date).getDate()));
        const temp_wkEnd = new Date(new Date(temp_wkStart).setDate(temp_wkStart.getDate() + 7));
        setEndAt(temp_wkEnd)
        setStartAt(temp_wkStart)
    }

    const handleSelect = (newEvent) => {
        navigate(`?a=${JSON.stringify(newEvent)}&pm=m&state=new_av`)
    }

    useEffect(() => {
        dispatch(fetchCalendarAvailabilities({start_at, end_at}))
        dispatch(fetchUser())
    }, [lessons,profile])


    return (


      <div className= {`main-content`}>

            <div className= {`page-container ${(openNavbar || responsiveFilter ) && 'responsive-hidden'}`}>
                <Header />
                {(user.role == "Student" && location.pathname == "/planning") && <Avatar calendar/>}
                <Calendar event={Event} data={lessons} secondaryData={availabilities} date={dt} changeDate={changeDate} handleSelect={handleSelect} selectable={['Teacher', 'Admin', 'SuperAdmin'].includes(user.role)}/>
            </div>
            <Modal ref={ref}>
                <StateManagement />
            </ Modal>
        </div>
    )

};

export default Agenda;

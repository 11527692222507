import React from "react";
import Status from "../components/status";
import Issue from '../components/issue';
import i18n from "../../../i18n";
import RenderIcon from "../components/renderIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClipboardCheck, faClipboard
} from '@fortawesome/free-solid-svg-icons';
import UpsellButton from "../components/upsellBtn";
import OpenTraining from '../components/openTraining';
import Impersonate from "../../user/impersonate";


const columns = [
  {
    name: "id",
    selector: row => row.id,
    omit: true
  },
  {
    name: `${i18n.t('views.trainings.alert')}`,
    width: "10%",
    selector: row => <Issue has_issue={row.ticket_issue} note={row.ticket_issue_note} id={row.id} />

  },
  {
    name: `${i18n.t('views.trainings.chart.upsell')}`,
    selector: row => <UpsellButton trainingId={row.id} ticket_upsell={row?.ticket_upsell} note={row.ticket_upsell_note}  />,
    width: "10%"

  },
  {
    name: `${i18n.t('views.trainings.training')}`,
    width: "10%",
    selector: row => <OpenTraining row={row}><div className="bold">{row.student}</div></OpenTraining>,
  },
  {
    name: `${i18n.t('activerecord.attributes.training.language')}`,
    width: "10%",
    selector: row => <OpenTraining row={row}><div className="bold"> {i18n.t(`reasons.languages.${row?.language}`)} </div></OpenTraining>,
  },

  {
    name: `${i18n.t('views.trainings.dates')}`,
    width: "12%",
    selector: row => {
      return (
        <div className="table-sub-list">
          <OpenTraining row={row}>
          <div className="list-item"><div className="label">DEBUT :</div> {row.start_at}</div>
          <div className="list-item"><div className="label">FIN :</div> {row.end_at}</div>
          </OpenTraining>

        </div>
      )
    },
  },

  {
    name: `${i18n.t('views.trainings.status')}`,
    width: "10%",
    selector: row => {
      return (
        <div className={`table-sub-list ${row.student_status}`}>
           <OpenTraining row={row}>
           {i18n.t(`activerecord.attributes.student.status.${row.student_status}`)}

          </OpenTraining>
        </div>
      );
    }
  },

  {
    name: `${i18n.t('views.trainings.progress')}`,
    width: "10%",
    selector: row => {
      return (
        <div className="table-sub-list">
           <OpenTraining row={row}>
           <div className="list-item"><div className="label">CP : </div>{row.lessons_done}</div>
          <div className="list-item"><div className="label">CC : </div>{row.cc_lessons_done}</div>
          <div className="list-item"><div className="label">Elearning : </div>{row.elearning}</div>

          </OpenTraining>

        </div>
      )
    }
  },
  {
    name: `${i18n.t('views.trainings.lessons')}`,
    width: "10%",
    selector: row => {
      return (
        <div className="table-sub-list">
           <OpenTraining row={row}>
           <div className="list-item"><div className="label">Dernier : </div>{row.last_lesson_date}</div>
          <div className="list-item"><div className="label">prochain : </div>{row.next_lesson_date}</div>
          <div className="list-item">
            <div className="label">Carnet : </div>
            {(row.has_passed_lessons) ?
              row.review_done ? (
              <a className="list-link" >
                <FontAwesomeIcon icon={faClipboardCheck} color="#1abc9c" size="lg" pull="left" />
              </a>
            ) : (
              <a className="list-link" >
                <FontAwesomeIcon icon={faClipboard} color="#cc8574" size="lg" pull="left" />
              </a>
            ) : ''}
          </div>

          </OpenTraining>

        </div>
      )
    }
  },
  {
    name: `${i18n.t('views.trainings.test.title')}`,
    width: "10%",
    selector: row => {
      return (
        <div className="table-sub-list">
          <OpenTraining row={row}>
          <div className="list-item"><div className="label">Type : </div><div className="value">{row.test_type}</div></div>
          <div className="list-item"><div className="label">Date : </div><div className="value">{row.planned_test_date}</div></div>
          <div className="list-item"><div className="label">Statut : </div><div
            className="value">
            <RenderIcon status={row.evaluation_status} />

          </div></div>

          </OpenTraining>

        </div>
      )
    }
  },
  {
    name: "",
    width: "8%",
    selector: row => <Impersonate user_id={row.user_id} />,
  }
];

export default columns

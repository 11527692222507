import React from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../i18n";
import { faDownload, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { deleteDocument, updateDocument } from "../trainingsSlice";
import { useLocation } from "react-router-dom";

const Columns = () => {
  const user = useSelector(state => state.user.userData)
  const dispatch = useDispatch()
  const search = useLocation().search;
  const trainingId = new URLSearchParams(search).get('t') || location.pathname.split("/")[2]


  const renderValue = (type, typeOptions) => {
    let value = typeOptions.filter(opt => {
      return opt["value"] == type
    }
    )
    return value[0]
  }

  const onSelectChange = (id, selectedOpt) => {
    //format params => document, training_id, document_id
    dispatch(updateDocument({ documentType: event.target.innerHTML, training_id: trainingId, document_id: id }))

  }

  const typeOptions = [
    { value: '', label: '' },
    { value: 'quote', label: i18n.t('documents.quote') },
    { value: 'convention', label: i18n.t('documents.convention') },
    { value: 'program', label: i18n.t('documents.pif') }, //
    { value: 'certifying_attestation', label: i18n.t('documents.certifying_attestation') },
    { value: 'training_attestation', label: i18n.t('documents.training_attestation') },
    { value: 'ending_training_attestation', label: i18n.t('documents.ending_training_attestation') },
    { value: 'invoice', label: i18n.t('documents.invoice') },
    { value: 'other', label: i18n.t('documents.other') }
  ]

  const renderContentTooltip = (props) => {
    return (
      <Tooltip id='button-tooltip' {...props}>
        Ce document peut servir de justificatif pour pôle emploi
      </Tooltip>
    );
  }

  const renderTooltip = (type) => {
    if (type == "training_attestation") {
      return (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderContentTooltip}
        >
          <FontAwesomeIcon style={{ marginLeft: '4px' }} className="icon-details" icon={faInfoCircle} color="#1ABC9C" />
        </OverlayTrigger>
      )
    }
  }

  const handleDelete = (id) => {

    dispatch(deleteDocument({ training_id: trainingId, document_id: id }))

  }

  const columns = [
    {
      name: "id",
      selector: row => row.id,
      omit: true
    },
    {
      name: `${i18n.t('views.profile.first_name')}`,
      width: "50%",
      selector: row => {
        return (
          <a style={{ whiteSpace: 'wrap', color: 'black' }} target="_blank" href={row?.url}> {row?.name} </a>
        )
      }
    },
    {
      name: `${i18n.t('activerecord.attributes.availability.list.headers.type')}`,
      selector: row => {
        const isEditable = user?.role !== "Student" && user?.role !== "HumanResource" && user?.role !== "Teacher" && !["training_attestation", "ending_training_attestation"].includes(row?.document_type);

        if (isEditable) {
          return (
            <div className="line">
              <Select
                menuPortalTarget={document.body}
                placeholder='Sélectionner...'
                defaultValue={renderValue(row?.document_type, typeOptions)}
                options={typeOptions}
                onChange={(selectedOpt) => onSelectChange(row.id, selectedOpt)}
              />
            </div>
          );
        } else {
          const value = typeOptions.find(opt => opt.value === row?.document_type);
          return (
            <>
              <a style={{ whiteSpace: 'wrap', color: 'black' }} href={row.url} target="_blank" rel="noopener noreferrer">{value ? value.label : 'N/A'}</a>
              {renderTooltip(row?.document_type)}
            </>
          );
        }
      },
      width: "40%"
    },
    {
      name: ``,
      width: "8%",
      selector: row => {
        return (
          <div style={{ display: 'flex' }}>
            <a style={{ whiteSpace: 'wrap' }} href={row.url} target="_blank"><FontAwesomeIcon className="icon-details" icon={faDownload} color="#AFAFAF" /></a>
            <FontAwesomeIcon className="icon-delete" icon={faTimes} color="#AFAFAF" style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() =>
              handleDelete(row?.id)
            } />
          </div>
        )
      }
    },



  ];

  return columns;
}

export default Columns;

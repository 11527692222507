import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {fetchNotificationsCount, readNotification} from './notificationsSlice'; // Adjust the import path as needed

const NotificationItem = ({ notification }) => {
	const [isChecked, setIsChecked] = useState(false);
	const [isHovered, setIsHovered] = useState(false);

	const dispatch = useDispatch();

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const handleCheckboxChange = () => {
		setIsChecked(prevChecked => {
			const newChecked = !prevChecked;
			if (newChecked) {
				dispatch(readNotification(notification.id)).then(() => {
					dispatch(fetchNotificationsCount())
				})
			}
			return newChecked;
		});
	};

	const renderContent = (notification) => (
		<div
			key={notification.id}
			onClick={() => window.location.href = notification.redirect_url}
			className="notif-line"
			dangerouslySetInnerHTML={{ __html: notification.action }}
		/>
	);

	return (
		<div
			className="notification-item"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div>
				{renderContent(notification)}
				<div className="intel">
					<div className="createdAt">{notification.date}</div>
					{isHovered && !notification.read && (
						<input
							type="checkbox"
							checked={isChecked}
							onChange={handleCheckboxChange}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default NotificationItem;
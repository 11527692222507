import React, { useEffect, useState } from 'react'
import i18n from '../../../i18n'
import { useSelector } from 'react-redux'


export default function ManagerProfile(){

  const manager = useSelector(state => state.managers.managerShow)
  const responsive = useSelector((state)=> state.navigations.responsiveOne)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return(
    <div className="container-manager">
      <h3 className="title-fullname"> {manager?.name} </h3>
      <iframe style={{marginTop:'10px'}} width={responsive ? windowWidth-60 :560} height={310} src={manager?.video_presentation} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen >  </iframe>
      <p style={{ marginTop: '10px' }}> <span className="presentation">
      {i18n.t('activerecord.attributes.teacher.professional_presentation')}:</span>  {manager?.resume} </p>
    </div>
  )
}

import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ImportantDates from "../importantDates";
import Status from '../status';
import ListItem from "../listItem";
import i18n from '../../../../i18n'
import { Button } from "react-bootstrap";
import Modal from '../../../toolbox/modal'
import Document from "../document";
import DocumentList from "../documentList";



const TrainingCard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(state => state.user.userData)
  const training = useSelector(state => state.trainings.trainingShow)
  const path = location.pathname
  const ref = useRef()


  let autresLinks = ''

  let attestation = training?.documents?.filter(t => { return t?.document_type == "training_attestation" })
  let link = attestation[0] ? attestation[0].url : "#"
  let showLink = attestation[0] ? "" : "hidden"


  let attestationFinDeFormation = training?.documents?.filter(t => { return t?.document_type == "ending_training_attestation" })
  let linkFinDeFormation = attestationFinDeFormation[0] ? attestationFinDeFormation[0].url : "#"
  let showLinkFinDeFormation = attestationFinDeFormation[0] ? "" : "hidden"


  let attestationPlan = training?.documents?.filter(t => { return t?.document_type == "program" })
  let linkPlan = attestationPlan[0] ? attestationPlan[0].url : "#"
  let showLinkPlan = attestationPlan[0] ? "" : "hidden"


  if (training?.documents) {
    let list = training?.documents?.filter(t =>
      t?.document_type === "invoice" ||
      t?.document_type === "other" ||
      t?.document_type === "quote" ||
      t?.document_type === "convention" ||
      t?.document_type === "certifying_attestation"
    );

    if (list?.length > 0) {
      autresLinks = list;
    }
  }

  // how to handle alternate rendering
  // fullwidth -> trainings/:id
  // sided -> trainings?t={:id}&pm=s (for teacher admin and hr)
  // mobile -> trainings/:id (mobile version)

  // to do clean training object & make a specific call for manager

  return (
    <div className="training-card bloc light-grey">
      <div className="bloc-header">
        <div className="left-col">
          <h2>{training.training_type}</h2>
          <p>{training.base_level}</p>
          <p>{training.test_type}</p>
        </div>
        <div className="right-col">
          <ImportantDates first={training.start_at} middle={training.certifying_end_at} last={training.end_at} />
          <Status status={training.student_status} />
        </div>
      </div>
      <div className="bloc-body">
        <div className="left-col">
          <div className="training-info-list">
            <ListItem key={'manager'} label={`${i18n.t('views.trainings.card.labels.manager')}`} managerId={training.manager_id} value={training.manager_name} status={"active"} />
            <ListItem key={'teacher'} label={`${i18n.t('views.trainings.card.labels.teacher')}`} teacherId={training.teacher_id} value={training.teacher_name} status={"active"} />
            <ListItem label={`${i18n.t('navigation.student.lessons')}`} value={`${training.lesson_done}`} status={"active"} />
            <ListItem label={`${i18n.t('navigation.student.collective_lessons')}`} value={`${training.cc_lesson_done}`} status={"active"} />
            <ListItem label={`${i18n.t('navigation.student.elearnings')}`
            } value={training.assignment_count} status={!training.elearning_access && "inactive"} />
            <ListItem label={`${i18n.t('navigation.student.conferences')}`} value={""} status={!training.conference_access && "inactive"} />
          </div>
        </div>

        {(user?.role !== 'Teacher') && <div className="right-col">
          <div className="training-info-list">
            <ListItem label={`${i18n.t('documents.training_attestation')}`} link={link} value={(link !== '#' && link) ?
              <div className={(link !== '#' && link) && "general-icon small downloadTraining"}></div> : ''
            } status={"active"} />
            <ListItem label={`${i18n.t('documents.ending_certifying_attestation')}`
            } link={linkFinDeFormation} value={(linkFinDeFormation !== '#' && linkFinDeFormation) ? <div className={(linkFinDeFormation !== '#' && linkFinDeFormation) && "general-icon small downloadTraining"}></div> : ' '} status={"active"} />
            <ListItem label={`${i18n.t('documents.pif')}`
            } link={linkPlan} value={(linkPlan !== '#' && linkPlan) ? <div className={(linkPlan !== '#' && linkPlan) && "general-icon small downloadTraining"}></div> : ''} status={"active"} />
            <DocumentList documents={autresLinks}   />
          </div>
        </div>}
      </div>
      <div className="bloc-footer">

      </div>


      <Modal ref={ref}>
        <Document training={training}  />
      </ Modal>


    </div>
  )

};

export default TrainingCard;

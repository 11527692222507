import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getTraining = createAsyncThunk(
  'user/getTraining',
  async (id) => {
    try {
      const response = await axios.get(`/api/v2/trainings/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)

export const fetchTrainings = createAsyncThunk(
  'user/fetchTrainings',
  async ({ page, filters }) => {
    try {
      const response = await axios.get(`/api/v2/trainings?filters=${JSON.stringify(filters)}&page=${page}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)


export const trainingTicket = createAsyncThunk(
  'user/trainingTicket',
  async ({ training, type, note, mode }) => {

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
    const body = { training, type, note, mode };
    try {
      const response = await axios.put(`/api/v1/tickets/training`, body, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }

)

export const getDocuments = createAsyncThunk(
  'user/getDocuments',
  async ({ training_id }) => {

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
    try {
      const response = await axios.get(`/api/v1/trainings/${training_id}/documents`, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }

  })

export const updateDocument = createAsyncThunk(
  'user/updateDocument',
  async ({ documentType, training_id, document_id }) => {

    const body = { type: documentType };

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
    try {
      const response = await axios.put(`/api/v1/trainings/${training_id}/documents/${document_id}`, body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }

  })


export const deleteDocument = createAsyncThunk(
  'user/deleteDocument',
  async ({ training_id, document_id }) => {

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
    try {
      const response = await axios.delete(`/api/v1/trainings/${training_id}/documents/${document_id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }

  })

export const dlDocuments = createAsyncThunk(
  'user/dlDocuments',
  async ({ training_id }) => {

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
    try {
      const response = await axios.get(`/api/v1/trainings/${training_id}/documents/attachments`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }

  })





const trainingsSlice = createSlice({
  name: 'trainings',
  initialState: {
    loading: false,
    currentRequestId: "",
    trainingShow: {},
    trainingsList: [],
    documents: [],
    updatedDocument: {}
  },
  reducers: {
    setTrainingShow: () => {
      state.trainingShow = action.payload
    },
    setTrainingsList: () => {
      state.trainingsList = [...state.trainingsList, ...action.payload]
    },
    resetTrainings: (state, action) => {
      state.trainingsList = []
    },
    setDocuments: () => {
      state.documents = action.payload
    },
    setUpdateDocument: () => {
      state.updatedDocument = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTraining.fulfilled, (state, action) => {
      state.trainingShow = action.payload
    }),
      builder.addCase(fetchTrainings.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true
          state.currentRequestId = action.meta.requestId
        }
      }),
      builder.addCase(fetchTrainings.fulfilled, (state, action) => {
        const { requestId } = action.meta
        if (
          state.loading &&
          state.currentRequestId === requestId
        ) {
          state.loading = false
          state.trainingsList = [...state.trainingsList, ...action.payload]
          state.currentRequestId = undefined
        }
      }),
      builder.addCase(fetchTrainings.rejected, (state, action) => {
        const { requestId } = action.meta
        if (
          state.loading &&
          state.currentRequestId === requestId
        ) {
          state.loading = false
          state.error = action.error
          state.currentRequestId = undefined
        }
      }),
      builder.addCase(trainingTicket.fulfilled, (state, action) => {


        let oldList = state.trainingsList?.filter(e => {
          return e.id === action.payload.id
        })

        const index = state.trainingsList.indexOf(oldList[0])
        let copiedState = state.trainingsList.slice(0);
        copiedState[index] = action.payload
        state.trainingsList = copiedState
      }),
      builder.addCase(getDocuments.fulfilled, (state, action) => {
        state.documents = action.payload
      }),
      builder.addCase(updateDocument.fulfilled, (state, action) => {
        state.documents = action.payload
      }),
      builder.addCase(deleteDocument.fulfilled, (state, action) => {
        // let oldList = state.documents?.filter(e => {
        //   return e.id === action.payload.id
        // })

        // const index = state.documents.indexOf(oldList[0])
        // let copiedState = state.documents.slice(0);
        // copiedState[index] = action.payload
        // state.documents = copiedState
        state.documents = action.payload

      }),
      builder.addCase(dlDocuments.fulfilled, (state, action) => {
        state.documents = action.payload
      })
  }
})

export const trainingsShow = createSelector(
  (state) => ({
    trainingShow: state.trainingShow,
    trainingsList: state.trainingsList,
    documents: state.documents,
    updatedDocument: state.updatedDocument
  }),
  (state) => state.trainingShow
)

export const { setTrainingShow, setTrainingsList, resetTrainings, setDocuments, setUpdateDocument } = trainingsSlice.actions
export default trainingsSlice.reducer

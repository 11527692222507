import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {setFiltersLesson, setFiltersTickets} from "../filtersSlice";
import i18n from '../../../i18n'

const ByTeacherManager = ({model, action, loading}) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.filters[model].teacher_managers)
	const [options, setOptions] = useState([])

	const loadManagerOptions = () => {
		const url = `/api/v2/filters?filter=manager`
		const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
			.content.value;
		const promise = fetch(url, { credentials: "same-origin" }).then(r =>
			r.json()
		).then(data => {
			setOptions(data)
		});
	}

	useEffect(() => {
		loadManagerOptions()
	}, []);

	const dispatcher = {
		"setFiltersTickets": (teacher_managers) => dispatch(setFiltersTickets({teacher_managers: teacher_managers}))
	}

	const  onSelectChange = value => {
		dispatcher[action](value)
	}

	return (
		<div className='filter by_demande_statuses'>
			<div className="line">
				<label htmlFor="filter_type" id="demande_statuses" className="s_label">
				{i18n.t('filters.by_teacher_manager')}

          </label>
				<Select
					cacheOptions
					onChange={onSelectChange}
					isMulti
					isLoading={loading}
					isDisabled={loading}
					options={options}
					defaultValue={current}
          placeholder={`${i18n.t('helpers.select.prompt')}...`}
				/>
			</div>
		</div>
	)

};

export default ByTeacherManager;

import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {setFiltersLesson, setFiltersTraining, setResponsiveFilterCleanBtn} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'


const ByTestType = ({model, action, loading}) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.filters[model].test_types)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
  const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
  const ref = useRef()

	const [options, setOptions] = useState([
		{ value: "English 360", label: 'English 360' },
		{ value: "TOEIC voucher", label: 'TOEIC voucher' },
		{ value: "Linguaskill", label: 'Linguaskill' },
		{ value: "TOEIC en centre", label: 'TOEIC en centre' },
		{ value: "Sans test", label: 'Sans test' },
	])


	//to-do: options will take from backend

	const dispatcher = {
		"setFiltersTraining": (test_type) => dispatch(setFiltersTraining({test_types: test_type})),
	}

	const  onSelectChange = value => {
	// 	if(responsiveFilter){
    //   dispatch(setResponsiveFiltersArray({action,value,type:'testType'}))
    // }
    // else{
      dispatcher[action](value)
    //}
	}

  if(isCleaned){
    ref.current?.select.clearValue();

  }

	return (
		<div className='filter by_test_types'>
			<div className="line">
				<label htmlFor="filter_type" id="test_types" className="s_label">
				{i18n.t('filters.by_test_type')}
          		</label>
				<Select
        		ref={ref}
						cacheOptions
						onChange={onSelectChange}
						isMulti
						isLoading={loading}
						isDisabled={loading}
						options={options}
						defaultValue={current}
         		placeholder={`${i18n.t('helpers.select.prompt')}...`}
				/>
			</div>
		</div>
	)

};

export default ByTestType;

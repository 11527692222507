import React, { useEffect, useState, useRef } from "react";
import FullCalendar from '@fullcalendar/react'
import allLocales from '@fullcalendar/core/locales-all';
//import frLocale from '@fullcalendar/core/locales/fr';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import getUserLocale from "get-user-locale";
import { useSelector } from "react-redux";

export default function Calendar({ data, secondaryData, clickEvent, date, changeDate, selectable, handleSelect, event, scope }) {
  const calendarComponentRef = useRef(null)
  const [editable, setEditable] = useState(false)
  const [clickable, setClickable] = useState(true)
  const [currentDate, setCurrentDate] = useState(date)
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)


  const handleSelectAllow = (selectInfo) => {
    if (scope === 'week_type' || scope === 'admin_week_type') {
      return true
    }

    let currentDate = new Date()
    let startDate = new Date(selectInfo.start)
    return currentDate < startDate
  }

  const open = (selectInfo) => {
    let newEvent = {}
    newEvent.start = selectInfo.startStr
    newEvent.end = selectInfo.endStr
    newEvent.allDay = selectInfo.allDay
    handleSelect(newEvent)
    // this.props.selectEvent(newEvent)
    // this.setState({
    //     calendarApi: selectInfo.view.calendar
    // })
  }

  const renderDayHeaderContent = (dayInfo) => {
    let val = getUserLocale().includes('fr') ? dayInfo.text.split('. ') : dayInfo.text.split(' ')
    if (scope === 'week_type' || scope === 'admin_week_type') {
      return (
        <div className="day-header-content">
          <div className="day">{val[0]}</div>
        </div>
      )
    }
    let day = (getUserLocale().includes('fr')) ? val[1]?.split('/')[0] : val[1]?.split('/')[1]
    return (
      <div className="day-header-content">
        <div className="day">{val[0]}</div>
        <div className="date">{day}</div>
      </div>
    )
  }

  const headerToolbar = {
    left: (scope === 'week_type' || scope === 'admin_week_type') ? '' : 'today prev,next title',
    center: '',
    right: ''
  }

  const handleEventClick = (type, event) => {
    if (clickEvent) {
      clickEvent(type, null, null, event)
    }
  }

  const getCurrentDate = (info) => {
    let y = (info.view.currentStart.getFullYear()).toString();
    let m = (info.view.currentStart.getMonth() + 1).toString();
    let d = (info.view.currentStart.getDate()).toString();

    m = m.length < 2 ? '0' + m : m
    d = d.length < 2 ? '0' + d : d

    changeDate(`${y}-${m}-${d}`)
  }

  const handleUserLocale=()=>{

    let userLcl = getUserLocale()

    if(!userLcl?.includes('fr')){
        userLcl=null
        userLcl='en'
        return userLcl
    }
    else{
      return userLcl
    }

  }




  return (
    <FullCalendar
      plugins={[timeGridPlugin, interactionPlugin]}
      initialView="timeGridWeek"
      aspectRatio="1.6"
      expandRows={true}
      locales={allLocales}
      locale={handleUserLocale()}
      eventSources={responsiveOne ? [
        data?.map((item) => {
          const { title, ...rest } = item;
          return rest;
        }), secondaryData
      ] : [
        data, secondaryData
      ]}
      allDaySlot={false}
      slotMinTime="06:00:00"
      slotMaxTime="24:00:00"
      slotDuration='00:30:00'
      scrollTime='08:30:00'
      editable={editable}
      selectable={selectable}
      selectAllow={handleSelectAllow}
      selectMirror={true}
      select={open}
      draggable={false}
      resizable={false}
      eventContent={event} // custom render function
      dayHeaderContent={renderDayHeaderContent}
      eventClick={(e) => { clickable == "true" && handleEventClick('clic', e.event) }}
      datesSet={(i) => { getCurrentDate(i) }}
      weekNumberCalculation={'ISO'}
      ref={calendarComponentRef}
      headerToolbar={headerToolbar}
    />
  )
}

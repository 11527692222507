import DatePicker from "react-datepicker";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import { setFiltersLesson,setFiltersTickets } from "../filtersSlice";
import getUserLocale from "get-user-locale";
import i18n from '../../../i18n'
import fr from 'date-fns/locale/fr';
import { registerLocale } from  "react-datepicker";
registerLocale('fr', fr);

const ByDate = ({ model, action }) => {
  const dispatch = useDispatch()
  const start_at = useSelector(state => state.filters[model].start_at)
  const end_at = useSelector(state => state.filters[model].end_at)
  const [locale, setLocale] = useState("en")

  useEffect(() => {
    getUserLocale().includes("fr") && setLocale("fr")
  }, []);

  const onChange = dates => {
      const [start, end] = dates;
      dispatcher[action](start, end)
  }

  const dispatcher = {
    "setFiltersLesson": (start, end) => dispatch(setFiltersLesson({start_at: start, end_at: end})),
    "setFiltersTickets": (start, end) => dispatch(setFiltersTickets({start_at: start, end_at: end})),

  }

  const  renderDatePicker = () => {

    return (
      <div id="picker-container">
        <DatePicker
          selected={undefined}
          onChange={onChange}
          startDate={start_at}
          endDate={end_at}
          selectsRange
          inline
          dateFormat="Pp"
          locale={locale}
        />

      </div>
    );
  }


  return (
    <div className='filter by_currency'>
      <div className="line dateline">
        <label htmlFor="filter_date" className="filter_date">Date</label>
        {renderDatePicker()}
      </div>
    </div>
  )

}

export default ByDate;

import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default function CommentTooltip({note}){

  const renderCommentTooltip = (props) => {
    return (

      <Tooltip id='button-tooltip' {...props}>
        <span>  {note}  </span>
      </Tooltip>
    )

  }

  return(
    <div>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderCommentTooltip}
      >
       <p> {note} </p>
      </OverlayTrigger>
    </div>
  )

}

import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setFiltersTickets, setResponsiveFilterCleanBtn} from "../filtersSlice";
import { setResponsiveFiltersArray } from "../../navigation/navigationSlice";
import i18n from '../../../i18n'

const ByDemandStatus = ({model, action, loading}) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.filters[model].demand_statuses)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)

  const isCleaned = useSelector((state)=>state.filters.responsiveFilterCleanBtn)
  const ref = useRef()

	const [options, setOptions] = useState([
		{value: "validated", label: `${i18n.t('filters.options.validated')}`
      },
		{value: "refused", label: `${i18n.t('filters.options.refused')}`
      },
		{value: "pending", label: `${i18n.t('filters.options.pending')}`
      },
	])


	//to-do: options will take from backend

	const dispatcher = {
		"setFiltersTickets": (statuses) => dispatch(setFiltersTickets({demand_statuses: statuses}))
	}

	const  onSelectChange = value => {
	// 	if(responsiveFilter){
    //   		dispatch(setResponsiveFiltersArray({action,value,type:'demandStatus'}))
    // }
    // else{
      dispatcher[action](value)
    //}
	}

  if(isCleaned){
    ref.current?.select.clearValue();

  }

	return (
		<div className='filter by_demande_statuses'>
			<div className="line">
				<label htmlFor="filter_type" id="demande_statuses" className="s_label">
				{i18n.t('filters.by_ticket_status')}

          </label>
				<Select
        	ref={ref}
					isLoading={loading}
					isDisabled={loading}
					cacheOptions
					onChange={onSelectChange}
					isMulti
					options={options}
					defaultValue={current}
          placeholder={`${i18n.t('helpers.select.prompt')}...`}
				/>
			</div>
		</div>
	)

};

export default ByDemandStatus;

import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getTeacher = createAsyncThunk(
    'user/getTeacher',
    async (id) => {
      try {
        const response = await axios.get(`/api/v1/teachers/${id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )


export const getProfile = createAsyncThunk(
  'user/getProfile',
  async () => {
    try {
      const response = await axios.get(`/api/v2/profiles`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)


  export const fetchTeachers = createAsyncThunk(
    'user/fetchTeachers',
    async ({page, filters}) => {
      try {
        const response = await axios.get(`/api/v2/admin/teachers?filters=${JSON.stringify(filters)}&page=${page}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

const teachersSlice = createSlice({
  name: 'teachers',
  initialState: {
    teacherShow: {},
    profile:{},
    teachersList: [],
    currentRequestId: null
  },
  reducers: {
    setTeacherShow: () => {
        state.teacherShow = action.payload
    },
    setProfile: () => {
      state.profile = action.payload
  },
    resetTeachers: (state, action) => {
      state.teachersList = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTeacher.fulfilled, (state, action) => {
        state.teacherShow = action.payload
    }),
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profile = action.payload
  })
    builder.addCase(fetchTeachers.pending, (state, action) => {
      if (!state.loading) {
        state.loading = true
        state.currentRequestId = action.meta.requestId
      }
    }),
    builder.addCase(fetchTeachers.fulfilled, (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading &&
        state.currentRequestId === requestId
      ) {
        state.loading = false
        state.teachersList = [...state.teachersList, ...action.payload]
        state.currentRequestId = undefined
      }
    }),
    builder.addCase(fetchTeachers.rejected, (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading &&
        state.currentRequestId === requestId
      ) {
        state.loading = false
        state.error = action.error
        state.currentRequestId = undefined
      }
    })
  }
})

export const lessonsList = createSelector(
  (state) => ({
    teacherShow: state.teacherShow,
    profile: state.profile,
    teachersList: state.teachersList
  }),
  (state) => state.teacherShow
)

export const { setTeacherShow, setProfile, setTeachersList, resetTeachers } = teachersSlice.actions
export default teachersSlice.reducer
